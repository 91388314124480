import { getCenter } from './point-finder';

export default ({ x, y, scaleX, scaleY, width, height, angle, startX, startY, offsetX, offsetY }, onUpdate) => {
  const center = getCenter({ x, y, scaleX, scaleY, width, height });
  const pressAngle = (Math.atan2(startY - offsetY - center.y, startX - offsetX - center.x) * 180) / Math.PI;

  return (event) => {
    const degree = (Math.atan2(event.pageY - offsetY - center.y, event.pageX - offsetX - center.x) * 180) / Math.PI;
    let ang = Math.floor(angle + degree - pressAngle);

    if (ang >= 360) {
      ang -= 360;
    } else if (ang < 0) {
      ang += 360;
    }

    onUpdate({
      angle: ang,
    });
  };
};
