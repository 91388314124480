import $ from 'jquery';

export default (_this) => {
  let isCtrlDown = false,
    ctrlKey = 17,
    cmdKey = 91,
    cmdKey2 = 93,
    vKey = 86,
    cKey = 67;

  $(document)
    .on('keydown', function (e) {
      if (e.keyCode === ctrlKey || e.keyCode === cmdKey || e.keyCode === cmdKey2) {
        e.preventDefault();
        isCtrlDown = true;
      }
    })
    .on('keyup', function (e) {
      if (e.keyCode === ctrlKey || e.keyCode === cmdKey || e.keyCode === cmdKey2) {
        e.preventDefault();
        isCtrlDown = false;
      }
    });

  $(document).on('keydown', function (e) {
    if (isCtrlDown) {
      // 복사
      if (e.keyCode === cKey) {
        _this.copiedElement = _this.selectedElement;
      }
      // 붙여넣기
      else if (e.keyCode === vKey) {
        if (_this.copiedElement) {
          _this.isCopyPasted = true;
        }
      }
    }
  });
};
