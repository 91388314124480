var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = {}),
        (_obj[_vm.classPrefix + "-transform"] = true),
        (_obj[_vm.classPrefix + "-transform--active"] = _vm.selected),
        _obj),
      style: _vm.styles,
      on: {
        click: _vm.click,
        dblclick: _vm.dblClick,
        mousedown: _vm.mousedown,
        mouseover: _vm.mouseover,
        mouseup: _vm.mouseup,
      },
    },
    [
      _c(
        "div",
        {
          class: _vm.classPrefix + "-transform__content",
          style: _vm.computedStyles.element,
        },
        [_vm._t("default")],
        2
      ),
      _vm.selected
        ? _c(
            "div",
            {
              class: _vm.classPrefix + "-transform__controls",
              style: _vm.computedStyles.controls,
            },
            [
              _c("div", {
                class: _vm.classPrefix + "-transform__rotator",
                on: { mousedown: _vm.handleRotation },
              }),
              _c("div", {
                class: [
                  _vm.classPrefix +
                    "-transform__scale-point " +
                    _vm.classPrefix +
                    "-transform__scale-point--tl",
                ],
                on: {
                  mousedown: function ($event) {
                    return _vm.handleScale("tl", $event)
                  },
                },
              }),
              _c("div", {
                class: [
                  _vm.classPrefix +
                    "-transform__scale-point " +
                    _vm.classPrefix +
                    "-transform__scale-point--ml",
                ],
                on: {
                  mousedown: function ($event) {
                    return _vm.handleScale("ml", $event)
                  },
                },
              }),
              _c("div", {
                class: [
                  _vm.classPrefix +
                    "-transform__scale-point " +
                    _vm.classPrefix +
                    "-transform__scale-point--tr",
                ],
                on: {
                  mousedown: function ($event) {
                    return _vm.handleScale("tr", $event)
                  },
                },
              }),
              _c("div", {
                class: [
                  _vm.classPrefix +
                    "-transform__scale-point " +
                    _vm.classPrefix +
                    "-transform__scale-point--tm",
                ],
                on: {
                  mousedown: function ($event) {
                    return _vm.handleScale("tm", $event)
                  },
                },
              }),
              _c("div", {
                class: [
                  _vm.classPrefix +
                    "-transform__scale-point " +
                    _vm.classPrefix +
                    "-transform__scale-point--bl",
                ],
                on: {
                  mousedown: function ($event) {
                    return _vm.handleScale("bl", $event)
                  },
                },
              }),
              _c("div", {
                class: [
                  _vm.classPrefix +
                    "-transform__scale-point " +
                    _vm.classPrefix +
                    "-transform__scale-point--bm",
                ],
                on: {
                  mousedown: function ($event) {
                    return _vm.handleScale("bm", $event)
                  },
                },
              }),
              _c("div", {
                class: [
                  _vm.classPrefix +
                    "-transform__scale-point " +
                    _vm.classPrefix +
                    "-transform__scale-point--br",
                ],
                on: {
                  mousedown: function ($event) {
                    return _vm.handleScale("br", $event)
                  },
                },
              }),
              _c("div", {
                class: [
                  _vm.classPrefix +
                    "-transform__scale-point " +
                    _vm.classPrefix +
                    "-transform__scale-point--mr",
                ],
                on: {
                  mousedown: function ($event) {
                    return _vm.handleScale("mr", $event)
                  },
                },
              }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }