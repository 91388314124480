<template>
  <div class="scene-list-wrapper" @mouseleave="onMouseLeave">
    <div class="section-title" @mouseover="onMouseLeave">
      <h4>{{ $t('message.list_scene_title') }}</h4>
      <button data-icon="add" @click="addScene('single')" :title="$t('message.list_scene_add_tooltip')">
        <span data-icon="scene-group-add">
          <vue-material-icon name="add" :size="16" />
        </span>
      </button>
    </div>
    <div class="section-contents" id="scene-list" @mouseleave="onCloseMoreList">
      <ul class="scene-list" oncontextmenu="return false">
        <li
          v-for="(outerScene, index) in sceneArray"
          name="outerScene"
          :key="outerScene.key"
          :draggable="!isListMoveMode"
          class="scene outerScene"
          :class="{
            'single-scene': outerScene.type === 'single',
            'scene-group': outerScene.type === 'group',
            selected:
              (!activatedType.includes('asset') && activatedType !== 'group' && outerScene.key === selectedScene.key) ||
              (activatedType === 'group' && outerScene.key === selectedSceneGroup.key),
            focused: isFocusedOuterScene(outerScene),
            'hover-inner': hoveredScene !== 'outerScene',
          }"
          :title="outerScene.name"
          @mouseover="onMouseover($event, outerScene, false)"
          @dblclick="onInputMode($event, 'input' + outerScene.key)"
          @mousedown="onSelectScene($event, outerScene)"
          @mouseup="onMouseup($event)"
          @dragstart="handleDragStart($event, outerScene, index)"
          @dragenter="handleDragEnter($event, outerScene, index)"
          @dragover="handleDragOver($event, index)"
          @dragleave="handleDragLeave($event, outerScene, index)"
          @dragend="handleDragEnd($event, outerScene, null, index)"
        >
          <template v-if="outerScene.type !== 'inner'">
            <span @mouseover="onMouseover($event, outerScene, true)">
              <label>
                <input
                  type="text"
                  :id="'input' + outerScene.key"
                  v-model="custom.scenes[outerScene.key].name"
                  readonly="readonly"
                  maxlength="20"
                  @blur="onBlurInput"
                  @keypress="onKeypress"
                  placeholder="Scene"
                />
              </label>
              <span
                data-icon="drag_indicator"
                class="opacity-0"
                :class="{ 'opacity-100': hoveredScene === 'outerScene' }"
                draggable="false"
              >
                <img src="/images/drag_indicator.png" alt="Drag Indicator" />
              </span>
            </span>
            <ul class="scene-more-list">
              <li @click="onClickChangeSceneName">{{ $t('message.list_scene_name_label') }}</li>
              <li @click="onClickCopyScene">{{ $t('message.list_scene_copy_label') }}</li>
              <li :class="{ disabled: sceneArray.length === 1 }" @click="onClickDeleteScene(true)">
                {{ $t('message.delete_label') }}
              </li>
            </ul>
            <ul v-if="outerScene.type === 'group'" class="inner-scene-list">
              <li
                v-for="(innerScene, index) in outerScene.innerScenes"
                :name="'innerScene_' + outerScene.key"
                :key="innerScene.key"
                :draggable="!isListMoveMode"
                class="scene innerScene"
                :class="{
                  selected:
                    !activatedType.includes('asset') &&
                    activatedType !== 'group' &&
                    innerScene.key === selectedScene.key,
                  focused:
                    (activatedType === 'group' && innerScene.key === selectedScene.key) ||
                    (activatedType.includes('asset') && innerScene.key === selectedScene.key),
                }"
                :title="innerScene.name"
                @mouseover="onMouseover($event, innerScene, false)"
                @dblclick="onInputMode($event, 'input' + innerScene.key)"
                @mousedown="onSelectScene($event, outerScene, innerScene)"
                @mouseup="onMouseup($event)"
                @dragstart="handleDragStart($event, innerScene, index, outerScene.innerScenes)"
                @dragenter="handleDragEnter($event, innerScene, index, outerScene.innerScenes)"
                @dragover="handleDragOver($event, index)"
                @dragleave="handleDragLeave($event, innerScene, index, outerScene.innerScenes)"
                @dragend="handleDragEnd($event, outerScene, innerScene, index, outerScene.innerScenes)"
              >
                <label>
                  <input
                    type="text"
                    :id="'input' + innerScene.key"
                    v-model="custom.scenes[innerScene.key].name"
                    readonly="readonly"
                    maxlength="20"
                    @blur="onBlurInput"
                    @keypress="onKeypress"
                    placeholder="Scene"
                  />
                </label>
                <span
                  data-icon="drag_indicator"
                  class="opacity-0"
                  :class="{ 'opacity-100': hoveredScene === innerScene.parentKey }"
                >
                  <img src="/images/drag_indicator.png" alt="Drag Indicator" />
                </span>
                <ul class="scene-more-list">
                  <li @click="onClickChangeSceneName">{{ $t('message.list_scene_name_label') }}</li>
                  <li @click="onClickCopyScene">{{ $t('message.list_scene_copy_label') }}</li>
                  <li
                    :class="{ disabled: sceneArray.length === 1 && outerScene.innerScenes.length === 1 }"
                    @click="onClickDeleteScene(false)"
                  >
                    {{ $t('message.delete_label') }}
                  </li>
                </ul>
              </li>
              <li class="add-list scene" @mouseover="onMouseLeave">
                <span data-icon="add" ref="add" @click="addInnerScene(outerScene.key)">
                  <vue-material-icon name="add" :size="16" />
                </span>
              </li>
            </ul>
          </template>
        </li>
      </ul>
    </div>
    <confirm-popup
      :isShow="showedPopupName === 'ConfirmPopup'"
      :contentsKey="popupContentsKey"
      @close="togglePopup"
      @confirm="onConfirmInPopup"
    />
  </div>
</template>

<script>
import $ from 'jquery';
import { mapState, mapMutations } from 'vuex';
import createUuid from '@/asset/js/createUuid.js';
import ConfirmPopup from '@/component/popup/confirmPopup/ConfirmPopup';

export default {
  name: 'sceneList',
  props: ['isGetTempoSavedCustom', 'checkSheetAssetInReference'],
  data() {
    return {
      sceneArray: [],
      dragStartElement: null,
      sceneIndex: 0,
      sceneContainerIndex: 0,
      dragScene: {},
      dragIndex: 0,
      enterIndex: 0,
      custom: {
        scenes: {},
        commonItems: [],
      },
      showedPopupName: '',
      popupContentsKey: '',
      isDragging: false,
      oldSceneName: '',
      deleteType: '',
      hoverSceneType: '',
      isListMoveMode: false,
      deleteTypeName: '',
      isInitChangeSceneArray: true,
      prevSelectedScene: {},
    };
  },
  computed: {
    ...mapState('manualTasks', [
      'selectedTask',
      'scenes',
      'selectedScene',
      'selectedSceneGroup',
      'activatedType',
      'commonItems',
    ]),
    content() {
      return this.selectedTask.saveContent ? this.selectedTask.saveContent : this.selectedTask.publishContent;
    },
    hoveredScene() {
      if (this.hoverSceneType === 'single' || this.hoverSceneType === 'group') {
        return 'outerScene';
      } else {
        return this.hoverSceneType;
      }
    },
  },
  mounted() {
    if (this.content.custom) {
      this.custom = JSON.parse(this.content.custom);
      this.setStoreScenes(this.custom.scenes);
      this.SET_COMMON_ITEMS(this.custom.commonItems);
      this.setSceneArray();
    } else {
      this.addScene('single');
      this.SET_COMMON_ITEMS([]);
    }
    this.initSelectScene();
  },
  destroyed() {
    this.SET_SELECTED_SCENE(null);
    this.SET_SELECTED_SCENE_GROUP(null);
  },
  watch: {
    sceneArray() {
      this.setStoreScenes(this.custom.scenes);
      if (this.isInitChangeSceneArray) {
        this.isInitChangeSceneArray = false;
      } else {
        this.$emit('isChangeScene');
      }
    },
    activatedType() {
      if (this.activatedType.includes('asset')) {
        this.onCloseMoreList();
      }
    },
    isGetTempoSavedCustom(newVal) {
      if (newVal) {
        this.custom.scenes = this.scenes;
        this.custom.commonItems = this.commonItems;
        this.setSceneArray();
        this.initSelectScene();
      }
    },
    checkSheetAssetInReference(newVal) {
      if (newVal) {
        const sceneUuid = this.addScene('reference');
        this.$emit('addedReferenceScene', sceneUuid);
      }
    },
  },
  methods: {
    ...mapMutations('manualTasks', [
      'SET_COMMON_ITEMS',
      'SET_SELECTED_SCENE',
      'SET_SELECTED_SCENE_GROUP',
      'SET_ACTIVATED_TYPE',
      'SET_SCENES',
      'SET_STORE_SCENE_ARRAY',
    ]),
    isFocusedOuterScene(outerScene) {
      if (this.activatedType.includes('asset')) {
        return outerScene.key === this.selectedScene.key;
      } else if (this.activatedType !== 'group') {
        return this.selectedSceneGroup && outerScene.key === this.selectedSceneGroup.key;
      }
    },
    togglePopup(popupName, popupContentsKey) {
      this.showedPopupName = popupName || '';
      this.popupContentsKey = popupContentsKey || '';
    },
    onConfirmInPopup() {
      switch (this.popupContentsKey) {
        case 'DELETE_SCENE':
          this.onDeleteScene();
          this.togglePopup();
          break;
        default:
          break;
      }
    },
    initSelectScene() {
      if (this.sceneArray[0].type === 'group') {
        this.SET_SELECTED_SCENE(this.sceneArray[0].innerScenes[0]);
        this.SET_SELECTED_SCENE_GROUP(this.sceneArray[0]);
        this.SET_ACTIVATED_TYPE('group');
      } else {
        this.SET_SELECTED_SCENE(this.sceneArray[0]);
        this.SET_SELECTED_SCENE_GROUP(null);
        this.SET_ACTIVATED_TYPE('single');
      }
    },
    onMouseLeave(e) {
      e.stopPropagation();
      this.hoverSceneType = '';
    },
    onMouseover(e, scene, isGroup) {
      e.stopPropagation();
      if (scene.type === 'group' && isGroup) {
        this.hoverSceneType = scene.type;
      } else if (scene.type === 'inner') {
        this.hoverSceneType = scene.parentKey;
      } else if (scene.type !== 'group' && !isGroup) {
        this.hoverSceneType = scene.type;
      }
    },
    onClickChangeSceneName() {
      let key;
      if (this.activatedType === 'group') {
        key = `input${this.selectedSceneGroup.key}`;
      } else {
        key = `input${this.selectedScene.key}`;
      }
      this.onInputMode(null, key);
      this.onCloseMoreList();
    },
    copyArItems(arItems) {
      return arItems.map((arItem) => {
        arItem.id = createUuid();
        if (arItem.type === 'checkSheet') {
          this.$emit('copiedScene', arItem, this.selectedScene.key);
        }
        return arItem;
      });
    },
    onClickCopyScene() {
      if (this.activatedType === 'single' || this.activatedType === 'inner') {
        let copiedScene = JSON.parse(JSON.stringify(this.custom.scenes[this.selectedScene.key]));
        if (this.activatedType === 'single') {
          this.addScene(this.selectedScene.type);
        } else {
          this.addInnerScene(copiedScene.parentKey);
          this.selectedScene.subtitle = copiedScene.subtitle;
          this.selectedScene.description = copiedScene.description;
        }
        this.selectedScene.arItems = this.copyArItems(copiedScene.arItems);
        this.selectedScene.name = copiedScene.name + ' Copy';
      } else if (this.activatedType === 'group') {
        let copiedScene = JSON.parse(JSON.stringify(this.custom.scenes[this.selectedSceneGroup.key]));
        let sceneType = this.selectedSceneGroup.type;
        let sceneGroupType = this.selectedSceneGroup.groupType;
        this.addScene(sceneType, sceneGroupType, sceneGroupType);
        this.selectedSceneGroup.name = copiedScene.name + ' Copy';
        this.selectedSceneGroup.title = copiedScene.title;

        Object.keys(this.custom.scenes).forEach((key) => {
          if (this.custom.scenes[key].type === 'inner') {
            if (this.custom.scenes[key].parentKey === this.selectedSceneGroup.key) {
              delete this.custom.scenes[key];
            }
          }
        });

        Object.keys(this.custom.scenes).forEach((key) => {
          const uuid = createUuid();
          if (this.custom.scenes[key].type === 'inner') {
            if (this.custom.scenes[key].parentKey === copiedScene.key) {
              this.custom.scenes[uuid] = JSON.parse(JSON.stringify(this.custom.scenes[key]));
              this.custom.scenes[uuid].parentKey = this.selectedSceneGroup.key;
              this.custom.scenes[uuid].key = uuid;
              if (this.custom.scenes[uuid].viewOrder === 0) {
                this.SET_SELECTED_SCENE(this.custom.scenes[uuid]);
              }
            }
          }
        });
      }
      this.setSceneArray();
    },
    onClickDeleteScene(isOuterScene) {
      if (this.sceneArray.length === 1 && (isOuterScene || this.selectedSceneGroup.innerScenes.length === 1)) {
        return;
      }

      this.deleteType = '';

      if (this.activatedType === 'group') {
        this.deleteType = 'group';
        this.deleteTypeName = this.selectedSceneGroup.name;
      } else {
        if (this.selectedSceneGroup && this.selectedSceneGroup.innerScenes.length === 1) {
          this.deleteType = 'group/scene';
          this.deleteTypeName = this.selectedSceneGroup.name;
        } else {
          this.deleteType = 'scene';
        }
      }
      this.togglePopup('ConfirmPopup', 'DELETE_SCENE');
      this.onCloseMoreList();
    },
    onDeleteScene() {
      // group scene 삭제
      if (this.activatedType === 'group') {
        delete this.custom.scenes[this.selectedSceneGroup.key];

        Object.keys(this.custom.scenes).forEach((key) => {
          if (this.custom.scenes[key].type === 'inner') {
            if (this.custom.scenes[key].parentKey === this.selectedSceneGroup.key) {
              delete this.custom.scenes[key];
            }
          }
        });
      } else {
        // inner scene 삭제
        if (this.selectedSceneGroup && this.selectedSceneGroup.innerScenes.length === 1) {
          delete this.custom.scenes[this.selectedSceneGroup.key];
        }
        // single scene 삭제 및 scene 안에 연결되어 있는 참고씬 전체 삭제
        this.deleteReferenceSceneInDeletedScene();
        delete this.custom.scenes[this.selectedScene.key];
      }

      this.SET_ACTIVATED_TYPE('single');
      this.setSceneArray();
      this.SET_SELECTED_SCENE_GROUP(null);

      if (this.sceneArray[0].type === 'group') {
        this.SET_SELECTED_SCENE_GROUP(this.sceneArray[0]);
        this.SET_SELECTED_SCENE(this.sceneArray[0].innerScenes[0]);
      } else {
        this.SET_SELECTED_SCENE_GROUP(null);
        this.SET_SELECTED_SCENE(this.sceneArray[0]);
      }
    },
    deleteReferenceSceneInDeletedScene() {
      const referenceSceneKeys = [];

      this.custom.scenes[this.selectedScene.key].arItems.forEach((arItem) => {
        if (arItem.type === 'checkSheet') {
          referenceSceneKeys.push(arItem.connectedSceneKey);
        }
      });

      referenceSceneKeys.forEach((sceneKey) => {
        delete this.custom.scenes[sceneKey];
      });
    },
    onMouseup(e) {
      e.stopPropagation();
      this.onCloseMoreList();

      // 오른쪽 마우스 클릭
      if (e.button === 2 || e.which === 3) {
        let _left;
        let _top = e.offsetY;
        if (e.layerX > 155) {
          _left = e.layerX - 70;
        } else {
          _left = e.layerX;
        }
        $('.selected > .scene-more-list').css({
          display: 'inline-block',
          left: _left,
          top: _top,
        });
      }
    },
    onCloseMoreList() {
      $('.scene-more-list').css('display', 'none');
    },
    // 이름 변경 모드
    onInputMode(e, key) {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }
      let $INPUT = document.getElementById(key);
      this.oldSceneName = $($INPUT).val();
      $($INPUT).attr('readonly', false).focus();
      this.isListMoveMode = true;
    },
    // 엔터키 입력시 blur
    onKeypress(e) {
      if (e.keyCode === 13) {
        this.onBlurInput(e);
      }
    },
    onBlurInput(e) {
      if (!$(e.currentTarget).is('[readonly="readonly"]')) {
        // 값을 입력하지 않고 입력 모드 종료되면, 예전 값이 다시 들어감.
        if ($(e.currentTarget).val().trim() === '' && this.oldSceneName) {
          $(e.currentTarget).val(this.oldSceneName);

          if (this.activatedType === 'group') {
            this.custom.scenes[this.selectedSceneGroup.key].name = this.oldSceneName;
          } else {
            this.custom.scenes[this.selectedScene.key].name = this.oldSceneName;
          }
        }
        $(e.currentTarget).attr('readonly', 'readonly').blur();
        this.isListMoveMode = false;
        this.setSceneArray();
      }
    },
    setStoreScenes(_scenes) {
      this.SET_SCENES(_scenes);
      this.SET_STORE_SCENE_ARRAY(this.sceneArray);
    },
    onSelectScene(e, _outerScene, _innerScene = null) {
      e.stopPropagation();
      if ($(e.target.parentNode).attr('data-icon') === 'add' || this.isDragging) {
        return;
      }
      let outerScene = JSON.parse(JSON.stringify(_outerScene));

      // group 내 inner scene 선택
      if (_innerScene) {
        let innerScene = JSON.parse(JSON.stringify(_innerScene));
        this.SET_SELECTED_SCENE_GROUP(outerScene);
        this.SET_SELECTED_SCENE(innerScene);
        this.SET_ACTIVATED_TYPE('inner');
      }
      // group 선택
      else if (outerScene.type === 'group') {
        if (this.selectedScene.parentKey !== outerScene.key) {
          let selectedInnerScene = JSON.parse(JSON.stringify(outerScene.innerScenes[0]));
          this.SET_SELECTED_SCENE(selectedInnerScene);
        }
        this.SET_SELECTED_SCENE_GROUP(outerScene);
        this.SET_ACTIVATED_TYPE('group');
      }
      // single scene 선택
      else {
        this.SET_SELECTED_SCENE_GROUP(null);
        this.SET_SELECTED_SCENE(outerScene);
        this.SET_ACTIVATED_TYPE('single');
        this.prevSelectedScene = outerScene;
      }
    },
    newScene({ uuid, sceneType, sceneName, groupType = null, parentKey = null, arItems = [] }) {
      return {
        key: uuid,
        type: sceneType,
        name: sceneName,
        updateDT: null,
        groupType: groupType,
        title: groupType ? '' : null,
        parentKey: parentKey, // 해당 scene의 parent scene의 key
        subtitle: parentKey ? '' : null, // 가이드 소제목
        description: parentKey ? '' : null, // 가이드 내용
        viewOrder: parentKey ? 0 : null,
        arItems: groupType ? null : arItems,
      };
    },
    addScene(sceneType, groupType = null) {
      const uuid = createUuid();
      switch (sceneType) {
        case 'single':
          this.custom.scenes[uuid] = this.newScene({ uuid: uuid, sceneType: sceneType, sceneName: 'Scene' });
          this.custom.scenes[uuid].viewOrder = this.sceneArray.length;
          this.setSceneArray();
          this.SET_SELECTED_SCENE_GROUP(null);
          this.SET_SELECTED_SCENE(this.custom.scenes[uuid]);
          this.SET_ACTIVATED_TYPE('single');
          break;
        case 'group':
          this.custom.scenes[uuid] = this.newScene({
            uuid: uuid,
            sceneType: sceneType,
            sceneName: groupType,
            groupType: groupType,
          });
          this.custom.scenes[uuid].viewOrder = this.sceneArray.length;
          this.addInnerScene(uuid, true);
          break;
        case 'reference':
          this.custom.scenes[uuid] = this.newScene({
            uuid: uuid,
            sceneType: sceneType,
            sceneName: 'Scene',
            arItems: [this.checkSheetAssetInReference],
          });
          return uuid;
        default:
          break;
      }
      setTimeout(() => {
        let $SCENE_LIST = document.getElementById('scene-list');
        $SCENE_LIST.scrollTop = $SCENE_LIST.scrollHeight;
      }, 5);
    },
    addInnerScene(groupKey, isFirstOfGroup = false) {
      let uuid = createUuid();
      this.custom.scenes[uuid] = this.newScene({
        uuid: uuid,
        sceneType: 'inner',
        sceneName: 'Scene',
        parentKey: groupKey,
      });
      if (!isFirstOfGroup) {
        this.sceneArray.map((scene) => {
          if (scene.key === groupKey) {
            this.custom.scenes[uuid].viewOrder = scene.innerScenes.length;
            return;
          }
        });
      }
      this.setSceneArray();
      this.SET_SELECTED_SCENE_GROUP(this.custom.scenes[groupKey]);
      this.SET_SELECTED_SCENE(this.custom.scenes[uuid]);
      this.SET_ACTIVATED_TYPE('inner');

      setTimeout(() => {
        let $SCENE_LIST = document.getElementById('scene-list');
        $SCENE_LIST.scrollTop = $SCENE_LIST.scrollTop + 32;
      }, 5);
    },
    onSelectLibrary(sceneType) {
      if (sceneType === 'Basic Scene') {
        this.addScene('single');
      } else {
        this.addScene('group', sceneType);
      }
    },
    newSceneArrayItem(_scene) {
      return {
        key: _scene.key,
        viewOrder: _scene.viewOrder,
        type: _scene.type,
        name: _scene.name,
        parentKey: _scene.parentKey,
        innerScenes: [],
      };
    },
    setSceneArray() {
      let sceneArray = [];

      Object.keys(this.custom.scenes).forEach((key) => {
        let sceneArrayItem = this.newSceneArrayItem(this.custom.scenes[key]);

        // Scene Type - inner 일 경우
        if (this.custom.scenes[key].type === 'inner') {
          sceneArray.forEach((arrayItem) => {
            if (this.custom.scenes[key].parentKey === arrayItem.key) {
              arrayItem.innerScenes.push(sceneArrayItem);
              arrayItem.innerScenes.sort(function (a, b) {
                return a.viewOrder < b.viewOrder ? -1 : a.viewOrder > b.viewOrder ? 1 : 0;
              });
            }
          });
        }
        // Scene Type - single, group 일 경우
        else {
          sceneArray.push(sceneArrayItem);
        }
      });

      sceneArray.sort(function (a, b) {
        return a.viewOrder < b.viewOrder ? -1 : a.viewOrder > b.viewOrder ? 1 : 0;
      });

      this.sceneArray = sceneArray.filter((scene) => {
        return scene.type !== 'reference';
      });

      this.sceneArray.forEach((scene, index) => {
        this.custom.scenes[scene.key].viewOrder = index;

        if (scene.type === 'group') {
          scene.innerScenes.map((innerScene, index) => {
            this.custom.scenes[innerScene.key].viewOrder = index;
          });
        }
      });
    },
    // drag and drop
    handleDragStart(e, scene, index) {
      e.stopPropagation();
      this.isDragging = true;
      this.dragScene = scene;
      this.dragIndex = index;
      this.dragStartElement = e.target;
      e.target.classList.add('start');
    },
    handleDragEnter(e) {
      e.stopPropagation();
    },
    handleDragOver(e, index) {
      e.stopPropagation();

      let $TARGET_LI;
      if (e.target.tagName === 'LI') {
        $TARGET_LI = e.target;
      } else {
        $TARGET_LI = e.target.closest('li');
      }

      if ($TARGET_LI.getAttribute('name') === this.dragStartElement.getAttribute('name')) {
        // 같은 단계 끼리만 순서 변경
        if ($TARGET_LI !== this.dragStartElement) {
          $('.over-top').removeClass('over-top');
          $('.over-bottom').removeClass('over-bottom');

          if (index >= this.dragIndex) {
            $TARGET_LI.classList.add('over-bottom');
          } else {
            $TARGET_LI.classList.add('over-top');
          }
        }
        this.enterIndex = index;
      }
    },
    handleDragLeave(e) {
      e.stopPropagation();
    },
    handleDragEnd(e, outerScene, innerScene, store, innerScenes) {
      e.stopPropagation();

      let $TARGET_LI;
      if (e.target.tagName === 'LI') {
        $TARGET_LI = e.target;
      } else {
        $TARGET_LI = e.target.closest('li');
      }

      this.isDragging = false;
      $('.start').removeClass('start');
      if ($TARGET_LI.getAttribute('name') === this.dragStartElement.getAttribute('name')) {
        // 같은 단계 끼리만 순서 변경
        if (this.dragIndex !== this.enterIndex) {
          if (innerScenes && innerScenes.length > 0) {
            innerScenes.splice(this.dragIndex, 1);
            innerScenes.splice(this.enterIndex, 0, this.dragScene);

            innerScenes.map((scene, index) => {
              this.custom.scenes[scene.key].viewOrder = index;
            });
          } else {
            this.sceneArray.splice(this.dragIndex, 1);
            this.sceneArray.splice(this.enterIndex, 0, this.dragScene);
            this.onSelectScene(e, outerScene, innerScene);

            this.sceneArray.map((scene, index) => {
              this.custom.scenes[scene.key].viewOrder = index;
            });
          }
        }
      }
    },
  },
  components: {
    ConfirmPopup,
  },
};
</script>

<style scoped lang="scss">
@import 'SceneList';
</style>
