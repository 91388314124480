<template>
  <div class="scene-right-bar" v-if="selectedScene">
    <div class="section-title" v-show="propertySubtitle">
      <h4>{{ propertySubtitle }}</h4>
    </div>
    <div class="section-contents basic-scroll" :class="{ 'exist-reference-scene': isShowReferenceSceneSection }">
      <div v-if="activatedType.includes('asset') && !selectedElement" class="section-contents-read-only">
        <div>
          <h5>{{ $t('message.propety_layout_title') }}</h5>
          <ul>
            <li>
              <span class="list-name">{{ $t('message.propety_location_lbl') }}</span>
              <label for="property-x-disabled">
                <input
                  type="number"
                  id="property-x-disabled"
                  name="property-x"
                  class="basic-property-element"
                  readonly
                />
                <span class="unit">X</span>
              </label>
              <label for="property-y-disabled">
                <input
                  type="number"
                  id="property-y-disabled"
                  name="property-y"
                  class="basic-property-element"
                  readonly
                />
                <span class="unit">Y</span>
              </label>
            </li>
            <li>
              <span class="list-name">{{ $t('message.propety_size_lbl') }}</span>
              <label for="property-width-disabled">
                <input
                  type="number"
                  id="property-width-disabled"
                  name="property-width"
                  class="basic-property-element"
                  readonly
                />
                <span class="unit">W</span>
              </label>
              <label for="property-height-disabled">
                <img src="/images/lock-gray.svg" alt="Lock Icon" style="margin: 6px 1px 0px 1px; opacity: 0.2" />
                <input
                  type="number"
                  id="property-height-disabled"
                  name="property-height"
                  class="basic-property-element"
                  readonly
                />
                <span class="unit">H</span>
              </label>
            </li>
          </ul>
        </div>
      </div>
      <!--      레이아웃-->
      <div
        v-if="activatedType.includes('asset') && selectedItem && selectedElement"
        @wheel="onMousewheel"
        @mousedown="onMousedown"
      >
        <div>
          <h5>{{ $t('message.propety_layout_title') }}</h5>
          <ul v-if="selectedItem.x">
            <li>
              <span class="list-name">{{ $t('message.propety_location_lbl') }}</span>
              <label for="property-x">
                <input
                  type="number"
                  id="property-x"
                  name="property-x"
                  :value="selectedItem.x.toFixed(1)"
                  class="basic-property-element"
                  readonly
                  @keydown="onKeypress"
                />
                <span class="unit">X</span>
                <span class="hover-button-wrapper">
                  <span data-icon="arrow-up-gray" @click="addNumbers('property-x')">
                    <img src="/images/arrow-up-gray.png" class="hover-button-image" alt="Arrow Up Icon" />
                  </span>
                  <span data-icon="arrow-down-gray" @click="subNumbers('property-x')">
                    <img src="/images/arrow-down-gray.png" class="hover-button-image" alt="Arrow Down Icon" />
                  </span>
                </span>
              </label>
              <label for="property-y">
                <input
                  type="number"
                  id="property-y"
                  name="property-y"
                  :value="selectedItem.y.toFixed(1)"
                  class="basic-property-element"
                  readonly
                  @keydown="onKeypress"
                />
                <span class="unit">Y</span>
                <span class="hover-button-wrapper">
                  <span data-icon="arrow-up-gray" @click="addNumbers('property-y')">
                    <img src="/images/arrow-up-gray.png" class="hover-button-image" alt="Arrow Up Icon" />
                  </span>
                  <span data-icon="arrow-down-gray" @click="subNumbers('property-y')">
                    <img src="/images/arrow-down-gray.png" class="hover-button-image" alt="Arrow Down Iㄹcon" />
                  </span>
                </span>
              </label>
            </li>
            <li :disabled="['text', 'label', 'checkSheet'].includes(selectedElement.type)">
              <span class="list-name">{{ $t('message.propety_size_lbl') }}</span>
              <label for="property-width">
                <input
                  type="number"
                  id="property-width"
                  name="property-width"
                  readonly
                  :value="(selectedItem.width * selectedItem.scaleX).toFixed(1)"
                  class="basic-property-element"
                  @keydown="onKeypress"
                />
                <span class="unit">W</span>
                <span class="hover-button-wrapper">
                  <span data-icon="arrow-up-gray" @click="addNumbers('property-width')">
                    <img src="/images/arrow-up-gray.png" class="hover-button-image" alt="Arrow Up Icon" />
                  </span>
                  <span data-icon="arrow-down-gray" @click="subNumbers('property-width')">
                    <img src="/images/arrow-down-gray.png" class="hover-button-image" alt="Arrow Down Icon" />
                  </span>
                </span>
              </label>
              <label for="property-height">
                <img
                  src="/images/lock-gray.svg"
                  alt="Lock Icon"
                  style="margin: 6px 1px 0px 1px"
                  :class="{ 'visibility-hidden': !selectedItem.aspectRatio }"
                />
                <input
                  type="number"
                  id="property-height"
                  name="property-height"
                  readonly
                  :value="(selectedItem.height * selectedItem.scaleY).toFixed(1)"
                  class="basic-property-element"
                  @keydown="onKeypress"
                />
                <span class="unit">H</span>
                <span class="hover-button-wrapper">
                  <span data-icon="arrow-up-gray" @click="addNumbers('property-height')">
                    <img src="/images/arrow-up-gray.png" class="hover-button-image" alt="Arrow Up Icon" />
                  </span>
                  <span data-icon="arrow-down-gray" @click="subNumbers('property-height')">
                    <img src="/images/arrow-down-gray.png" class="hover-button-image" alt="Arrow Down Icon" />
                  </span>
                </span>
              </label>
            </li>
            <li
              :disabled="
                selectedElement.type === 'targetQR' ||
                selectedElement.type === 'targetImage' ||
                selectedElement.type === 'guideImage'
              "
            >
              <span class="list-name">{{ $t('message.property_rotation_lbl') }}</span>
              <label for="property-angle">
                <input
                  type="number"
                  id="property-angle"
                  name="property-angle"
                  readonly
                  :value="selectedItem.angle.toFixed(0)"
                  class="basic-property-element"
                  @keydown="onKeypress"
                />
                <span class="unit" style="font-size: 20px">˚</span>
                <span class="hover-button-wrapper">
                  <span data-icon="arrow-up-gray" @click="addNumbers('property-angle')">
                    <img src="/images/arrow-up-gray.png" class="hover-button-image" alt="Arrow Up Icon" />
                  </span>
                  <span data-icon="arrow-down-gray" @click="subNumbers('property-angle')">
                    <img src="/images/arrow-down-gray.png" class="hover-button-image" alt="Arrow Down Icon" />
                  </span>
                </span>
              </label>
              <template>
                <button
                  class="basic-property-element asset-flip-button"
                  data-icon="vertical-flip"
                  :disabled="['text', 'label', 'checkSheet', 'image', 'video'].includes(selectedElement.type)"
                  @click="flipItem('isVerticalFlip')"
                  :class="{ selected: selectedElement.isVerticalFlip }"
                >
                  <vue-material-icon name="flip" :size="16" />
                </button>
                <button
                  class="basic-property-element asset-flip-button"
                  data-icon="horizontal-flip"
                  :disabled="['text', 'label', 'checkSheet', 'image', 'video'].includes(selectedElement.type)"
                  @click="flipItem('isHorizontalFlip')"
                  :class="{ selected: selectedElement.isHorizontalFlip }"
                >
                  <vue-material-icon name="flip" :size="16" />
                </button>
              </template>
            </li>
          </ul>
        </div>
        <!--        텍스트-->
        <div :disabled="!['text', 'label', 'checkSheet'].includes(selectedElement.type)">
          <h5>{{ $t('message.propety_text_title') }}</h5>
          <ul>
            <li>
              <span class="list-name">{{ $t('message.propety_text_style_lbl') }}</span>
              <label for="font-weight-property">
                <select
                  v-model="selectedElement.fontWeight"
                  id="font-weight-property"
                  style="width: 76px"
                  @change="onChangeFontWeight"
                  class="basic-select"
                >
                  <option value="normal">Regular</option>
                  <option value="bold">Bold</option>
                </select>
              </label>
              <label for="font-size-property">
                <select
                  v-model="selectedElement.fontSize"
                  id="font-size-property"
                  style="width: 44px"
                  @change="onChangeTextSize"
                  class="basic-select"
                >
                  <option v-for="(fontSize, index) in fontSizeRange" :key="index" :value="fontSize">
                    {{ fontSize }}
                  </option>
                </select>
              </label>
            </li>
            <li>
              <span class="list-name">{{ $t('message.propety_text_color_lbl') }}</span>
              <span
                :key="index"
                class="color-set"
                v-for="(color, index) in colorSet"
                :class="{ 'color-set-white': color === '#ffffff', selected: color === selectedItem.textColor }"
                :style="{ backgroundColor: color }"
                @click="onSetTextColor(color)"
              ></span>
            </li>
            <li
              class="align"
              :disabled="
                ['label', 'checkSheet'].includes(selectedElement.type) ||
                (selectedElement.name && selectedElement.name.includes('Number'))
              "
            >
              <span class="list-name">{{ $t('message.propety_text_align_lbl') }}</span>
              <span
                :class="{ selected: selectedItem.textAlign === 'left' }"
                data-value="left"
                @click="onSetTextAlign('left')"
              >
                <vue-material-icon name="format_align_left" :size="16" />
              </span>
              <span
                :class="{ selected: selectedItem.textAlign === 'center' }"
                data-value="center"
                @click="onSetTextAlign('center')"
              >
                <vue-material-icon name="format_align_center" :size="16" />
              </span>
              <span
                :class="{ selected: selectedItem.textAlign === 'right' }"
                data-value="right"
                @click="onSetTextAlign('right')"
              >
                <vue-material-icon name="format_align_right" :size="16" />
              </span>
            </li>
          </ul>
        </div>
        <!--        스타일-->
        <div :disabled="['targetQR', 'targetImage', 'guideImage'].includes(selectedElement.type)">
          <h5>{{ $t('message.propety_style_title') }}</h5>
          <ul v-if="selectedItem.opacity >= 0">
            <li :disabled="['symbol', 'image', 'video'].includes(selectedElement.type)">
              <span class="list-name">{{ $t('message.propety_style_color_lbl') }}</span>
              <span
                :key="index"
                class="color-set"
                v-for="(color, index) in colorSet"
                :class="{ 'color-set-white': color === '#ffffff', selected: color === selectedItem.backgroundColor }"
                :style="{ backgroundColor: color }"
                @click="onSetBackgroundColor(color)"
              ></span>
            </li>
            <li>
              <span class="list-name">{{ $t('message.propety_style_opacity_lbl') }}</span>
              <label for="property-opacity">
                <input
                  type="number"
                  id="property-opacity"
                  name="property-opacity"
                  v-model="opacityRangeValue"
                  class="basic-property-element"
                  readonly
                  @keydown="onKeypress"
                />
                <span class="unit">%</span>
                <span class="hover-button-wrapper">
                  <span data-icon="arrow-up-gray" @click="addNumbers('property-opacity')">
                    <img src="/images/arrow-up-gray.png" class="hover-button-image" alt="Arrow Up Icon" />
                  </span>
                  <span data-icon="arrow-down-gray" @click="subNumbers('property-opacity')">
                    <img src="/images/arrow-down-gray.png" class="hover-button-image" alt="Arrow Down Icon" />
                  </span>
                </span>
              </label>
              <label for="range">
                <input
                  type="range"
                  id="range"
                  class="range"
                  min="0"
                  max="100"
                  step="1"
                  v-model="opacityRangeValue"
                  :style="`background: linear-gradient(to right, #2fa2eb ${opacityRangeValue}%, #CED4DA ${opacityRangeValue}%)`"
                />
              </label>
            </li>
            <li class="anchor" :disabled="selectedElement.name && !selectedElement.name.includes('Anchor')">
              <span class="list-name">{{ $t('message.propety_style_anchor_title') }}</span>
              <span
                :key="anchor"
                v-for="(imgSrc, anchor) in anchorTypes"
                :class="{ selected: getAnchorType(selectedItem.name) === anchor }"
                @click="onSetAnchor(anchor)"
                v-html="imgSrc"
              ></span>
            </li>
          </ul>
        </div>
        <!--        액션-->
        <div v-if="isActiveActionProperties" class="action-properties">
          <h5 :disabled="isDisabledAction">{{ $t('message.propety_style_action_title') }}</h5>
          <ul>
            <li>
              <span class="list-name" :disabled="isDisabledAction">
                {{ $t('message.propety_style_actiontype_title') }}
              </span>
              <label for="action-property">
                <select v-model="actionType" id="action-property" :disabled="isDisabledAction" class="basic-select">
                  <option :value="null" selected>{{ $t('message.propety_style_na_lbl') }}</option>
                  <option value="scene">{{ $t('message.propety_style_move_lbl') }}</option>
                  <option value="url">{{ $t('message.propety_style_URL_lbl') }}</option>
                </select>
              </label>
            </li>
            <li v-show="actionType">
              <span class="list-name">{{ actionType === 'scene' ? $t('message.propety_style_move_lbl') : 'URL' }}</span>
              <label for="action-scene" v-show="actionType === 'scene'">
                <select
                  v-model="selectedElement.connectedSceneKey"
                  id="action-scene"
                  :disabled="isDisabledAction"
                  class="basic-select"
                >
                  <option :value="null" selected>{{ $t('message.propety_style_na_lbl') }}</option>
                  <template v-for="(scene, index) in actionSceneArray">
                    <option :key="index" v-if="scene" :value="scene.key">{{ scene.name }}</option>
                  </template>
                </select>
              </label>
              <label for="action-url" v-show="actionType === 'url'">
                <input
                  type="text"
                  class="basic-property-element"
                  v-model="connectedUrl"
                  @focus="$emit('focusedPropertyInput', true)"
                  @blur="$emit('focusedPropertyInput', false)"
                  id="action-url"
                />
              </label>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="!activatedType.includes('asset')">
        <div>
          <h5>{{ $t('message.propety_info_title') }}</h5>
          <ul class="scene-info-list">
            <li>
              <span class="list-name">{{ $t('message.propety_info_name_lbl') }}</span>
              <span v-if="activatedType === 'inner'">
                {{ scenes[selectedScene.key].name }}
              </span>
              <span v-if="activatedType === 'group'">
                {{ scenes[selectedSceneGroup.key].name }}
              </span>
              <span v-if="activatedType === 'single'">
                {{ scenes[selectedScene.key].name }}
              </span>
            </li>
          </ul>
        </div>
        <div v-if="activatedType === 'group' || activatedType === 'inner'">
          <h5>{{ $t('message.propety_guide_title') }}</h5>
          <ul class="scene-guide-list">
            <li v-if="activatedType === 'group'">
              <span class="list-name">{{ $t('message.propety_guide_name_lbl') }}</span>
              <label>
                <textarea
                  type="text"
                  name="Guide Title"
                  v-model="scenes[selectedSceneGroup.key].title"
                  maxlength="30"
                  class="basic-property-element basic-scroll"
                  :placeholder="$t('message.propety_guide_name_phd')"
                  @focus="$emit('focusedPropertyInput', true)"
                  @blur="$emit('focusedPropertyInput', false)"
                ></textarea>
              </label>
            </li>
            <li v-if="activatedType === 'inner'">
              <span class="list-name">{{ $t('message.propety_guide_step_lbl') }}</span>
              <label>
                <textarea
                  type="text"
                  name="Guide Subtitle"
                  v-model="scenes[selectedScene.key].subtitle"
                  maxlength="70"
                  class="basic-property-element basic-scroll"
                  :placeholder="$t('message.propety_guide_step_phd')"
                  @focus="$emit('focusedPropertyInput', true)"
                  @blur="$emit('focusedPropertyInput', false)"
                ></textarea>
              </label>
            </li>
            <li v-if="activatedType === 'inner'">
              <span class="list-name">{{ $t('message.propety_guide_content_lbl') }}</span>
              <label>
                <textarea
                  name="Guide Description"
                  v-model="scenes[selectedScene.key].description"
                  maxlength="140"
                  class="basic-property-element basic-scroll"
                  :placeholder="$t('message.propety_guide_content_phd')"
                  @focus="$emit('focusedPropertyInput', true)"
                  @blur="$emit('focusedPropertyInput', false)"
                ></textarea>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="add-check-sheet-scene" v-if="isShowReferenceSceneSection">
      <div class="section-title">
        <h4>{{ $t('message.list_check_sheet_scene_title') }}</h4>
      </div>
      <div class="create-scene">
        <template v-if="!isReferenceScene && referenceSceneUpdateDT()">
          <p class="date">마지막 수정 : {{ referenceSceneUpdateDT() }}</p>
        </template>
        <div class="buttons">
          <template v-if="isReferenceScene">
            <button-basic color="gray-border-1" size="ss" :width="80" :height="24" @click="onCancelReferenceScene">
              취소
            </button-basic>
            <button-basic
              color="blue"
              size="ss"
              :width="80"
              :height="24"
              :disabled="!isChangeReferenceElements"
              @click="onSaveReferenceScene"
            >
              저장
            </button-basic>
          </template>
          <template v-else-if="referenceSceneUpdateDT()">
            <button-basic color="red" size="ss" :width="80" :height="24" @click="onDeleteReferenceScene">
              삭제
            </button-basic>
            <button-basic color="dark" size="ss" :width="80" :height="24" @click="openReferenceScene">
              수정
            </button-basic>
          </template>
          <button-basic v-else color="dark" size="ss" :width="120" :height="24" @click="openReferenceScene">
            참고 씬 만들기
          </button-basic>
        </div>
        <p class="description">
          <vue-material-icon name="info" :size="12" />
          <span v-if="isReferenceScene">
            <b>참고 씬</b>
            저작이 완료되면 저장 버튼을 클릭하세요.
          </span>
          <span v-else>
            설비 점검 앱에서 증강된 체크시트를 탭했을 때 보이는 증강 컨텐츠(Scene)를 저작할 수 있습니다.
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import { mapState } from 'vuex';
import Color from '@/asset/data/Color.json';
import replaceAll from '@/asset/js/replaceAll';
import getAnchorType from '@/asset/js/getAnchorType';

export default {
  name: 'SceneRightBar',
  props: ['selectedItem', 'isReferenceScene', 'isChangeReferenceElements'],
  data() {
    return {
      selectedActionScene: {},
      fontSizeRange: [8, 9, 10, 11, 12, 13, 14, 15, 16, 20, 24, 28, 30],
      colorSet: ['#000000', '#ffffff', '#ff6811', '#fe001f', '#f8e71c', '#7ed321', '#00c5fb', '#009cff', '#9013fe'],
      positionUnit: 2,
      scaleUnit: 5,
      opacityUnit: 1,
      color: {},
      opacityRangeValue: 0,
      actionType: null,
      anchorTypes: {
        bottom:
          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd" transform="translate(1 6)"><rect width="21" height="6" x=".5" y=".5" fill="#FFF" stroke="#848C94" rx="2"/><g fill="#848C94" transform="translate(9.5 6)"><path d="M1 0H2V5H1z"/><circle cx="1.5" cy="5.5" r="1.5"/></g></g></svg>',
        top:
          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd" transform="rotate(-180 11.5 9.5)"><rect width="21" height="6" x=".5" y=".5" fill="#FFF" stroke="#848C94" rx="2"/><g fill="#848C94" transform="translate(9.5 6)"><path d="M1 0H2V5H1z"/><circle cx="1.5" cy="5.5" r="1.5"/></g></g></svg>',
        right:
          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd" transform="translate(1 8.5)"><rect width="15" height="6" x=".5" y=".5" fill="#FFF" stroke="#848C94" rx="2"/><g fill="#848C94" transform="rotate(-90 10 -5)"><path d="M1 0H2V5H1z"/><circle cx="1.5" cy="5.5" r="1.5"/></g></g></svg>',
        left:
          '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24"><g fill="none" fill-rule="evenodd" transform="rotate(-180 11.5 7.75)"><rect width="15" height="6" x=".5" y=".5" fill="#FFF" stroke="#848C94" rx="2"/><g fill="#848C94" transform="rotate(-90 10 -5)"><path d="M1 0H2V5H1z"/><circle cx="1.5" cy="5.5" r="1.5"/></g></g></svg>',
      },
      connectedUrl: null,
    };
  },
  computed: {
    ...mapState('manualTasks', [
      'scenes',
      'selectedScene',
      'selectedSceneGroup',
      'storeSceneArray',
      'selectedElement',
      'activatedType',
    ]),
    isDisabledAction() {
      return ['inner', 'reference'].includes(this.selectedScene.type);
    },
    isShowReferenceSceneSection() {
      return this.isReferenceScene || this.activatedType === 'check_sheet_asset';
    },
    actionSceneArray() {
      return this.storeSceneArray.filter((scene) => {
        return (
          this.selectedScene && scene.key !== 'home' && scene.key !== this.selectedScene.key && scene.type !== 'inner'
        );
      });
    },
    isActiveActionProperties() {
      return (
        this.selectedElement && Object.keys(this.selectedElement).length !== 0 && this.selectedElement.type === 'label'
      );
    },
    propertySubtitle() {
      if (this.activatedType === 'check_sheet_asset') {
        return this.$i18n.messages[this.$lang].message.list_check_sheet_title;
      } else if (this.activatedType.includes('asset')) {
        // asset
        if (!this.selectedElement) {
          // 빈 canvas 클릭 할 때
          return null;
        } else {
          if (this.selectedElement.type === 'targetQR' || this.selectedElement.type === 'targetImage') {
            return this.$i18n.messages[this.$lang].message.task_new_target_lbl;
          } else if (this.selectedElement.type === 'guideImage') {
            return this.$i18n.messages[this.$lang].message.head_scene_guide;
          } else {
            return this.$i18n.messages[this.$lang].message.list_asset_title;
          }
        }
      } else if (this.activatedType === 'group') {
        // group
        return this.scenes[this.selectedSceneGroup.key].groupType;
      } else {
        // single, inner
        return this.$i18n.messages[this.$lang].message.list_scene_title;
      }
    },
  },
  created() {
    this.color = Color;
  },
  mounted() {},
  watch: {
    selectedElement() {
      if (this.selectedElement) {
        this.connectedUrl = null;
        let isExist = false;
        this.actionSceneArray.map((scene) => {
          if (scene.key === this.selectedElement.connectedSceneKey) {
            isExist = true;
          }
        });
        if (!isExist && this.selectedElement.type !== 'checkSheet') {
          this.selectedElement.connectedSceneKey = null;
        }
        if (this.selectedElement.opacity) {
          this.opacityRangeValue = this.selectedElement.opacity * 100;
        }
        if (this.selectedElement.type === 'label') {
          if (this.selectedElement.connectedSceneKey) {
            this.actionType = 'scene';
          } else if (this.selectedElement.connectedUrl) {
            this.actionType = 'url';
            this.connectedUrl = this.selectedElement.connectedUrl;
          } else {
            this.actionType = null;
          }
        }
      }
    },
    opacityRangeValue() {
      this.selectedElement.opacity = this.opacityRangeValue / 100;
      this.setIsChangeCheckSheetProperty();
    },
    actionType() {
      if (this.actionType === 'scene') {
        this.selectedElement.connectedUrl = null;
      } else if (this.actionType === 'url') {
        this.selectedElement.connectedSceneKey = null;
      } else {
        this.selectedElement.connectedUrl = null;
        this.selectedElement.connectedSceneKey = null;
      }
    },
    connectedUrl(newVal) {
      if (newVal) {
        if (this.connectedUrl.startsWith('http')) {
          this.selectedElement.connectedUrl = this.connectedUrl;
        } else {
          this.selectedElement.connectedUrl = 'http://' + this.connectedUrl;
        }
      }
    },
  },
  methods: {
    setIsChangeCheckSheetProperty() {
      if (this.selectedItem.type === 'checkSheet') {
        this.$emit('changePropertyCheckSheetElement');
      }
    },
    referenceSceneUpdateDT() {
      let updateDT;
      if (this.isReferenceScene) {
        updateDT = this.selectedScene.updateDT;
      } else if (this.selectedElement) {
        updateDT = this.scenes[this.selectedElement.connectedSceneKey].updateDT;
      }

      return updateDT ? this.$options.filters.localDateYYYYMMDDTime(updateDT) : null;
    },
    openReferenceScene() {
      this.$emit('openReferenceScene', this.selectedScene.key);
    },
    onSaveReferenceScene() {
      this.$emit('saveReferenceScene', new Date());
    },
    onCancelReferenceScene() {
      this.$emit('cancelReferenceScene');
    },
    onDeleteReferenceScene() {
      this.$emit('deleteReferenceScene');
    },
    getAnchorType(name) {
      return getAnchorType(name);
    },
    onSetAnchor(anchor) {
      this.selectedItem.name = this.selectedItem.name.split('_')[0] + '_' + anchor;
      this.$emit('setAnchor', anchor);
      this.setIsChangeCheckSheetProperty();
    },
    flipItem(flipType) {
      this.selectedElement[flipType] = !this.selectedElement[flipType];
    },
    onChangeTextSize() {
      if (this.selectedElement.subDataFontSize) {
        let fontSize = this.selectedElement.fontSize;
        this.selectedElement.subDataFontSize = this.setSubDataFontSize(fontSize);
      }
      this.$emit('changeTextSize');
    },
    setSubDataFontSize(fontSize) {
      if (fontSize >= 28) {
        return fontSize - 10;
      } else if (fontSize >= 20) {
        return fontSize - 6;
      } else if (fontSize >= 14) {
        return fontSize - 4;
      } else if (fontSize >= 10) {
        return 10;
      }
      return fontSize;
    },
    onChangeFontWeight() {
      this.$emit('changeFontWeight');
    },
    onSetTextColor(color) {
      this.selectedItem.textColor = color;
      this.setIsChangeCheckSheetProperty();
    },
    onSetBackgroundColor(color) {
      if (this.selectedElement) {
        // png
        if (
          typeof this.selectedElement.data === 'string' &&
          this.selectedElement.data &&
          this.selectedElement.data.startsWith('http')
        ) {
          const startIndex = this.selectedElement.name.indexOf('_') + 1;
          const endIndex = this.selectedElement.name.indexOf('.');
          const oldColor = this.selectedElement.name.substring(startIndex, endIndex);

          this.selectedElement.name = replaceAll(this.selectedElement.name, oldColor, this.color[color]);
          this.selectedElement.data = replaceAll(this.selectedElement.data, oldColor, this.color[color]);
        }
        // svg
        if (['pointing', 'figure'].includes(this.selectedElement.type)) {
          this.selectedElement.data = replaceAll(
            this.selectedElement.data,
            this.selectedElement.backgroundColor,
            color,
          );
        }
        this.selectedElement.backgroundColor = color;
      }
      this.setIsChangeCheckSheetProperty();
    },
    onSetTextAlign(align) {
      this.selectedItem.textAlign = align;
    },
    // mousedown 이벤트 감지
    onMousedown(e) {
      $('.selected-input').removeClass('selected-input');

      if (e.target.tagName === 'INPUT' && e.target.type !== 'text') {
        e.target.classList.add('selected-input');
        this.startX = e.pageX;
        this.startY = e.pageY;
      }
    },
    // wheel 이벤트 감지
    onMousewheel(e) {
      if (e.wheelDelta > 0) {
        // 위로 스크롤
        this.addNumbers();
      } else if (e.wheelDelta < 0) {
        // 아래로 스크롤
        this.subNumbers();
      }
    },
    // keypress 이벤트 감지
    onKeypress(e) {
      if (($(e.target).attr('readonly') && e.keyCode === 39) || e.keyCode === 38) {
        // 편집 불가능 모드일 때, 우, 상
        this.addNumbers();
      } else if (($(e.target).attr('readonly') && e.keyCode === 37) || e.keyCode === 40) {
        // 편집 불가능 모드일 때, 좌, 하
        this.subNumbers();
      } else if (e.keyCode === 13) {
        // 엔터키
        $(e.target).blur();
      }
    },
    // 숫자 증가
    addNumbers(type = null) {
      let _type;
      if (type) {
        _type = type;
        this.positionUnit = 1;
        this.scaleUnit = 1;
      } else {
        _type = $('.selected-input').attr('name');
        this.positionUnit = 1;
        this.scaleUnit = 1;
      }
      if (_type === 'property-opacity' && this.opacityRangeValue < 100)
        this.opacityRangeValue = Number(this.opacityRangeValue) + this.opacityUnit;
      if (_type === 'property-x') this.selectedItem.x += this.positionUnit;
      if (_type === 'property-y') this.selectedItem.y -= this.positionUnit;
      if (_type === 'property-angle') {
        if (this.selectedItem.angle + this.positionUnit >= 360) {
          this.selectedItem.angle -= 360;
        }
        this.selectedItem.angle += this.positionUnit;
      }
      // 비율 고정일 경우
      if (this.selectedItem.aspectRatio) {
        if (_type === 'property-width' || _type === 'property-height') {
          this.selectedItem.scaleX += this.scaleUnit / 50;
          this.selectedItem.scaleY += this.scaleUnit / 50;
        }
      } else {
        if (_type === 'property-width') this.selectedItem.scaleX += this.scaleUnit / 50;
        if (_type === 'property-height') this.selectedItem.scaleY += this.scaleUnit / 50;
      }
      this.setIsChangeCheckSheetProperty();
    },
    // 숫자 감소
    subNumbers(type) {
      let _type;
      if (type) {
        _type = type;
        this.positionUnit = 1;
        this.scaleUnit = 1;
      } else {
        _type = $('.selected-input').attr('name');
        this.positionUnit = 1;
        this.scaleUnit = 1;
      }

      if (_type === 'property-opacity' && this.opacityRangeValue > this.opacityUnit)
        this.opacityRangeValue = Number(this.opacityRangeValue) - this.opacityUnit;
      if (_type === 'property-x') this.selectedItem.x -= this.positionUnit;
      if (_type === 'property-y') this.selectedItem.y += this.positionUnit;
      if (_type === 'property-angle') {
        if (this.selectedItem.angle - this.positionUnit < 0) {
          this.selectedItem.angle += 360;
        }
        this.selectedItem.angle -= this.positionUnit;
      }
      // 비율 고정일 경우
      if (this.selectedItem.aspectRatio) {
        if (
          (_type === 'property-width' && this.selectedItem.width * this.selectedItem.scaleX > 20) ||
          (_type === 'property-height' && this.selectedItem.height * this.selectedItem.scaleY > 20)
        ) {
          this.selectedItem.scaleX -= this.scaleUnit / 50;
          this.selectedItem.scaleY -= this.scaleUnit / 50;
        }
      } else {
        if (_type === 'property-width' && this.selectedItem.width * this.selectedItem.scaleX > 20)
          this.selectedItem.scaleX -= this.scaleUnit / 50;
        if (_type === 'property-height' && this.selectedItem.height * this.selectedItem.scaleY > 20)
          this.selectedItem.scaleY -= this.scaleUnit / 50;
      }
      this.setIsChangeCheckSheetProperty();
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import 'SceneRightBar';
</style>
