<template>
  <div class="scene-header">
    <div class="scene-header__left">
      <button class="back-button" @click="onBackPage">
        <span class="icon" data-icon="arrow_back">
          <vue-material-icon name="arrow_back" :size="20" />
        </span>
      </button>
      <h1>AR 컨텐츠 관리</h1>
      <span class="task-name">
        <span>{{ selectedEqpNameCode }}</span>
        <vue-material-icon name="chevron_right" :size="20" />
        <span>{{ content.name }}</span>
      </span>
    </div>
    <div class="scene-header__right">
      <button class="scene-header-button" :disabled="isReferenceScene" @click="togglePopup('TaskPopup')">
        <span class="scene-header-button__icon">
          <vue-material-icon name="info" :size="10" />
        </span>
        상세
      </button>
      <button
        class="scene-header-button"
        :class="{ 'disabled-area': !isExistScene }"
        :disabled="isReferenceScene"
        @click="onSave"
      >
        <span class="scene-header-button__icon">
          <vue-material-icon name="save" :size="10" />
        </span>
        저장
      </button>
      <button
        class="scene-header-button publish"
        v-if="isPossiblePublish"
        @click="onPublish"
        :disabled="isReferenceScene"
        :class="{ 'disabled-area': !isExistScene }"
      >
        <span class="scene-header-button__icon" data-icon="publish">
          <vue-material-icon name="publish" :size="10" />
        </span>
        게시
      </button>
      <button
        class="scene-header-button update"
        v-if="isPossibleUpdate"
        :disabled="isReferenceScene || isImpossibleUpdate"
        :class="{ 'disabled-area': !isExistScene }"
        @click="onPublish"
      >
        <span class="scene-header-button__icon" data-icon="autorenew">
          <vue-material-icon name="autorenew" :size="10" />
        </span>
        업데이트
      </button>
    </div>
    <transition name="modal">
      <task-popup
        v-if="showedPopupName === 'TaskPopup'"
        :selected-eqp-id="selectedTask.manualProjectId"
        :selected-task="selectedTask"
        @close="togglePopup"
      />
    </transition>
    <confirm-popup
      :isShow="showedPopupName === 'ConfirmPopup'"
      :contentsKey="popupContentsKey"
      @close="onCancelConfirmInPopup"
      @confirm="onConfirmInPopup"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TaskPopup from '@/component/popup/taskPopup/TaskPopup';
import ConfirmPopup from '@/component/popup/confirmPopup/ConfirmPopup';
import eqpNameCodeByGroup from '@/asset/js/eqpNameCodeByGroup';

export default {
  name: 'SceneHeader',
  props: ['isChangeElements', 'isExistScene', 'isReferenceScene'],
  data() {
    return {
      showedPopupName: '',
      popupContentsKey: '',
    };
  },
  computed: {
    ...mapState('manualTasks', ['selectedTask']),
    ...mapState('factoryEquipments', ['selectedEqpByGroup']),
    selectedEqpNameCode() {
      return eqpNameCodeByGroup(this);
    },
    content() {
      return this.selectedTask.saveContent ? this.selectedTask.saveContent : this.selectedTask.publishContent;
    },
    // 현재 unpublish 상태 (publish 가능)
    isPossiblePublish() {
      return !this.selectedTask.publishContent && !!this.selectedTask.saveContent;
    },
    // 현재 publish 상태인데 새롭게 save된 custom이 존재한 상태 (update 가능)
    isPossibleUpdate() {
      return !!this.selectedTask.publishContent;
    },
    // saveContent가 없거나 custom이 바뀐 것이 없는 상태 (update 불가능)
    isImpossibleUpdate() {
      return !this.selectedTask.saveContent && !this.isChangeElements;
    },
  },
  mounted() {},
  watch: {},
  methods: {
    async onConfirmInPopup() {
      switch (this.popupContentsKey) {
        case 'SCENE_EXIT_SAVE':
          await this.onExit(true);
          break;
        default:
          break;
      }
    },
    async onCancelConfirmInPopup() {
      switch (this.popupContentsKey) {
        case 'SCENE_EXIT_SAVE':
          await this.onExit(false);
          this.togglePopup();
          break;
        default:
          this.togglePopup();
          break;
      }
    },
    togglePopup(popupName, popupContentsKey) {
      this.showedPopupName = popupName || '';
      this.popupContentsKey = popupContentsKey || '';
    },
    onBackPage() {
      this.$emit('clickedCustomBack');
      if (this.isChangeElements) {
        this.togglePopup('ConfirmPopup', 'SCENE_EXIT_SAVE');
      } else {
        this.$router.back();
      }
    },
    onSave() {
      this.$emit('save');
    },
    async onExit(_isSave) {
      if (_isSave) {
        await this.onSave();
        this.$router.back();
      } else {
        this.$router.back();
      }
    },
    onPublish() {
      this.$emit('publish');
    },
    togglePreview() {
      this.isOpenPreview = !this.isOpenPreview;
    },
  },
  components: { ConfirmPopup, TaskPopup },
};
</script>

<style scoped lang="scss">
@import 'SceneHeader';
</style>
