import $ from 'jquery';

export default (_type) => {
  let str = `.${_type.replace(' ', '')}`;
  let ul = document.querySelector(`${str} ul`);
  let arrow_down = document.querySelector(`${str} .arrow_down`);
  let arrow_right = document.querySelector(`${str} .arrow_right`);

  if (ul.classList.contains('closed')) {
    ul.classList.remove('closed');
    $(`${str} ul`).slideDown(200);
    arrow_down.classList.remove('disabled');
    arrow_right.classList.add('disabled');
  } else {
    ul.classList.add('closed');
    $(`${str} ul`).slideUp(200);
    arrow_down.classList.add('disabled');
    arrow_right.classList.remove('disabled');
  }
};
