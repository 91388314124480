<template>
  <section class="main-section lnb-small-section" v-if="content" :class="{ 'loading-cursor': isLoadingFile }">
    <template v-if="content">
      <scene-header
        :isChangeElements="isChangeElements"
        :isExistScene="isExistScene"
        :isReferenceScene="isReferenceScene"
        @clickedCustomBack="isClickCustomBack = true"
        @save="putTaskCustom(false)"
        @publish="putTaskCustom(true)"
      />
      <scene-left-bar
        v-if="isInitRendered"
        @isLoadingFile="setIsLoadingFile"
        @isChangeScene="onChangeScene"
        @copiedScene="onCopiedScene"
        :isExistScene="isExistScene"
        :isGetTempoSavedCustom="isGetTempoSavedCustom"
        :taskId="selectedTask.id"
        :checkSheetItems="checkSheetItems"
        :checkSheetAssetInReference="checkSheetAssetInReference"
        :isReferenceScene="isReferenceScene"
        :usedCheckSheetItemIds="checkSheetItemIdsInCurrentScene"
        @uploadMedia="addMediaElement"
        @addedReferenceScene="onAddedReferenceScene"
      />
      <scene-right-bar
        v-if="isInitRendered"
        @changeTextSize="resizeText(true, 'size')"
        @changeFontWeight="resizeText(true, 'weight')"
        @focusedPropertyInput="focusedPropertyInput"
        @setAnchor="onChangeAnchor"
        @openReferenceScene="openReferenceScene"
        @saveReferenceScene="onSaveReferenceScene"
        @cancelReferenceScene="onCancelReferenceScene"
        @deleteReferenceScene="onDeleteReferenceScene"
        @changePropertyCheckSheetElement="onChangePropertyCheckSheetElement"
        :isExistScene="isExistScene"
        :selectedItem="selectedItem"
        :isReferenceScene="isReferenceScene"
        :isChangeReferenceElements="isChangeReferenceElements"
      />
    </template>
    <div class="authoring-section">
      <button class="delete-asset-button" @click="deleteElementAll" :class="{ 'disabled-area': !isExistScene }">
        <vue-material-icon name="delete" size="17" />
      </button>
      <!-- drop 공간 -->
      <drop
        class="drop basic-scroll"
        @drop="handleDrop(...arguments)"
        oncontextmenu="return false"
        ondragstart="return false"
        onselectstart="return false"
      >
        <div
          id="workspace_wrapper"
          class="workspace_wrapper"
          ref="workspace_wrapper"
          :class="{ 'disabled-area': !isExistScene }"
        >
          <div class="workspace" id="workspace" ref="workspace" @mouseup="onClickCanvas">
            <div class="transWrapper">
              <template v-for="element in elements">
                <free-transform
                  :class="[
                    'tr-transform__' + element.type,
                    { 'pointer-events-none': element.type === 'checkSheet' && isReferenceScene },
                  ]"
                  :id="element.id"
                  :key="element.id"
                  :x="element.x"
                  :y="element.y"
                  :scale-x="element.scaleX"
                  :scale-y="element.scaleY"
                  :width="element.width"
                  :height="element.height"
                  :offset-x="offsetX"
                  :offset-y="offsetY"
                  :angle="element.angle"
                  :disable-scale="element.disableScale"
                  :scale-from-center="element.scaleForCenter"
                  :aspectRatio="element.aspectRatio"
                  :selected="selectedElement && element.id === selectedElement.id"
                  :selectOn="selectWay(element)"
                  :title="element.name"
                  @onSelect="onSelectElement(element)"
                  @update="update(element, $event)"
                  @mouseup="onMouseup(element)"
                  @dblclick="onDblclick(element, $event)"
                >
                  <!-- targetQR -->
                  <div v-if="element.type === 'targetQR'" class="element">
                    <vue-qr-code
                      :value="element.data"
                      class="qr-code"
                      :margin="0"
                      errorCorrectionLevel="H"
                    ></vue-qr-code>
                  </div>
                  <!-- targetImage / guideImage / asset - Image -->
                  <div
                    v-if="imageTypes.includes(element.type)"
                    class="element"
                    :class="'element__' + element.type"
                    :style="{ opacity: element.opacity }"
                  >
                    <img :src="element.data" alt="Image" />
                  </div>
                  <!-- 비디오 -->
                  <div v-if="element.type === 'video'" class="element" :style="{ opacity: element.opacity }">
                    <img v-if="element.thumbnail" :src="element.thumbnail" alt="Video Thumbnail" />
                    <span data-icon="play_circle_filled">
                      <vue-material-icon name="play_circle_filled" :size="30" />
                    </span>
                  </div>
                  <!-- 텍스트 -->
                  <div v-if="element.type === 'text' && element.name === 'Text'" class="element text-element">
                    <label>
                      <textarea
                        v-model="element.data"
                        :id="'asset' + element.id"
                        @focus="resizeText(false)"
                        @blur="onTextBlur(element)"
                        readonly="readonly"
                        autocomplete="off"
                        @keydown="resizeText(false)"
                        @keyup="resizeText(false)"
                        :placeholder="$t('message.propety_guide_content_phd')"
                        :style="{
                          color: element.textColor,
                          backgroundColor: element.backgroundColor,
                          textAlign: element.textAlign,
                          fontWeight: element.fontWeight,
                          fontSize: element.fontSize + 'pt',
                          opacity: element.opacity,
                          padding: '7.5px 10px',
                        }"
                      ></textarea>
                    </label>
                  </div>
                  <!-- 텍스트 Number-->
                  <div
                    v-if="element.type === 'text' && element.name && element.name.includes('Number')"
                    class="element text-element numbering"
                    :data-type="element.name"
                  >
                    <label>
                      <input
                        type="number"
                        v-model="element.data"
                        :id="'asset' + element.id"
                        max="999"
                        min="-99"
                        maxlength="3"
                        readonly="readonly"
                        autocomplete="off"
                        @blur="onTextBlur(element, true)"
                        @keydown="onKeydownLabel($event, element)"
                        @input="maxLengthCheck($event, element)"
                        :style="{
                          color: element.textColor,
                          backgroundColor: '#ffffff',
                          boxShadow: `0 0 0 3px ${element.backgroundColor} inset`,
                          textAlign: element.textAlign,
                          fontWeight: element.fontWeight,
                          fontSize: element.fontSize + 'pt',
                          opacity: element.opacity,
                        }"
                      />
                    </label>
                  </div>
                  <!-- 버튼 Anchor Type 1 -->
                  <div
                    v-if="element.type === 'label' && element.name && element.name.includes('Anchor Type 1')"
                    class="element label-element text-type-1"
                    :class="getAnchorType(element.name)"
                  >
                    <label>
                      <input
                        type="text"
                        v-model="element.data"
                        :id="'asset' + element.id"
                        readonly="readonly"
                        @keydown="onKeydownLabel($event, element)"
                        @keyup="resizeText(false)"
                        autocomplete="off"
                        @blur="onTextBlur(element)"
                        :placeholder="$t('message.propety_guide_content_phd')"
                        maxlength="30"
                        :style="{
                          color: element.textColor,
                          backgroundColor: element.backgroundColor,
                          textAlign: element.textAlign,
                          fontWeight: element.fontWeight,
                          fontSize: element.fontSize + 'pt',
                          opacity: element.opacity,
                        }"
                      />
                    </label>
                    <span
                      class="anchor-triangle"
                      :style="{ borderColor: `${element.backgroundColor}`, opacity: element.opacity }"
                    />
                  </div>
                  <!-- 버튼 Anchor Type 2 -->
                  <div
                    v-if="element.type === 'label' && element.name && element.name.includes('Anchor Type 2')"
                    class="element label-element text-type-2"
                    :class="getAnchorType(element.name)"
                  >
                    <label>
                      <input
                        type="text"
                        v-model="element.data"
                        :id="'asset' + element.id"
                        readonly="readonly"
                        @keydown="onKeydownLabel($event, element)"
                        @keyup="resizeText(false)"
                        autocomplete="off"
                        @blur="onTextBlur(element)"
                        :placeholder="$t('message.propety_guide_content_phd')"
                        maxlength="30"
                        :style="{
                          color: element.textColor,
                          backgroundColor: '#ffffff',
                          boxShadow: `0 0 0 3px ${element.backgroundColor} inset`,
                          textAlign: element.textAlign,
                          fontWeight: element.fontWeight,
                          fontSize: element.fontSize + 'pt',
                          opacity: element.opacity,
                        }"
                      />
                    </label>
                    <span
                      class="anchor-line"
                      :style="{ backgroundColor: element.backgroundColor, opacity: element.opacity }"
                    ></span>
                    <span
                      class="anchor-circle"
                      :style="{ boxShadow: `0 0 0 3px ${element.backgroundColor} inset`, opacity: element.opacity }"
                    ></span>
                  </div>
                  <!-- 버튼 numbering -->
                  <div
                    v-if="element.type === 'label' && element.name === 'Numbering'"
                    class="element label-element numbering"
                  >
                    <label>
                      <input
                        type="number"
                        v-model="element.data"
                        :id="'asset' + element.id"
                        max="999"
                        min="-99"
                        maxlength="3"
                        readonly="readonly"
                        autocomplete="off"
                        @blur="onTextBlur(element, true)"
                        @keydown="onKeydownLabel($event, element)"
                        @input="maxLengthCheck($event, element)"
                        :style="{
                          color: element.textColor,
                          backgroundColor: '#ffffff',
                          boxShadow: `0 0 0 3px ${element.backgroundColor} inset`,
                          textAlign: element.textAlign,
                          fontWeight: element.fontWeight,
                          fontSize: element.fontSize + 'pt',
                          opacity: element.opacity,
                        }"
                      />
                    </label>
                  </div>
                  <!-- 버튼 Button Type 1-->
                  <div
                    v-if="element.type === 'label' && element.name && element.name.includes('Button Type 1')"
                    class="element label-element button-type"
                    :class="getAnchorType(element.name)"
                    :data-type="element.name"
                  >
                    <label :style="{ opacity: element.opacity }">
                      <span
                        v-if="element.name.includes('2')"
                        class="asset-button-number"
                        :style="{ backgroundColor: element.backgroundColor }"
                      />
                      <input
                        type="text"
                        v-model="element.data"
                        :id="'asset' + element.id"
                        readonly="readonly"
                        @keydown="onKeydownLabel($event, element)"
                        @keyup="resizeText(false)"
                        autocomplete="off"
                        @blur="onTextBlur(element)"
                        :placeholder="$t('message.propety_guide_content_phd')"
                        maxlength="30"
                        :style="{
                          color: element.textColor,
                          backgroundColor: element.backgroundColor,
                          borderRadius: element.height - 15 + 'px',
                          textAlign: element.textAlign,
                          fontWeight: element.fontWeight,
                          fontSize: element.fontSize + 'pt',
                        }"
                      />
                      <span
                        v-if="element.name.includes('1')"
                        data-icon="asset-button-arrow"
                        class="arrow-right icon-vertical-top"
                        :style="{
                          color: element.textColor,
                          top: element.height / 2 - (Number(element.fontSize) + 8) / 2 + 'px',
                        }"
                      >
                        <vue-material-icon name="arrow_forward" :size="Number(element.fontSize) + 8" />
                      </span>
                    </label>
                  </div>
                  <!-- 버튼 Button Type 2-->
                  <div
                    v-if="element.type === 'label' && element.name && element.name.includes('Button Type 2')"
                    class="element label-element button-type"
                    :class="getAnchorType(element.name)"
                    :data-type="element.name"
                  >
                    <label :style="{ opacity: element.opacity }">
                      <span
                        v-if="element.name.includes('2')"
                        class="asset-button-number"
                        :style="{ backgroundColor: element.backgroundColor, width: element.height + 'px' }"
                      >
                        <input
                          type="number"
                          :id="'number-asset' + element.id"
                          v-model="element.subData"
                          max="999"
                          min="-99"
                          maxlength="3"
                          readonly="readonly"
                          autocomplete="off"
                          @blur="onTextBlur(element, true)"
                          @keydown="onKeydownLabel($event, element)"
                          @input="maxLengthCheck($event, element)"
                          :style="{
                            color: setButtonType2Color('color', element.backgroundColor),
                            fontWeight: element.fontWeight,
                            fontSize: element.subDataFontSize + 'pt',
                          }"
                        />
                      </span>
                      <input
                        type="text"
                        v-model="element.data"
                        :id="'asset' + element.id"
                        readonly="readonly"
                        @keydown="onKeydownLabel($event, element)"
                        @keyup="resizeText(false)"
                        autocomplete="off"
                        @blur="onTextBlur(element)"
                        :placeholder="$t('message.propety_guide_content_phd')"
                        maxlength="30"
                        :style="{
                          color: element.textColor,
                          backgroundColor: setButtonType2Color('background', element.backgroundColor),
                          boxShadow: `0 0 0 3px ${element.backgroundColor} inset`,
                          textAlign: element.textAlign,
                          fontWeight: element.fontWeight,
                          fontSize: element.fontSize + 'pt',
                          borderRadius: element.height - 15 + 'px',
                        }"
                      />
                      <span
                        v-if="element.name.includes('1')"
                        data-icon="asset-button-arrow"
                        class="icon-vertical-top"
                        :style="{
                          color: element.textColor,
                          top: element.height / 2 - (Number(element.fontSize) + 8) / 2 + 'px',
                        }"
                      >
                        <vue-material-icon
                          name="arrow_forward"
                          :size="Number(element.fontSize) + 8"
                        ></vue-material-icon>
                      </span>
                    </label>
                  </div>
                  <!-- 버튼 Button Type 3-->
                  <div
                    v-if="element.type === 'label' && element.name && element.name.includes('Button Type 3')"
                    class="element label-element button-type"
                    :class="getAnchorType(element.name)"
                    :data-type="element.name"
                  >
                    <label>
                      <span
                        v-if="element.name.includes('2')"
                        class="asset-button-number"
                        :style="{ backgroundColor: element.backgroundColor }"
                      ></span>
                      <input
                        type="text"
                        v-model="element.data"
                        :id="'asset' + element.id"
                        readonly="readonly"
                        @keydown="onKeydownLabel($event, element)"
                        @keyup="resizeText(false)"
                        autocomplete="off"
                        @blur="onTextBlur(element)"
                        :placeholder="$t('message.propety_guide_content_phd')"
                        maxlength="30"
                        :style="{
                          color: element.textColor,
                          backgroundColor: element.backgroundColor,
                          textAlign: element.textAlign,
                          fontWeight: element.fontWeight,
                          fontSize: element.fontSize + 'pt',
                          opacity: element.opacity,
                        }"
                      />
                      <span
                        v-if="element.name.includes('1')"
                        data-icon="asset-button-arrow"
                        class="icon-vertical-top"
                        :style="{
                          color: element.textColor,
                          top: element.height / 2 - (Number(element.fontSize) + 8) / 2 + 'px',
                        }"
                      >
                        <vue-material-icon
                          name="arrow_forward"
                          :size="Number(element.fontSize) + 8"
                        ></vue-material-icon>
                      </span>
                    </label>
                  </div>
                  <!-- 도형 -->
                  <div
                    v-if="element.type === 'figure' && !element.data.startsWith('http')"
                    class="element"
                    v-html="element.data"
                    :class="{ horizontal: element.isHorizontalFlip, vertical: element.isVerticalFlip }"
                    :style="{ opacity: element.opacity }"
                  ></div>
                  <div
                    v-if="element.type === 'figure' && element.data.startsWith('http')"
                    class="element"
                    :style="{ opacity: element.opacity }"
                    :class="{ horizontal: element.isHorizontalFlip, vertical: element.isVerticalFlip }"
                  >
                    <img :src="element.data" :alt="element.name" />
                  </div>
                  <!-- 포인팅-->
                  <div
                    v-if="element.type === 'pointing'"
                    class="element"
                    v-html="element.data"
                    :style="{ opacity: element.opacity }"
                  ></div>
                  <!-- 심볼 -->
                  <div
                    v-if="element.type === 'symbol'"
                    class="element"
                    v-html="element.data"
                    :class="{ horizontal: element.isHorizontalFlip, vertical: element.isVerticalFlip }"
                    :style="{ opacity: element.opacity }"
                  ></div>
                  <!-- 체크시트 -->
                  <div
                    v-if="isUsedArCheckSheet(element)"
                    class="element label-element text-type-2"
                    :class="getAnchorType(element.name)"
                    :style="{ opacity: element.opacity }"
                  >
                    <label>
                      <input
                        type="text"
                        :value="element.checkSheetItem.name"
                        :id="'asset' + element.id"
                        class="check-sheet-input"
                        readonly="readonly"
                        autocomplete="off"
                        :style="{
                          color: element.textColor,
                          backgroundColor: '#ffffff',
                          boxShadow: `0 0 0 2px ${element.backgroundColor} inset`,
                          fontWeight: element.fontWeight,
                          fontSize: element.fontSize + 'pt',
                          paddingLeft: Number(element.fontSize) * 1.2 + 'px',
                          paddingRight: Number(element.fontSize) + 8 + 'px',
                          width: getCheckSheetAssetWidth(element),
                        }"
                      />
                      <span
                        class="check-sheet-status"
                        :style="{ borderColor: `${element.backgroundColor}`, width: Number(element.fontSize) + 'px' }"
                      />
                      <span
                        class="arrow-right"
                        :style="{
                          color: element.textColor,
                          top: getCheckSheetAssetTop(element),
                        }"
                      >
                        <vue-material-icon name="chevron_right" :size="Number(element.fontSize) + 8" />
                      </span>
                    </label>
                    <span
                      class="anchor-line check-sheet-anchor-line"
                      :style="{ backgroundColor: element.backgroundColor, opacity: element.opacity }"
                    />
                    <span
                      class="anchor-circle check-sheet-anchor-circle"
                      :style="{ background: `${element.backgroundColor}`, opacity: element.opacity }"
                    />
                    <div
                      class="check-sheet-detail-box"
                      :ref="`check-sheet-detail-box-${element.id}`"
                      :class="{ 'visibility-hidden': !isReferenceScene }"
                      :style="{
                        width: `${(245 / 12) * element.fontSize}px`,
                        boxShadow: `0 0 0 2px ${element.backgroundColor} inset`,
                        padding: `${(10 / 12) * element.fontSize}px`,
                        opacity: element.opacity,
                        top: `${checkBoxDetailTop(element)}px`,
                      }"
                    >
                      <div
                        v-if="element.checkSheetItem.rscFiles.length > 0"
                        class="background-image"
                        :style="{
                          backgroundImage: `url(${element.checkSheetItem.rscFiles[0].rscUrl})`,
                          height: `${(130 / 12) * element.fontSize}px`,
                          marginBottom: `${(5 / 12) * element.fontSize}px`,
                        }"
                      />
                      <span
                        class="check-sheet-cycle"
                        :style="{
                          color: element.textColor,
                          fontSize: element.fontSize + 'pt',
                          margin: `0 ${(5 / 12) * element.fontSize}px ${(4 / 12) * element.fontSize}px`,
                        }"
                      >
                        <span>
                          점검 주기 :
                          <b>{{ checkSheetCycleOptionNames[element.checkSheetItem.chkCycle] }}</b>
                        </span>
                      </span>
                      <p
                        class="white-space-pre-line"
                        :style="{
                          color: element.textColor,
                          fontSize: element.fontSize + 'pt',
                          margin: `0 ${(5 / 12) * element.fontSize}px`,
                        }"
                      >
                        {{ element.checkSheetItem.chkMethodDesc }}
                      </p>
                    </div>
                  </div>
                </free-transform>
              </template>
            </div>
          </div>
        </div>
      </drop>
      <zoom :class="{ 'disabled-area': !isExistScene }"></zoom>
      <div
        id="tempo-text-area"
        class="tempo-text-area"
        v-if="selectedElement"
        :style="{ fontSize: selectedElement.fontSize + 'pt' }"
      />
      <div id="tempo-check-sheet-area" class="tempo-text-area" />
    </div>
    <div class="toast-popup toast-popup-middle">
      {{ toastPopupTitle }}
    </div>
    <confirm-popup
      :isShow="showedPopupName === 'ConfirmPopup'"
      :contentsKey="popupContentsKey"
      @close="onCancelConfirmInPopup"
      @confirm="onConfirmInPopup"
    />
    <div class="toast-popup toast-popup-bottom">
      {{ toastTitle }}
    </div>
  </section>
</template>

<script>
import $ from 'jquery';
import { mapState, mapMutations, mapActions } from 'vuex';
import SceneHeader from './component/sceneHeader/SceneHeader';
import SceneLeftBar from './component/sceneLeftBar/SceneLeftBar';
import SceneRightBar from './component/sceneRightBar/SceneRightBar';
import FreeTransform from '@/plugin/freeTransform/FreeTransform';
import Zoom from '@/component/zoom/Zoom';
import createUuid from '@/asset/js/createUuid';
import keyboardControl from '@/asset/js/keyboardControl';
import isEmptyObject from '@/asset/js/isEmptyObject';
import getAnchorType from '@/asset/js/getAnchorType';
import ConfirmPopup from '@/component/popup/confirmPopup/ConfirmPopup';
import checkCycle from '@/const/checkCycle';

export default {
  name: 'ArContentsDetail',
  data() {
    return {
      toastTitle: '',
      itemId: 0,
      offsetX: 290, // left side bar width
      offsetY: 124, // header height
      elements: [],
      custom: {
        scenes: {},
        commonItems: [],
      },
      standardWidth: 0,
      centerX: 0,
      centerY: 0,
      isChangeElements: false,
      isChangeReferenceElements: false,
      readingImage: '',
      oldSelectedScene: {},
      isEditable: true,
      taskCustomContentTypes: ['targetQR', 'targetImage'],
      impossibleCopyTypes: ['targetQR', 'targetImage', 'checkSheet'],
      targetTypes: ['targetQR', 'targetImage'],
      imageTypes: ['targetImage', 'image'],
      selectedItem: {},
      toastPopupTitle: '',
      toastPopupIcon: '',
      isCopyPasted: false,
      isClickCustomBack: false,
      showedPopupName: '',
      popupContentsKey: '',
      isLoadingFile: false,
      targetRelPositionX: 0,
      targetRelPositionY: 0,
      isFocusedPropertyInput: false,
      copiedElement: null,
      isGetTempoSavedCustom: false,
      checkSheetCycleOptionNames: checkCycle,
      checkSheetItems: [],
      checkSheetAssetInReference: null,
      addingReferenceSceneKey: '',
      addingReferenceSceneParentKey: '',
      isReferenceScene: false,
      prevReferenceSceneArItems: [],
      isInitRendered: false,
    };
  },
  computed: {
    ...mapState('manualTasks', [
      'selectedTask',
      'selectedScene',
      'selectedSceneGroup',
      'scenes',
      'commonItems',
      'selectedElement',
      'isChangeTarget',
      'activatedType',
    ]),
    ...mapState('users', ['myInfo']),
    content() {
      return this.selectedTask.saveContent ? this.selectedTask.saveContent : this.selectedTask.publishContent;
    },
    offsetWidth() {
      return this.$refs.workspace_wrapper.offsetWidth;
    },
    offsetHeight() {
      return this.$refs.workspace_wrapper.offsetHeight;
    },
    isExistScene() {
      return !!this.selectedScene;
    },
    createCurrentCustom() {
      return {
        scenes: this.scenes,
        commonItems: this.commonItems,
      };
    },
    checkSheetItemIdsInCurrentScene() {
      if (this.checkSheetItems.length === 0) return [];
      //task에서(arItem) 체크시트 리스트 아이디 저장
      return this.elements.map((arItem) => {
        if (arItem.type === 'checkSheet') {
          return arItem.checkSheetItem.id;
        }
      });
    },
    isUsedArCheckSheet() {
      return (element) => {
        return element.type === 'checkSheet' && element.name && element.name.includes('Anchor Type');
      };
    },
  },
  created() {
    this.initCheckSheetItems();
  },
  mounted() {
    this.$nextTick(() => {
      keyboardControl(this);
      this.getProject(this.$route.query.project);
      this.getTask(this.$route.params.tId);

      document.addEventListener('keydown', (e) => {
        // backspace, delete key
        if (e.keyCode === 8 || e.keyCode === 46) {
          // text 입력 가능 상태이면 element 안 지움
          if (
            (this.selectedElement &&
              (this.selectedElement.type === 'text' || this.selectedElement.type === 'label') &&
              !$(`#asset${this.selectedElement.id}`).is('[readonly="readonly"]')) ||
            this.isFocusedPropertyInput
          ) {
            return;
          }
          if (this.selectedElement && this.activatedType.includes('asset')) {
            this.deleteElement();
          }
        }
      });
      window.onbeforeunload = (e) => {
        if (this.$route.name === 'ArContentsDetail') {
          let dialogText = 'Dialog text here';
          e.returnValue = dialogText;
          return dialogText;
        }
      };
    });
  },
  watch: {
    selectedScene(newVal, oldVal) {
      if (oldVal && !this.isGetTempoSavedCustom) {
        this.oldSelectedScene = JSON.parse(JSON.stringify(oldVal));
      }
      if (newVal) {
        this.initializeCanvas();
        this.initCheckSheetItems();
        this.checkChangedImage();

        if (newVal.type !== 'reference') {
          this.closeReferenceScene();
        }
      } else {
        // scene 없을 경우
        this.SET_ACTIVATED_TYPE('asset');
        this.deleteElementAll();
      }

      this.isGetTempoSavedCustom = false;
    },
    selectedElement(newVal, oldVal) {
      if (newVal && !isEmptyObject(newVal) && !oldVal) {
        this.isChangeElements = true;
        this.isChangeReferenceElements = true;
      } else if (!newVal) {
        this.SET_ACTIVATED_TYPE('asset');
      }
      this.selectedItem = newVal;
    },
    isChangeTarget(newVal) {
      if (newVal === true) {
        this.removeTarget();
        this.addTarget();
        this.SET_IS_CHANGE_TARGET(false);
      }
    },
    isCopyPasted() {
      if (this.isCopyPasted) {
        if (!this.impossibleCopyTypes.includes(this.copiedElement.type)) {
          this.isCopyPasted = false;

          if (
            // text 입력 가능 상태이면 복사 안됨.
            this.selectedElement &&
            (this.selectedElement.type === 'text' || this.selectedElement.type === 'label') &&
            !$(`#asset${this.selectedElement.id}`).is('[readonly="readonly"]')
          ) {
            return;
          }

          if (this.isFocusedPropertyInput) {
            // property input focus 되어 있으면 복사 안됨
            return;
          }

          const copiedElement = JSON.parse(JSON.stringify(this.copiedElement));
          copiedElement.id = createUuid();
          this.elements.push(copiedElement);
          this.onSelectElement(copiedElement);
          this.openToastPopup('에셋 복제가 완료되었습니다.');
        } else {
          this.isCopyPasted = false;
          if (this.selectedElement.type.includes('target')) {
            this.openToastPopup('타깃이미지는 복제할 수 없습니다.');
          } else {
            this.openToastPopup('해당 에셋은 복제할 수 없습니다.');
          }
        }
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isClickCustomBack && to.name !== 'Login') {
      if (this.isChangeElements) {
        next(false);
        this.togglePopup('ConfirmPopup', 'SCENE_EXIT_SAVE');
      } else {
        next();
      }
    } else {
      next();
    }
  },
  methods: {
    ...mapMutations('manualTasks', [
      'SET_ACTIVATED_TYPE',
      'SET_SCENES',
      'SET_COMMON_ITEMS',
      'SET_SELECTED_ELEMENT',
      'SET_IS_CHANGE_TARGET',
      'SET_SELECTED_SCENE',
    ]),
    ...mapActions('manualProjects', ['GET_PROJECT']),
    ...mapActions('manualTasks', ['GET_TASK', 'PUT_TASK_CUSTOM']),
    ...mapActions('checkSheets', ['GET_RECENT_CHECK_SHEET']),
    openToastPopup(toastTitle) {
      this.toastTitle = toastTitle;
      $('.toast-popup-bottom').fadeIn(200).delay(800).fadeOut(200);
    },
    getCheckSheetAssetWidth(element) {
      const paddingRight = Number(element.fontSize) + 8;
      const paddingLeft = Number(element.fontSize) * 1.2;
      switch (this.getAnchorType(element.name)) {
        case 'right':
        case 'left':
          return element.width - (40 + paddingRight + paddingLeft) + 'px';
        case 'top':
        case 'bottom':
          return element.width - (paddingRight + paddingLeft) + 'px';
      }
    },
    getCheckSheetAssetTop(element) {
      const subValueByFontSize = element.fontSize < 10 ? 2 : 0;
      switch (this.getAnchorType(element.name)) {
        case 'right':
        case 'left':
          return element.height / 2 - (Number(element.fontSize) + 8) / 2 - subValueByFontSize + 'px';
        case 'top':
          return element.height / 2 - (Number(element.fontSize) + 8) / 2 + 20 - subValueByFontSize + 'px';
        case 'bottom':
          return element.height / 2 - (Number(element.fontSize) + 8) / 2 - 20 - subValueByFontSize + 'px';
      }
    },
    checkBoxDetailTop(element) {
      switch (this.getAnchorType(element.name)) {
        case 'top':
        case 'right':
        case 'left':
          return element.height + 5;
        case 'bottom':
          return element.height - 35;
      }
    },
    openReferenceScene(parentKey) {
      this.isChangeReferenceElements = false;
      this.SET_SELECTED_SCENE(this.custom.scenes[this.selectedElement.connectedSceneKey]);
      this.prevReferenceSceneArItems = JSON.parse(JSON.stringify(this.selectedScene.arItems));
      this.addingReferenceSceneParentKey = parentKey;
      this.sameChangeCheckSheetAssetInReference(this.selectedElement);
      setTimeout(() => {
        this.isReferenceScene = true;
      }, 0);
    },
    sameChangeCheckSheetAssetInReference(checkSheetElementInGeneralScene) {
      if (checkSheetElementInGeneralScene.type === 'checkSheet') {
        const referenceSceneKey = checkSheetElementInGeneralScene.connectedSceneKey;
        this.custom.scenes[referenceSceneKey].arItems = this.custom.scenes[referenceSceneKey].arItems.map((arItem) => {
          if (arItem.type === 'checkSheet') {
            let newCheckSheetElement = JSON.parse(JSON.stringify(checkSheetElementInGeneralScene));
            newCheckSheetElement.id = arItem.id;
            newCheckSheetElement.connectedSceneKey = arItem.connectedSceneKey;
            newCheckSheetElement = this.calcRelativeData(newCheckSheetElement);
            return newCheckSheetElement;
          }
          return arItem;
        });
      }
    },
    onChangePropertyCheckSheetElement() {
      this.sameChangeCheckSheetAssetInReference(this.selectedElement);
    },
    onSaveReferenceScene(updateDT) {
      this.togglePopup('ConfirmPopup', 'SAVE_REFERENCE_SCENE');
      this.selectedScene.updateDT = updateDT;
      this.SET_SELECTED_SCENE(this.custom.scenes[this.addingReferenceSceneParentKey]);
      this.closeReferenceScene();
    },
    onCancelReferenceScene() {
      this.selectedScene.arItems = this.prevReferenceSceneArItems;
      this.setElements();
      this.SET_SELECTED_SCENE(this.custom.scenes[this.addingReferenceSceneParentKey]);
      this.closeReferenceScene();
    },
    onDeleteReferenceScene() {
      this.togglePopup('ConfirmPopup', 'DELETE_REFERENCE_SCENE');
    },
    closeReferenceScene() {
      this.isChangeReferenceElements = false;
      this.SET_ACTIVATED_TYPE('single');
      this.isReferenceScene = false;
    },
    onAddedReferenceScene(sceneUuid) {
      this.addingReferenceSceneKey = sceneUuid;
    },
    async initCheckSheetItems() {
      await this.getRecentCheckSheet(this.$route.query.eqp);
      this.syncCheckSheetItem();
    },
    togglePopup(popupName, popupContentsKey) {
      this.showedPopupName = popupName || '';
      this.popupContentsKey = popupContentsKey || '';
    },
    async getRecentCheckSheet(chkGroupId) {
      const checkSheet = await this.GET_RECENT_CHECK_SHEET(chkGroupId);
      this.checkSheetItems = checkSheet ? checkSheet.items : [];
      this.checkSheetItems.sort(function (a, b) {
        return a.viewOrder - b.viewOrder;
      });
    },
    async onConfirmInPopup() {
      switch (this.popupContentsKey) {
        case 'SCENE_EXIT_SAVE':
          await this.onExit(true);
          break;
        case 'DELETE_REFERENCE_SCENE':
          this.custom.scenes[this.selectedElement.connectedSceneKey].updateDT = null;
          this.custom.scenes[this.selectedElement.connectedSceneKey].arItems = this.custom.scenes[
            this.selectedElement.connectedSceneKey
          ].arItems.filter((arItem) => {
            return arItem.type === 'checkSheet';
          });
          this.togglePopup();
          break;
        default:
          break;
      }
    },
    async onCancelConfirmInPopup() {
      switch (this.popupContentsKey) {
        case 'SCENE_EXIT_SAVE':
          await this.onExit(false);
          this.togglePopup();
          break;
        default:
          this.togglePopup();
          break;
      }
    },
    onChangeScene() {
      this.isChangeElements = true;
    },
    setButtonType2Color(type, color) {
      if (color === '#ffffff') {
        if (type === 'color') {
          return '#000000';
        } else {
          return '#e2e2e2';
        }
      } else {
        return '#ffffff';
      }
    },
    onChangeAnchor(anchor) {
      if (anchor === 'right' || anchor === 'left') {
        this.selectedElement.height = 35;
      } else {
        this.selectedElement.height = 75;
      }
      this.resizeText(true, 'size');
    },
    getAnchorType(name) {
      return getAnchorType(name);
    },
    hasGeneralAsset() {
      return this.elements.some((element) => {
        return !this.taskCustomContentTypes.includes(element.type);
      });
    },
    deleteElementAll() {
      if (this.elements && this.hasGeneralAsset()) {
        this.isChangeElements = true;
        this.isChangeReferenceElements = true;
        const maintainElements = [];
        this.elements.forEach((element) => {
          if (this.taskCustomContentTypes.includes(element.type)) {
            maintainElements.push(JSON.parse(JSON.stringify(element)));
          } else if (element.type === 'checkSheet') {
            if (this.isReferenceScene) {
              maintainElements.push(JSON.parse(JSON.stringify(element)));
            } else {
              delete this.custom.scenes[element.connectedSceneKey];
            }
          }
        });
        this.elements = maintainElements;
        this.onSelectElement(null);
      }
    },
    focusedPropertyInput(_isFocusedPropertyInput) {
      this.isFocusedPropertyInput = _isFocusedPropertyInput;
    },
    setIsLoadingFile(_isLoadingFile) {
      this.isLoadingFile = _isLoadingFile;
    },
    async onExit(_isSave) {
      this.isClickCustomBack = true;
      if (_isSave) {
        await this.putTaskCustom(false);
        this.$router.back();
      } else {
        this.$router.back();
      }
      this.togglePopup();
    },
    checkChangedImage() {
      let _targetQR;
      let _targetImage;

      this.custom.commonItems.map((item) => {
        let commonItem = JSON.parse(JSON.stringify(item));
        if (item.type === 'targetQR') {
          _targetQR = commonItem;
        } else if (item.type === 'targetImage') {
          _targetImage = commonItem;
        }
      });

      // target image
      if (this.content.manualTarget.rscURL && _targetImage && this.content.manualTarget.rscURL !== _targetImage.data) {
        this.removeTarget();
        this.addTarget();
      }
      // target qr
      if (this.content.manualTarget.qrCode && _targetQR && this.content.manualTarget.qrCode !== _targetQR.data) {
        this.removeTarget();
        this.addTarget();
      }
    },
    maxLengthCheck(e, element) {
      if (e.target.value.length > e.target.maxLength) {
        if (element.name === 'Button Type 2') {
          element.subData = e.target.value.slice(0, e.target.maxLength);
        } else {
          element.data = e.target.value.slice(0, e.target.maxLength);
        }
      }
    },
    async getProject(pId) {
      try {
        await this.GET_PROJECT(pId);
      } catch (error) {
        if (error.response.status === 404) {
          let path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
    async getTask(tId) {
      try {
        await this.GET_TASK(tId);
      } catch (error) {
        if (error.response.status === 404) {
          let path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
    onKeydownLabel(e, element) {
      if (e.keyCode === 13) {
        this.onTextBlur(element);
      }
      this.resizeText(false);
    },
    resizeText(isChangeTextSize, _type = '') {
      setTimeout(() => {
        if (this.selectedElement) {
          const $TEXT_AREA = document.getElementById(`asset${this.selectedElement.id}`);

          if (!isChangeTextSize && $($TEXT_AREA).is('[readonly="readonly"]')) {
            return;
          }

          if (this.selectedElement.name.includes('Number') && _type === 'weight') {
            return;
          }

          const UNIT_SIZE = this.selectedElement.fontSize * (96 / 72);
          const TEXT_DATA = $(`#asset${this.selectedElement.id}`).val();
          const $TEMPO_TEXT_AREA = document.getElementById('tempo-text-area');
          $TEMPO_TEXT_AREA.innerHTML = TEXT_DATA.length > 0 ? TEXT_DATA : $TEXT_AREA.placeholder;

          let changedWidth = parseFloat(window.getComputedStyle($TEMPO_TEXT_AREA).width);
          let changedHeight = parseFloat(window.getComputedStyle($TEMPO_TEXT_AREA).height);

          switch (this.selectedElement.type) {
            case 'text':
              if (this.selectedElement.name.includes('Number')) {
                if (isChangeTextSize) {
                  this.selectedElement.width = changedWidth + UNIT_SIZE * 2.2;
                  this.selectedElement.height = changedWidth + UNIT_SIZE * 2.2;
                }
              } else if (this.selectedElement.name.includes('Text')) {
                if (isChangeTextSize) {
                  $TEXT_AREA.style.boxSizing = 'border-box';
                } else {
                  $TEXT_AREA.style.boxSizing = 'content-box';
                }
                const TEXTAREA_PADDING_LEFT = parseFloat($TEXT_AREA.style.paddingLeft);
                const TEXTAREA_PADDING_TOP = parseFloat($TEXT_AREA.style.paddingTop);
                this.selectedElement.width = changedWidth + TEXTAREA_PADDING_LEFT * 2 + 10;
                this.selectedElement.height = changedHeight + TEXTAREA_PADDING_TOP * 2;
              }
              break;
            case 'label':
            case 'checkSheet':
              if (this.selectedElement.name.includes('Anchor Type 2')) {
                if (['left', 'right'].includes(this.getAnchorType(this.selectedElement.name))) {
                  changedWidth += 40;
                } else {
                  changedHeight += 40;
                }
              } else if (this.selectedElement.name.includes('Anchor Type 3')) {
                const paddingRight = Number(this.selectedElement.fontSize) + 8;
                const paddingLeft = Number(this.selectedElement.fontSize) * 1.2;
                if (['left', 'right'].includes(this.getAnchorType(this.selectedElement.name))) {
                  changedWidth += 40 + paddingRight + paddingLeft;
                } else {
                  changedWidth += paddingRight + paddingLeft;
                  changedHeight += 40;
                }
              } else if (this.selectedElement.name.includes('Anchor Type 1')) {
                if (['left', 'right'].includes(this.getAnchorType(this.selectedElement.name))) {
                  changedWidth += 20;
                } else {
                  changedHeight += 20;
                }
              } else if (
                this.selectedElement.name.includes('Button Type 1') ||
                this.selectedElement.name.includes('Button Type 2')
              ) {
                changedWidth += Number(this.selectedElement.fontSize) + 8 + 10;
              }

              this.selectedElement.width = changedWidth + 22;
              this.selectedElement.height = changedHeight + 15;
              this.setSizeCheckSheetDetail(this.selectedElement);
              break;
            default:
              break;
          }
        }
      }, 0);
    },
    setSizeCheckSheetDetail(element) {
      if (element.type === 'checkSheet') {
        return new Promise((resolve) => {
          this.setStandard();
          setTimeout(() => {
            const checkSheetDetailBoxElem = this.$refs[`check-sheet-detail-box-${element.id}`][0];
            if (checkSheetDetailBoxElem) {
              const checkSheetDetailWidth = checkSheetDetailBoxElem.clientWidth;
              element.checkSheetDetail = {
                width: checkSheetDetailWidth,
                relWidth: checkSheetDetailWidth / this.standardWidth,
              };
              this.sameChangeCheckSheetAssetInReference(element);
            }
            resolve();
          }, 0);
        });
      }
    },
    selectWay(element) {
      return this.taskCustomContentTypes.includes(element.type) ? 'dblclick' : 'mousedown';
    },
    deleteElement() {
      if (this.elements && this.selectedElement && this.elements.length > 0) {
        this.elements.forEach((element, index) => {
          if (
            this.selectedElement &&
            element.id === this.selectedElement.id &&
            !this.targetTypes.includes(element.type)
          ) {
            this.elements.splice(index, 1);
            this.onSelectElement(null);

            if (element.type === 'checkSheet') {
              delete this.custom.scenes[element.connectedSceneKey];
              this.SET_ACTIVATED_TYPE('asset');
            }
          }
        });
      }
    },
    newElement(data, event = null) {
      return {
        id: createUuid(),
        type: data.type,
        name: data.name || null,
        subData: data.subData || null,
        data: data.data || null,
        checkSheetItem: data.checkSheetItem || null,
        checkSheetDetail: data.checkSheetDetail || null,
        thumbnail: data.thumbnail,
        width: data.width || null,
        height: data.height || null,
        scaleX: 1,
        scaleY: 1,
        angle: 0,
        isHorizontalFlip: false,
        isVerticalFlip: false,
        x: event ? event.layerX : data.x,
        y: event ? event.layerY : data.y,
        fontSize: data.fontSize || null,
        subDataFontSize: data.subDataFontSize || null,
        fontWeight: data.fontWeight || null,
        textAlign: data.textAlign || null,
        backgroundColor: data.backgroundColor || null,
        textColor: data.textColor || null,
        opacity: data.opacity || 1,
        connectedSceneKey: data.connectedSceneKey || null,
        connectedUrl: data.connectedUrl || null,
        disableScale: true, // 내부 텍스트 크기는 고정
        scaleForCenter: false, // 크기 조절 시, 가운데 기준
        aspectRatio: true, // 크기 조절 시, 비율 고정
      };
    },
    initializeCanvas() {
      setTimeout(() => {
        this.setStoreCustom(this.oldSelectedScene);
        this.elements = [];
        this.onSelectElement(null);
        this.custom.scenes = this.scenes; // store에 있는 데이터 가져오기
        this.custom.commonItems = this.commonItems; // store에 있는 데이터 가져오기

        if (this.custom.commonItems.length === 0) {
          this.addTarget();
        } else {
          this.setElements();
        }
      }, 0);
    },
    syncCheckSheetItem() {
      const customScenes = Object.values(this.custom.scenes);
      for (let i = customScenes.length - 1; i >= 0; --i) {
        const scene = customScenes[i];
        for (let j = scene.arItems.length - 1; j >= 0; --j) {
          const arItem = scene.arItems[j];
          const index = j;

          if (arItem.type === 'checkSheet') {
            const checkSheetItem = this.getCheckSheetItem(arItem.checkSheetItem.id);

            if (checkSheetItem) {
              arItem.checkSheetItem = checkSheetItem;
              this.initSetSizeCheckSheetItem(arItem);
            } else {
              if (scene.type !== 'reference') {
                const deletedCheckSheetItem = scene.arItems.splice(index, 1);
                delete this.custom.scenes[deletedCheckSheetItem[0].connectedSceneKey];
                this.setElements();
              }
            }
          }
        }
      }
      this.isInitRendered = true;
    },
    getCheckSheetItem(checkSheetItemId) {
      return this.checkSheetItems.find((item) => {
        return item.id === checkSheetItemId;
      });
    },
    // 이미지 읽기
    readImage(imgSrc) {
      return this.getImageSize(imgSrc).then((data) => {
        this.readingImage = data;
      });
    },
    // 이미지 사이즈 계산
    getImageSize(imgSrc) {
      return new Promise((resolve) => {
        let image = new Image();
        image.onload = () => {
          resolve({
            width: image.naturalWidth,
            height: image.naturalHeight,
          });
        };
        image.src = imgSrc;
      });
    },
    newTarget(_type, _width, _height, _data) {
      return {
        type: _type,
        width: _width,
        height: _height,
        x: (this.offsetWidth - _width) / 2,
        y: (this.offsetHeight - _height) / 2,
        data: _data,
        thumbnail: null,
      };
    },
    removeTarget() {
      this.elements = this.elements.filter((element) => {
        return element.type !== 'targetQR' && element.type !== 'targetImage';
      });
    },
    addTarget() {
      let target;
      switch (this.content.manualTarget.type) {
        case 'qr':
          target = JSON.parse(JSON.stringify(this.newTarget('targetQR', 100, 100, this.content.manualTarget.qrCode)));
          this.centerX = target.x + target.width / 2;
          this.centerY = target.y + target.height / 2;
          this.elements.push(JSON.parse(JSON.stringify(this.newElement(target))));
          break;
        case 'img':
          target = JSON.parse(
            JSON.stringify(
              this.newTarget(
                'targetImage',
                this.offsetWidth * 0.95,
                this.offsetHeight * 0.95,
                this.content.manualTarget.rscURL,
              ),
            ),
          );

          this.readImage(this.content.manualTarget.rscURL).then(() => {
            target.height = this.readingImage.height * (target.width / this.readingImage.width);
            if (target.height > this.offsetHeight * 0.95) {
              target.height = this.offsetHeight * 0.95;
              target.width = this.readingImage.width * (target.height / this.readingImage.height);
            }
            target.x = (this.offsetWidth - target.width) / 2;
            target.y = (this.offsetHeight - target.height) / 2;
            this.centerX = target.x + target.width / 2;
            this.centerY = target.y + target.height / 2;

            this.elements.push(JSON.parse(JSON.stringify(this.newElement(target))));
          });
          break;
        default:
          break;
      }
    },
    setElements() {
      this.elements = JSON.parse(JSON.stringify(this.custom.commonItems));
      let arItems = this.custom.scenes[this.selectedScene.key].arItems;
      for (let i = 0; i < arItems.length; i++) {
        if (arItems[i].type === 'label' && arItems[i].name.includes('Text Type')) {
          arItems[i].name = arItems[i].name.replace('Text', 'Anchor');
        }
        this.elements.push(arItems[i]);
      }
    },
    async setStoreCustom(selectedScene, isCalcCheckSheetRelValue = false) {
      this.custom.scenes = this.scenes;

      if (this.elements.length > 0 && this.custom.scenes[selectedScene.key]) {
        this.custom.scenes[selectedScene.key].arItems = [];
        this.custom.commonItems = [];
        this.setStandard();

        for (let i = 0; i < this.elements.length; i++) {
          const newElement = this.calcRelativeData(this.elements[i]);

          if (this.taskCustomContentTypes.includes(this.elements[i].type)) {
            this.custom.commonItems.push(newElement);
          } else {
            this.custom.scenes[selectedScene.key].arItems.push(newElement);

            if (isCalcCheckSheetRelValue && this.elements[i].type === 'checkSheet') {
              for (let j = 0; j < this.custom.scenes[this.elements[i].connectedSceneKey].arItems.length; j++) {
                const newArItem = this.calcRelativeData(
                  this.custom.scenes[this.elements[i].connectedSceneKey].arItems[j],
                );
                this.custom.scenes[this.elements[i].connectedSceneKey].arItems[j].relWidth = newArItem.relWidth;
                this.custom.scenes[this.elements[i].connectedSceneKey].arItems[j].relHeight = newArItem.relHeight;
                this.custom.scenes[this.elements[i].connectedSceneKey].arItems[j].relPosition = newArItem.relPosition;
                await this.setSizeCheckSheetDetail(newElement);
              }
            }
          }
        }

        this.SET_SCENES(this.custom.scenes);
        this.SET_COMMON_ITEMS(this.custom.commonItems);
      }
    },
    calcRelativeData(element) {
      let newElement = JSON.parse(JSON.stringify(element));

      newElement.relWidth = (element.width * element.scaleX) / this.standardWidth;
      newElement.relHeight = (element.height * element.scaleY) / this.standardWidth;
      newElement.relPosition = {
        x:
          (element.x -
            this.centerX +
            (element.width * element.scaleX) / 2 +
            (element.width - element.width * element.scaleX)) /
            this.standardWidth -
          this.targetRelPositionX,
        y:
          (element.y -
            this.centerY +
            (element.height * element.scaleY) / 2 +
            (element.height - element.height * element.scaleY)) /
            this.standardWidth -
          this.targetRelPositionY,
        z: 0.0001,
      };

      return newElement;
    },
    setStandard() {
      this.elements.forEach((element) => {
        switch (element.type) {
          case 'targetQR':
          case 'targetImage':
            this.standardWidth = element.width * element.scaleX;
            this.centerX = element.x + (element.width * element.scaleX) / 2;
            this.centerY = element.y + (element.height * element.scaleY) / 2;
            this.targetRelPositionX =
              (element.x -
                this.centerX +
                (element.width * element.scaleX) / 2 +
                (element.width - element.width * element.scaleX)) /
              this.standardWidth;
            this.targetRelPositionY =
              (element.y -
                this.centerY +
                (element.height * element.scaleY) / 2 +
                (element.height - element.height * element.scaleY)) /
              this.standardWidth;
            return;
          default:
            break;
        }
      });
    },
    setToastContents(isPublishing) {
      if (!isPublishing) {
        this.toastPopupIcon = '/images/atoms-input-state-success-icon.svg';
        this.toastPopupTitle = this.$i18n.messages[this.$lang].message.head_scene_save_popup;
      } else if (isPublishing && !this.selectedTask.publishContent) {
        this.toastPopupIcon = '/images/publish-icon.svg';
        this.toastPopupTitle = this.$i18n.messages[this.$lang].message.head_scene_publish_popup;
      } else if (isPublishing && this.selectedTask.publishContent) {
        this.toastPopupIcon = '/images/update-icon.svg';
        this.toastPopupTitle = this.$i18n.messages[this.$lang].message.head_scene_update_popup;
      }
    },
    async putTaskCustom(isPublishing) {
      this.setToastContents(isPublishing);
      await this.setStoreCustom(this.selectedScene, true);
      await this.PUT_TASK_CUSTOM([this.selectedTask.id, JSON.stringify(this.createCurrentCustom), isPublishing]);
      $('.toast-popup-middle').fadeIn(500).delay(2000).fadeOut(500);
      this.isChangeElements = false;
    },
    onSelectElement(element) {
      this.SET_SELECTED_ELEMENT(element);

      if (element) {
        if (element.type === 'checkSheet') {
          this.SET_ACTIVATED_TYPE('check_sheet_asset');
        } else {
          this.SET_ACTIVATED_TYPE('asset');
        }
      }
    },
    onMouseup(element) {
      if (!this.taskCustomContentTypes.includes(element.type)) {
        this.onSelectElement(element);
      }
      if (element.type === 'checkSheet') {
        this.sameChangeCheckSheetAssetInReference(element);
      }
    },
    onClickCanvas(e) {
      if (e.target.id === 'workspace') {
        this.onSelectElement(null);
      }
      if (
        e.target.parentNode.classList.contains('qr-code') ||
        e.target.parentNode.classList.contains('element__targetImage')
      ) {
        this.onSelectElement(null);
      }
    },
    onDblclick(element, e) {
      if (element.type === 'text' || element.type === 'label') {
        $(`#asset${element.id}, #number-asset${element.id}`).attr('readonly', false);
        $(e.target).focus();
      }
    },
    onTextBlur(element, isNumberType) {
      $(`#asset${element.id}, #number-asset${element.id}`).attr('readonly', 'readonly');
      if (element.type === 'text') {
        const $TEXT_AREA = document.getElementById(`asset${element.id}`);
        $TEXT_AREA.style.boxSizing = 'border-box';
      }

      if (isNumberType) {
        if (element.name.includes('Number') && !element.data) {
          element.data = 1;
        } else if (!element.name.includes('Number') && !element.subData) {
          element.subData = 1;
        }
      }
    },
    // 드래그 앤 드롭으로 AR Item 생성 및 배치
    handleDrop(handleData, event) {
      if (handleData) {
        if (handleData.type === 'checkSheet') {
          if (this.isReferenceScene) {
            return;
          }
          this.addCheckSheetElement(handleData, event);
        } else {
          this.addElement(handleData, event);
        }
      }
    },
    addElement(arItem, event) {
      this.isChangeElements = true;
      this.isChangeReferenceElements = true;
      const element = JSON.parse(JSON.stringify(this.newElement(arItem, event)));
      this.elements.push(element);
      this.onSelectElement(element);
      this.setSizeCheckSheetDetail(element);
    },
    addCheckSheetElement(arItem, event) {
      this.initSetSizeCheckSheetItem(arItem);
      arItem.connectedSceneKey = this.selectedScene.key;
      this.addElement(arItem, event);

      setTimeout(() => {
        this.checkSheetAssetInReference = this.calcRelativeData(this.newElement(this.selectedElement, event));
      }, 0);
      setTimeout(() => {
        this.selectedElement.connectedSceneKey = this.addingReferenceSceneKey;
      }, 0);
    },
    onCopiedScene(arItem, sceneKey) {
      setTimeout(() => {
        this.checkSheetAssetInReference = this.calcRelativeData(this.newElement(arItem));
        this.checkSheetAssetInReference.connectedSceneKey = sceneKey;
      }, 0);
      setTimeout(() => {
        arItem.connectedSceneKey = this.addingReferenceSceneKey;
      }, 0);
    },
    initSetSizeCheckSheetItem(arItem) {
      this.setStandard();
      const $TEMPO_TEXT_AREA = document.getElementById('tempo-check-sheet-area');
      $TEMPO_TEXT_AREA.style.fontWeight = arItem.fontWeight;
      $TEMPO_TEXT_AREA.style.fontSize = arItem.fontSize + 'pt';
      $TEMPO_TEXT_AREA.innerHTML = arItem.checkSheetItem.name;
      const paddingRight = Number(arItem.fontSize) + 8;
      const paddingLeft = Number(arItem.fontSize) * 1.2;
      let changedWidth = parseFloat(window.getComputedStyle($TEMPO_TEXT_AREA).width);

      if (['left', 'right'].includes(this.getAnchorType(arItem.name))) {
        changedWidth += 40 + paddingRight + paddingLeft;
      } else {
        changedWidth += paddingRight + paddingLeft;
      }

      arItem.width = changedWidth + 22;
      const newArItem = this.calcRelativeData(arItem);
      arItem.relWidth = newArItem.relWidth;
      arItem.relHeight = newArItem.relHeight;
      arItem.relPosition = newArItem.relPosition;
    },
    addMediaElement(mediaData) {
      this.isLoading = true;
      let element = JSON.parse(JSON.stringify(this.newElement(mediaData)));

      if (mediaData.type === 'image' || mediaData.thumbnail) {
        let imageData;
        if (mediaData.thumbnail) {
          imageData = mediaData.thumbnail;
        } else {
          imageData = mediaData.data;
        }
        this.readImage(imageData).then(() => {
          element.width = 150;
          element.height = this.readingImage.height * (element.width / this.readingImage.width);

          if (element.height > 150) {
            element.height = 150;
            element.width = this.readingImage.width * (element.height / this.readingImage.height);
          }

          element.x = (this.offsetWidth - element.width) / 2;
          element.y = (this.offsetHeight - element.height) / 2;
          element.disableScale = false; // 내부 텍스트 크기는 고정

          this.elements.push(element);
          this.onSelectElement(element);
        });
      } else {
        element.width = 150;
        element.height = 80;
        element.x = (this.offsetWidth - element.width) / 2;
        element.y = (this.offsetHeight - element.height) / 2;
        element.disableScale = false; // 내부 텍스트 크기는 고정

        this.elements.push(element);
        this.onSelectElement(element);
      }
    },
    update(item, payload) {
      this.elements = this.elements.map((element) => {
        if (element.id === item.id) {
          if (element.type === 'text' || element.type === 'label') {
            if (!$(`#asset${element.id}`).is('[readonly="readonly"]')) {
              return element;
            }
          }

          this.selectedItem = element;
          this.isChangeElements = true;
          this.isChangeReferenceElements = true;
          return {
            ...element,
            ...payload,
          };
        }
        return element;
      });
    },
  },
  components: {
    ConfirmPopup,
    Zoom,
    SceneRightBar,
    SceneLeftBar,
    SceneHeader,
    FreeTransform,
  },
};
</script>

<style scoped lang="scss">
@import 'ArContentsDetail';
</style>
