<template>
  <div class="scene-left-bar">
    <div class="scene-check-sheet-wrapper" :class="{ 'disabled-asset-section': isReferenceScene }">
      <!-- 씬 목록 -->
      <div class="scene-section" ref="sceneSection">
        <scene-list
          :isGetTempoSavedCustom="isGetTempoSavedCustom"
          :checkSheetAssetInReference="checkSheetAssetInReference"
          @isChangeScene="$emit('isChangeScene')"
          @copiedScene="onCopiedScene"
          @addedReferenceScene="onAddedReferenceScene"
        />
      </div>
      <!-- 체크시트 목록 -->
      <div class="check-sheet-section" ref="checkSheetSection">
        <div class="section-title">
          <h4>{{ $t('message.list_check_sheet_title') }}</h4>
        </div>
        <div class="section-contents basic-scroll" :class="{ 'disabled-area': !isExistScene }">
          <ul v-if="checkSheetAssets.length > 0">
            <li
              v-for="(checkSheetAsset, index) in checkSheetAssets"
              :key="index"
              :disabled="usedCheckSheetItemIds.includes(checkSheetAsset.checkSheetItem.id)"
            >
              <drag
                class="drag"
                :title="checkSheetAsset.id"
                :transfer-data="checkSheetAsset"
                :draggable="!usedCheckSheetItemIds.includes(checkSheetAsset.checkSheetItem.id)"
              >
                <img
                  slot="image"
                  src="/images/asset-checksheet.png"
                  alt="CheckSheet Asset"
                  :style="{ height: '35px' }"
                />
                <span>{{ checkSheetAsset.checkSheetItem.name }}</span>
              </drag>
            </li>
          </ul>
          <p v-else class="section-description">
            등록된 체크시트가 없습니다.
            <br />
            ‘체크시트 관리’ 에서 등록 후, 사용하세요.
          </p>
        </div>
      </div>
      <p v-show="isReferenceScene" class="section-description">
        참고씬 저작중에는 씬 or 체크시트를 수정할 수 없습니다.
      </p>
    </div>
    <div class="asset-section" ref="assetSection">
      <div class="section-title">
        <h4>{{ $t('message.list_asset_title') }}</h4>
      </div>
      <div class="section-contents basic-scroll" :class="{ 'disabled-area': !isExistScene }">
        <!-- 텍스트 -->
        <div class="text-asset-section">
          <h5 @click="onSlideList('text-asset-section')">
            <span>
              <img src="/images/asset-text.png" alt="Text Asset Icon" />
              {{ $t('message.list_text_title') }}
            </span>
            <button class="arrow_down">
              <img src="/images/arrow_drop_down-24px.svg" alt="Arrow Down Icon" />
            </button>
            <button class="arrow_right disabled">
              <img src="/images/arrow_right-24px.svg" alt="Arrow Down Icon" />
            </button>
          </h5>
          <ul class="text-asset-list">
            <li v-for="(text, key) in texts" :key="key">
              <drag class="drag" :title="key" :transfer-data="text">
                <img
                  slot="image"
                  :style="{ minWidth: `${text.width}px`, height: `${text.height}px` }"
                  :src="text.listData"
                  :alt="text.name"
                />
                <img :src="text.listData" :alt="text.name" />
              </drag>
              <span>{{ key }}{{ text.width }}</span>
            </li>
          </ul>
        </div>
        <!-- 레이블(버튼) -->
        <div class="label-asset-section" :class="{ 'disabled-asset-section': isReferenceScene }">
          <h5 @click="onSlideList('label-asset-section')">
            <span>
              <img src="/images/asset-label.png" alt="Label Asset Icon" />
              {{ $t('message.list_label_title') }}
            </span>
            <button class="arrow_down">
              <img src="/images/arrow_drop_down-24px.svg" alt="Arrow Down Icon" />
            </button>
            <button class="arrow_right disabled">
              <img src="/images/arrow_right-24px.svg" alt="Arrow Down Icon" />
            </button>
          </h5>
          <ul class="label-asset-list">
            <li v-for="(label, key) in labels" :key="key">
              <drag class="drag" :transfer-data="label" :title="key">
                <img
                  slot="image"
                  :style="{ minWidth: `${label.width}px`, width: `${label.width}px`, height: `${label.height}px` }"
                  :src="label.listData"
                  :alt="label.name"
                />
                <img :src="label.listData" :alt="label.name" />
              </drag>
              <span>{{ key }}</span>
            </li>
          </ul>
          <p v-show="isReferenceScene" class="section-description">참고씬 저작중에는 버튼 에셋을 등록할 수 없습니다.</p>
        </div>
        <!-- 도형 -->
        <div class="figure-asset-section">
          <h5 @click="onSlideList('figure-asset-section')">
            <span>
              <img src="/images/asset-figure.png" alt="Figure Asset Icon" />
              {{ $t('message.list_shape_title') }}
            </span>
            <button class="arrow_down">
              <img src="/images/arrow_drop_down-24px.svg" alt="Arrow Down Icon" />
            </button>
            <button class="arrow_right disabled">
              <img src="/images/arrow_right-24px.svg" alt="Arrow Down Icon" />
            </button>
          </h5>
          <ul class="symbol-asset-list">
            <li v-for="(figure, key) in figures" :class="figure.key" :key="key">
              <drag class="drag" :transfer-data="figure" :title="key">
                <img
                  slot="image"
                  :src="figure.listData"
                  :alt="figure.name"
                  :style="{ minWidth: `${figure.width}px`, height: `${figure.height}px` }"
                />
                <img :src="figure.listData" :alt="figure.name" />
              </drag>
              <span>{{ key }}</span>
            </li>
          </ul>
        </div>
        <!-- 심볼 -->
        <div class="symbol-asset-section">
          <h5 @click="onSlideList('symbol-asset-section')">
            <span>
              <img src="/images/asset-symbol.png" alt="Symbol Asset Icon" />
              {{ $t('message.list_symbol_title') }}
            </span>
            <button class="arrow_down">
              <img src="/images/arrow_drop_down-24px.svg" alt="Arrow Down Icon" />
            </button>
            <button class="arrow_right disabled">
              <img src="/images/arrow_right-24px.svg" alt="Arrow Down Icon" />
            </button>
          </h5>
          <ul class="symbol-asset-list">
            <li v-for="(symbol, key) in symbols" :class="symbol.key" :key="key">
              <drag class="drag" :transfer-data="symbol" v-html="symbol.data" :title="key">
                <img
                  slot="image"
                  :src="symbol.listData"
                  :alt="symbol.name"
                  :style="{
                    minWidth: `${symbol.width}px`,
                    width: `${symbol.width}px`,
                    minHeight: `${symbol.height}px`,
                  }"
                />
              </drag>
              <span>{{ key }}</span>
            </li>
          </ul>
        </div>
        <!-- 미디어 -->
        <div class="media-asset-section">
          <h5 @click="onSlideList('media-asset-section')">
            <span>
              <img src="/images/asset-media.png" alt="Media Asset Icon" />
              {{ $t('message.list_media_title') }}
            </span>
            <button class="arrow_down">
              <img src="/images/arrow_drop_down-24px.svg" alt="Arrow Down Icon" />
            </button>
            <button class="arrow_right disabled">
              <img src="/images/arrow_right-24px.svg" alt="Arrow Down Icon" />
            </button>
          </h5>
          <ul>
            <li>
              <form method="post" enctype="multipart/form-data" action="" title="Upload Image" class="disabled_button">
                <input
                  type="file"
                  name="upFileAssetImage"
                  accept=".jpg, .png"
                  id="upFileAssetImage"
                  class="upload"
                  @change="fileSelect($event, 'image')"
                  style="display: none"
                />
                <label for="upFileAssetImage">
                  <vue-material-icon name="photo_size_select_actual" :size="24" />
                </label>
              </form>
              <span>Image</span>
            </li>
            <li>
              <form method="post" enctype="multipart/form-data" action="" title="Upload Video" class="disabled_button">
                <input
                  type="file"
                  name="upFileAssetVideo"
                  accept=".mp4"
                  id="upFileAssetVideo"
                  class="upload"
                  @change="fileSelect($event, 'video')"
                  style="display: none"
                />
                <label for="upFileAssetVideo">
                  <vue-material-icon name="movie" :size="24" />
                </label>
              </form>
              <span>Video</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import { mapActions } from 'vuex';
import SceneList from '../sceneList/SceneList';
import slideList from '@/asset/js/slideList';
import fileUpload from '@/asset/js/fileUpload';
import Symbols from '@/asset/data/Symbols.json';
import Labels from '@/asset/data/Labels.json';
import Figures from '@/asset/data/Figures.json';
import Pointing from '@/asset/data/Pointing.json';
import Texts from '@/asset/data/Texts.json';
import CheckSheet from '@/asset/data/CheckSheet.json';

export default {
  name: 'SceneLeftBar',
  props: [
    'isExistScene',
    'isGetTempoSavedCustom',
    'taskId',
    'checkSheetItems',
    'checkSheetAssetInReference',
    'isReferenceScene',
    'usedCheckSheetItemIds',
  ],
  data() {
    return {
      symbols: Symbols,
      figures: Figures,
      labels: Labels,
      points: Pointing,
      texts: Texts,
      checkSheetAssets: [],
      imageUrl: '',
      allFilename: '',
      fileBase64: '',
      mediaType: '',
    };
  },
  computed: {},
  created() {
    this.setCheckSheetAssets(this.checkSheetItems);
  },
  mounted() {},
  watch: {
    checkSheetItems(newVal) {
      if (newVal) {
        this.setCheckSheetAssets(newVal);
      }
    },
  },
  methods: {
    ...mapActions('manualTasks', ['POST_TASK_CONTENT']),
    onCopiedScene(arItem, sceneKey) {
      this.$emit('copiedScene', arItem, sceneKey);
    },
    onAddedReferenceScene(sceneUuid) {
      this.$emit('addedReferenceScene', sceneUuid);
    },
    newMediaData(data) {
      return {
        type: this.mediaType,
        name: this.allFilename,
        data: data.rscURL,
        thumbnail: data.rscThmURL,
      };
    },
    onSlideList(_type) {
      slideList(_type);
    },
    fileSelect(e, type) {
      this.mediaType = type;
      fileUpload(this, e.target, false, true);
    },
    uploadFile() {
      this.$emit('isLoadingFile', true);
      this.postContent();
    },
    async postContent() {
      try {
        const resData = await this.POST_TASK_CONTENT([
          this.taskId,
          {
            rscName: this.allFilename,
            rscFileData: this.fileBase64,
          },
        ]);
        let mediaData = JSON.parse(JSON.stringify(this.newMediaData(resData)));
        this.$emit('uploadMedia', mediaData);
      } finally {
        this.initializeFile();
        this.$emit('isLoadingFile', false);
      }
    },
    setCheckSheetAssets(checkSheetItems) {
      this.checkSheetAssets = checkSheetItems.map((checkSheetItem) => {
        const CheckSheetAsset = JSON.parse(JSON.stringify(CheckSheet));
        CheckSheetAsset.checkSheetItem = checkSheetItem;
        return CheckSheetAsset;
      });
    },
    initializeFile() {
      $('.upload').val('');
    },
  },
  components: {
    SceneList,
  },
};
</script>

<style scoped lang="scss">
@import 'SceneLeftBar';
</style>
