var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scene-header" },
    [
      _c("div", { staticClass: "scene-header__left" }, [
        _c(
          "button",
          { staticClass: "back-button", on: { click: _vm.onBackPage } },
          [
            _c(
              "span",
              { staticClass: "icon", attrs: { "data-icon": "arrow_back" } },
              [
                _c("vue-material-icon", {
                  attrs: { name: "arrow_back", size: 20 },
                }),
              ],
              1
            ),
          ]
        ),
        _c("h1", [_vm._v("AR 컨텐츠 관리")]),
        _c(
          "span",
          { staticClass: "task-name" },
          [
            _c("span", [_vm._v(_vm._s(_vm.selectedEqpNameCode))]),
            _c("vue-material-icon", {
              attrs: { name: "chevron_right", size: 20 },
            }),
            _c("span", [_vm._v(_vm._s(_vm.content.name))]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "scene-header__right" }, [
        _c(
          "button",
          {
            staticClass: "scene-header-button",
            attrs: { disabled: _vm.isReferenceScene },
            on: {
              click: function ($event) {
                return _vm.togglePopup("TaskPopup")
              },
            },
          },
          [
            _c(
              "span",
              { staticClass: "scene-header-button__icon" },
              [_c("vue-material-icon", { attrs: { name: "info", size: 10 } })],
              1
            ),
            _vm._v(" 상세 "),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "scene-header-button",
            class: { "disabled-area": !_vm.isExistScene },
            attrs: { disabled: _vm.isReferenceScene },
            on: { click: _vm.onSave },
          },
          [
            _c(
              "span",
              { staticClass: "scene-header-button__icon" },
              [_c("vue-material-icon", { attrs: { name: "save", size: 10 } })],
              1
            ),
            _vm._v(" 저장 "),
          ]
        ),
        _vm.isPossiblePublish
          ? _c(
              "button",
              {
                staticClass: "scene-header-button publish",
                class: { "disabled-area": !_vm.isExistScene },
                attrs: { disabled: _vm.isReferenceScene },
                on: { click: _vm.onPublish },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "scene-header-button__icon",
                    attrs: { "data-icon": "publish" },
                  },
                  [
                    _c("vue-material-icon", {
                      attrs: { name: "publish", size: 10 },
                    }),
                  ],
                  1
                ),
                _vm._v(" 게시 "),
              ]
            )
          : _vm._e(),
        _vm.isPossibleUpdate
          ? _c(
              "button",
              {
                staticClass: "scene-header-button update",
                class: { "disabled-area": !_vm.isExistScene },
                attrs: {
                  disabled: _vm.isReferenceScene || _vm.isImpossibleUpdate,
                },
                on: { click: _vm.onPublish },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "scene-header-button__icon",
                    attrs: { "data-icon": "autorenew" },
                  },
                  [
                    _c("vue-material-icon", {
                      attrs: { name: "autorenew", size: 10 },
                    }),
                  ],
                  1
                ),
                _vm._v(" 업데이트 "),
              ]
            )
          : _vm._e(),
      ]),
      _c(
        "transition",
        { attrs: { name: "modal" } },
        [
          _vm.showedPopupName === "TaskPopup"
            ? _c("task-popup", {
                attrs: {
                  "selected-eqp-id": _vm.selectedTask.manualProjectId,
                  "selected-task": _vm.selectedTask,
                },
                on: { close: _vm.togglePopup },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("confirm-popup", {
        attrs: {
          isShow: _vm.showedPopupName === "ConfirmPopup",
          contentsKey: _vm.popupContentsKey,
        },
        on: {
          close: _vm.onCancelConfirmInPopup,
          confirm: _vm.onConfirmInPopup,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }