var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selectedScene
    ? _c("div", { staticClass: "scene-right-bar" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.propertySubtitle,
                expression: "propertySubtitle",
              },
            ],
            staticClass: "section-title",
          },
          [_c("h4", [_vm._v(_vm._s(_vm.propertySubtitle))])]
        ),
        _c(
          "div",
          {
            staticClass: "section-contents basic-scroll",
            class: { "exist-reference-scene": _vm.isShowReferenceSceneSection },
          },
          [
            _vm.activatedType.includes("asset") && !_vm.selectedElement
              ? _c("div", { staticClass: "section-contents-read-only" }, [
                  _c("div", [
                    _c("h5", [
                      _vm._v(_vm._s(_vm.$t("message.propety_layout_title"))),
                    ]),
                    _c("ul", [
                      _c("li", [
                        _c("span", { staticClass: "list-name" }, [
                          _vm._v(
                            _vm._s(_vm.$t("message.propety_location_lbl"))
                          ),
                        ]),
                        _vm._m(0),
                        _vm._m(1),
                      ]),
                      _c("li", [
                        _c("span", { staticClass: "list-name" }, [
                          _vm._v(_vm._s(_vm.$t("message.propety_size_lbl"))),
                        ]),
                        _vm._m(2),
                        _vm._m(3),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.activatedType.includes("asset") &&
            _vm.selectedItem &&
            _vm.selectedElement
              ? _c(
                  "div",
                  {
                    on: { wheel: _vm.onMousewheel, mousedown: _vm.onMousedown },
                  },
                  [
                    _c("div", [
                      _c("h5", [
                        _vm._v(_vm._s(_vm.$t("message.propety_layout_title"))),
                      ]),
                      _vm.selectedItem.x
                        ? _c("ul", [
                            _c("li", [
                              _c("span", { staticClass: "list-name" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("message.propety_location_lbl"))
                                ),
                              ]),
                              _c("label", { attrs: { for: "property-x" } }, [
                                _c("input", {
                                  staticClass: "basic-property-element",
                                  attrs: {
                                    type: "number",
                                    id: "property-x",
                                    name: "property-x",
                                    readonly: "",
                                  },
                                  domProps: {
                                    value: _vm.selectedItem.x.toFixed(1),
                                  },
                                  on: { keydown: _vm.onKeypress },
                                }),
                                _c("span", { staticClass: "unit" }, [
                                  _vm._v("X"),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "hover-button-wrapper" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { "data-icon": "arrow-up-gray" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addNumbers("property-x")
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "hover-button-image",
                                          attrs: {
                                            src: "/images/arrow-up-gray.png",
                                            alt: "Arrow Up Icon",
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-icon": "arrow-down-gray",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.subNumbers("property-x")
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "hover-button-image",
                                          attrs: {
                                            src: "/images/arrow-down-gray.png",
                                            alt: "Arrow Down Icon",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _c("label", { attrs: { for: "property-y" } }, [
                                _c("input", {
                                  staticClass: "basic-property-element",
                                  attrs: {
                                    type: "number",
                                    id: "property-y",
                                    name: "property-y",
                                    readonly: "",
                                  },
                                  domProps: {
                                    value: _vm.selectedItem.y.toFixed(1),
                                  },
                                  on: { keydown: _vm.onKeypress },
                                }),
                                _c("span", { staticClass: "unit" }, [
                                  _vm._v("Y"),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "hover-button-wrapper" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { "data-icon": "arrow-up-gray" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addNumbers("property-y")
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "hover-button-image",
                                          attrs: {
                                            src: "/images/arrow-up-gray.png",
                                            alt: "Arrow Up Icon",
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-icon": "arrow-down-gray",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.subNumbers("property-y")
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "hover-button-image",
                                          attrs: {
                                            src: "/images/arrow-down-gray.png",
                                            alt: "Arrow Down Iㄹcon",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c(
                              "li",
                              {
                                attrs: {
                                  disabled: [
                                    "text",
                                    "label",
                                    "checkSheet",
                                  ].includes(_vm.selectedElement.type),
                                },
                              },
                              [
                                _c("span", { staticClass: "list-name" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("message.propety_size_lbl"))
                                  ),
                                ]),
                                _c(
                                  "label",
                                  { attrs: { for: "property-width" } },
                                  [
                                    _c("input", {
                                      staticClass: "basic-property-element",
                                      attrs: {
                                        type: "number",
                                        id: "property-width",
                                        name: "property-width",
                                        readonly: "",
                                      },
                                      domProps: {
                                        value: (
                                          _vm.selectedItem.width *
                                          _vm.selectedItem.scaleX
                                        ).toFixed(1),
                                      },
                                      on: { keydown: _vm.onKeypress },
                                    }),
                                    _c("span", { staticClass: "unit" }, [
                                      _vm._v("W"),
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "hover-button-wrapper" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-icon": "arrow-up-gray",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addNumbers(
                                                  "property-width"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "hover-button-image",
                                              attrs: {
                                                src: "/images/arrow-up-gray.png",
                                                alt: "Arrow Up Icon",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-icon": "arrow-down-gray",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.subNumbers(
                                                  "property-width"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "hover-button-image",
                                              attrs: {
                                                src: "/images/arrow-down-gray.png",
                                                alt: "Arrow Down Icon",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "label",
                                  { attrs: { for: "property-height" } },
                                  [
                                    _c("img", {
                                      class: {
                                        "visibility-hidden":
                                          !_vm.selectedItem.aspectRatio,
                                      },
                                      staticStyle: {
                                        margin: "6px 1px 0px 1px",
                                      },
                                      attrs: {
                                        src: "/images/lock-gray.svg",
                                        alt: "Lock Icon",
                                      },
                                    }),
                                    _c("input", {
                                      staticClass: "basic-property-element",
                                      attrs: {
                                        type: "number",
                                        id: "property-height",
                                        name: "property-height",
                                        readonly: "",
                                      },
                                      domProps: {
                                        value: (
                                          _vm.selectedItem.height *
                                          _vm.selectedItem.scaleY
                                        ).toFixed(1),
                                      },
                                      on: { keydown: _vm.onKeypress },
                                    }),
                                    _c("span", { staticClass: "unit" }, [
                                      _vm._v("H"),
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "hover-button-wrapper" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-icon": "arrow-up-gray",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addNumbers(
                                                  "property-height"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "hover-button-image",
                                              attrs: {
                                                src: "/images/arrow-up-gray.png",
                                                alt: "Arrow Up Icon",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-icon": "arrow-down-gray",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.subNumbers(
                                                  "property-height"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "hover-button-image",
                                              attrs: {
                                                src: "/images/arrow-down-gray.png",
                                                alt: "Arrow Down Icon",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                attrs: {
                                  disabled:
                                    _vm.selectedElement.type === "targetQR" ||
                                    _vm.selectedElement.type ===
                                      "targetImage" ||
                                    _vm.selectedElement.type === "guideImage",
                                },
                              },
                              [
                                _c("span", { staticClass: "list-name" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("message.property_rotation_lbl")
                                    )
                                  ),
                                ]),
                                _c(
                                  "label",
                                  { attrs: { for: "property-angle" } },
                                  [
                                    _c("input", {
                                      staticClass: "basic-property-element",
                                      attrs: {
                                        type: "number",
                                        id: "property-angle",
                                        name: "property-angle",
                                        readonly: "",
                                      },
                                      domProps: {
                                        value:
                                          _vm.selectedItem.angle.toFixed(0),
                                      },
                                      on: { keydown: _vm.onKeypress },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "unit",
                                        staticStyle: { "font-size": "20px" },
                                      },
                                      [_vm._v("˚")]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "hover-button-wrapper" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-icon": "arrow-up-gray",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addNumbers(
                                                  "property-angle"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "hover-button-image",
                                              attrs: {
                                                src: "/images/arrow-up-gray.png",
                                                alt: "Arrow Up Icon",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-icon": "arrow-down-gray",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.subNumbers(
                                                  "property-angle"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "hover-button-image",
                                              attrs: {
                                                src: "/images/arrow-down-gray.png",
                                                alt: "Arrow Down Icon",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "basic-property-element asset-flip-button",
                                      class: {
                                        selected:
                                          _vm.selectedElement.isVerticalFlip,
                                      },
                                      attrs: {
                                        "data-icon": "vertical-flip",
                                        disabled: [
                                          "text",
                                          "label",
                                          "checkSheet",
                                          "image",
                                          "video",
                                        ].includes(_vm.selectedElement.type),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.flipItem("isVerticalFlip")
                                        },
                                      },
                                    },
                                    [
                                      _c("vue-material-icon", {
                                        attrs: { name: "flip", size: 16 },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "basic-property-element asset-flip-button",
                                      class: {
                                        selected:
                                          _vm.selectedElement.isHorizontalFlip,
                                      },
                                      attrs: {
                                        "data-icon": "horizontal-flip",
                                        disabled: [
                                          "text",
                                          "label",
                                          "checkSheet",
                                          "image",
                                          "video",
                                        ].includes(_vm.selectedElement.type),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.flipItem(
                                            "isHorizontalFlip"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("vue-material-icon", {
                                        attrs: { name: "flip", size: 16 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                              ],
                              2
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      {
                        attrs: {
                          disabled: !["text", "label", "checkSheet"].includes(
                            _vm.selectedElement.type
                          ),
                        },
                      },
                      [
                        _c("h5", [
                          _vm._v(_vm._s(_vm.$t("message.propety_text_title"))),
                        ]),
                        _c("ul", [
                          _c("li", [
                            _c("span", { staticClass: "list-name" }, [
                              _vm._v(
                                _vm._s(_vm.$t("message.propety_text_style_lbl"))
                              ),
                            ]),
                            _c(
                              "label",
                              { attrs: { for: "font-weight-property" } },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedElement.fontWeight,
                                        expression:
                                          "selectedElement.fontWeight",
                                      },
                                    ],
                                    staticClass: "basic-select",
                                    staticStyle: { width: "76px" },
                                    attrs: { id: "font-weight-property" },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.selectedElement,
                                            "fontWeight",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        _vm.onChangeFontWeight,
                                      ],
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      { attrs: { value: "normal" } },
                                      [_vm._v("Regular")]
                                    ),
                                    _c("option", { attrs: { value: "bold" } }, [
                                      _vm._v("Bold"),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "label",
                              { attrs: { for: "font-size-property" } },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedElement.fontSize,
                                        expression: "selectedElement.fontSize",
                                      },
                                    ],
                                    staticClass: "basic-select",
                                    staticStyle: { width: "44px" },
                                    attrs: { id: "font-size-property" },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.selectedElement,
                                            "fontSize",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        _vm.onChangeTextSize,
                                      ],
                                    },
                                  },
                                  _vm._l(
                                    _vm.fontSizeRange,
                                    function (fontSize, index) {
                                      return _c(
                                        "option",
                                        {
                                          key: index,
                                          domProps: { value: fontSize },
                                        },
                                        [_vm._v(" " + _vm._s(fontSize) + " ")]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "li",
                            [
                              _c("span", { staticClass: "list-name" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("message.propety_text_color_lbl")
                                  )
                                ),
                              ]),
                              _vm._l(_vm.colorSet, function (color, index) {
                                return _c("span", {
                                  key: index,
                                  staticClass: "color-set",
                                  class: {
                                    "color-set-white": color === "#ffffff",
                                    selected:
                                      color === _vm.selectedItem.textColor,
                                  },
                                  style: { backgroundColor: color },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onSetTextColor(color)
                                    },
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "align",
                              attrs: {
                                disabled:
                                  ["label", "checkSheet"].includes(
                                    _vm.selectedElement.type
                                  ) ||
                                  (_vm.selectedElement.name &&
                                    _vm.selectedElement.name.includes(
                                      "Number"
                                    )),
                              },
                            },
                            [
                              _c("span", { staticClass: "list-name" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("message.propety_text_align_lbl")
                                  )
                                ),
                              ]),
                              _c(
                                "span",
                                {
                                  class: {
                                    selected:
                                      _vm.selectedItem.textAlign === "left",
                                  },
                                  attrs: { "data-value": "left" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onSetTextAlign("left")
                                    },
                                  },
                                },
                                [
                                  _c("vue-material-icon", {
                                    attrs: {
                                      name: "format_align_left",
                                      size: 16,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    selected:
                                      _vm.selectedItem.textAlign === "center",
                                  },
                                  attrs: { "data-value": "center" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onSetTextAlign("center")
                                    },
                                  },
                                },
                                [
                                  _c("vue-material-icon", {
                                    attrs: {
                                      name: "format_align_center",
                                      size: 16,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    selected:
                                      _vm.selectedItem.textAlign === "right",
                                  },
                                  attrs: { "data-value": "right" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onSetTextAlign("right")
                                    },
                                  },
                                },
                                [
                                  _c("vue-material-icon", {
                                    attrs: {
                                      name: "format_align_right",
                                      size: 16,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        attrs: {
                          disabled: [
                            "targetQR",
                            "targetImage",
                            "guideImage",
                          ].includes(_vm.selectedElement.type),
                        },
                      },
                      [
                        _c("h5", [
                          _vm._v(_vm._s(_vm.$t("message.propety_style_title"))),
                        ]),
                        _vm.selectedItem.opacity >= 0
                          ? _c("ul", [
                              _c(
                                "li",
                                {
                                  attrs: {
                                    disabled: [
                                      "symbol",
                                      "image",
                                      "video",
                                    ].includes(_vm.selectedElement.type),
                                  },
                                },
                                [
                                  _c("span", { staticClass: "list-name" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "message.propety_style_color_lbl"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._l(_vm.colorSet, function (color, index) {
                                    return _c("span", {
                                      key: index,
                                      staticClass: "color-set",
                                      class: {
                                        "color-set-white": color === "#ffffff",
                                        selected:
                                          color ===
                                          _vm.selectedItem.backgroundColor,
                                      },
                                      style: { backgroundColor: color },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onSetBackgroundColor(color)
                                        },
                                      },
                                    })
                                  }),
                                ],
                                2
                              ),
                              _c("li", [
                                _c("span", { staticClass: "list-name" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "message.propety_style_opacity_lbl"
                                      )
                                    )
                                  ),
                                ]),
                                _c(
                                  "label",
                                  { attrs: { for: "property-opacity" } },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.opacityRangeValue,
                                          expression: "opacityRangeValue",
                                        },
                                      ],
                                      staticClass: "basic-property-element",
                                      attrs: {
                                        type: "number",
                                        id: "property-opacity",
                                        name: "property-opacity",
                                        readonly: "",
                                      },
                                      domProps: {
                                        value: _vm.opacityRangeValue,
                                      },
                                      on: {
                                        keydown: _vm.onKeypress,
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.opacityRangeValue =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                    _c("span", { staticClass: "unit" }, [
                                      _vm._v("%"),
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "hover-button-wrapper" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-icon": "arrow-up-gray",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addNumbers(
                                                  "property-opacity"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "hover-button-image",
                                              attrs: {
                                                src: "/images/arrow-up-gray.png",
                                                alt: "Arrow Up Icon",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-icon": "arrow-down-gray",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.subNumbers(
                                                  "property-opacity"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "hover-button-image",
                                              attrs: {
                                                src: "/images/arrow-down-gray.png",
                                                alt: "Arrow Down Icon",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("label", { attrs: { for: "range" } }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.opacityRangeValue,
                                        expression: "opacityRangeValue",
                                      },
                                    ],
                                    staticClass: "range",
                                    style:
                                      "background: linear-gradient(to right, #2fa2eb " +
                                      _vm.opacityRangeValue +
                                      "%, #CED4DA " +
                                      _vm.opacityRangeValue +
                                      "%)",
                                    attrs: {
                                      type: "range",
                                      id: "range",
                                      min: "0",
                                      max: "100",
                                      step: "1",
                                    },
                                    domProps: { value: _vm.opacityRangeValue },
                                    on: {
                                      __r: function ($event) {
                                        _vm.opacityRangeValue =
                                          $event.target.value
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c(
                                "li",
                                {
                                  staticClass: "anchor",
                                  attrs: {
                                    disabled:
                                      _vm.selectedElement.name &&
                                      !_vm.selectedElement.name.includes(
                                        "Anchor"
                                      ),
                                  },
                                },
                                [
                                  _c("span", { staticClass: "list-name" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "message.propety_style_anchor_title"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._l(
                                    _vm.anchorTypes,
                                    function (imgSrc, anchor) {
                                      return _c("span", {
                                        key: anchor,
                                        class: {
                                          selected:
                                            _vm.getAnchorType(
                                              _vm.selectedItem.name
                                            ) === anchor,
                                        },
                                        domProps: { innerHTML: _vm._s(imgSrc) },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onSetAnchor(anchor)
                                          },
                                        },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm.isActiveActionProperties
                      ? _c("div", { staticClass: "action-properties" }, [
                          _c(
                            "h5",
                            { attrs: { disabled: _vm.isDisabledAction } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("message.propety_style_action_title")
                                )
                              ),
                            ]
                          ),
                          _c("ul", [
                            _c("li", [
                              _c(
                                "span",
                                {
                                  staticClass: "list-name",
                                  attrs: { disabled: _vm.isDisabledAction },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "message.propety_style_actiontype_title"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "label",
                                { attrs: { for: "action-property" } },
                                [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.actionType,
                                          expression: "actionType",
                                        },
                                      ],
                                      staticClass: "basic-select",
                                      attrs: {
                                        id: "action-property",
                                        disabled: _vm.isDisabledAction,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.actionType = $event.target
                                            .multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          attrs: { selected: "" },
                                          domProps: { value: null },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "message.propety_style_na_lbl"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "scene" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "message.propety_style_move_lbl"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "url" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "message.propety_style_URL_lbl"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "li",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.actionType,
                                    expression: "actionType",
                                  },
                                ],
                              },
                              [
                                _c("span", { staticClass: "list-name" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.actionType === "scene"
                                        ? _vm.$t(
                                            "message.propety_style_move_lbl"
                                          )
                                        : "URL"
                                    )
                                  ),
                                ]),
                                _c(
                                  "label",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.actionType === "scene",
                                        expression: "actionType === 'scene'",
                                      },
                                    ],
                                    attrs: { for: "action-scene" },
                                  },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.selectedElement
                                                .connectedSceneKey,
                                            expression:
                                              "selectedElement.connectedSceneKey",
                                          },
                                        ],
                                        staticClass: "basic-select",
                                        attrs: {
                                          id: "action-scene",
                                          disabled: _vm.isDisabledAction,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.selectedElement,
                                              "connectedSceneKey",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          {
                                            attrs: { selected: "" },
                                            domProps: { value: null },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "message.propety_style_na_lbl"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._l(
                                          _vm.actionSceneArray,
                                          function (scene, index) {
                                            return [
                                              scene
                                                ? _c(
                                                    "option",
                                                    {
                                                      key: index,
                                                      domProps: {
                                                        value: scene.key,
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(scene.name))]
                                                  )
                                                : _vm._e(),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                                _c(
                                  "label",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.actionType === "url",
                                        expression: "actionType === 'url'",
                                      },
                                    ],
                                    attrs: { for: "action-url" },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.connectedUrl,
                                          expression: "connectedUrl",
                                        },
                                      ],
                                      staticClass: "basic-property-element",
                                      attrs: { type: "text", id: "action-url" },
                                      domProps: { value: _vm.connectedUrl },
                                      on: {
                                        focus: function ($event) {
                                          return _vm.$emit(
                                            "focusedPropertyInput",
                                            true
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$emit(
                                            "focusedPropertyInput",
                                            false
                                          )
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.connectedUrl = $event.target.value
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            !_vm.activatedType.includes("asset")
              ? _c("div", [
                  _c("div", [
                    _c("h5", [
                      _vm._v(_vm._s(_vm.$t("message.propety_info_title"))),
                    ]),
                    _c("ul", { staticClass: "scene-info-list" }, [
                      _c("li", [
                        _c("span", { staticClass: "list-name" }, [
                          _vm._v(
                            _vm._s(_vm.$t("message.propety_info_name_lbl"))
                          ),
                        ]),
                        _vm.activatedType === "inner"
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.scenes[_vm.selectedScene.key].name
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _vm.activatedType === "group"
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.scenes[_vm.selectedSceneGroup.key].name
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _vm.activatedType === "single"
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.scenes[_vm.selectedScene.key].name
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _vm.activatedType === "group" || _vm.activatedType === "inner"
                    ? _c("div", [
                        _c("h5", [
                          _vm._v(_vm._s(_vm.$t("message.propety_guide_title"))),
                        ]),
                        _c("ul", { staticClass: "scene-guide-list" }, [
                          _vm.activatedType === "group"
                            ? _c("li", [
                                _c("span", { staticClass: "list-name" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("message.propety_guide_name_lbl")
                                    )
                                  ),
                                ]),
                                _c("label", [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.scenes[_vm.selectedSceneGroup.key]
                                            .title,
                                        expression:
                                          "scenes[selectedSceneGroup.key].title",
                                      },
                                    ],
                                    staticClass:
                                      "basic-property-element basic-scroll",
                                    attrs: {
                                      type: "text",
                                      name: "Guide Title",
                                      maxlength: "30",
                                      placeholder: _vm.$t(
                                        "message.propety_guide_name_phd"
                                      ),
                                    },
                                    domProps: {
                                      value:
                                        _vm.scenes[_vm.selectedSceneGroup.key]
                                          .title,
                                    },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.$emit(
                                          "focusedPropertyInput",
                                          true
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$emit(
                                          "focusedPropertyInput",
                                          false
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.scenes[
                                            _vm.selectedSceneGroup.key
                                          ],
                                          "title",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.activatedType === "inner"
                            ? _c("li", [
                                _c("span", { staticClass: "list-name" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("message.propety_guide_step_lbl")
                                    )
                                  ),
                                ]),
                                _c("label", [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.scenes[_vm.selectedScene.key]
                                            .subtitle,
                                        expression:
                                          "scenes[selectedScene.key].subtitle",
                                      },
                                    ],
                                    staticClass:
                                      "basic-property-element basic-scroll",
                                    attrs: {
                                      type: "text",
                                      name: "Guide Subtitle",
                                      maxlength: "70",
                                      placeholder: _vm.$t(
                                        "message.propety_guide_step_phd"
                                      ),
                                    },
                                    domProps: {
                                      value:
                                        _vm.scenes[_vm.selectedScene.key]
                                          .subtitle,
                                    },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.$emit(
                                          "focusedPropertyInput",
                                          true
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$emit(
                                          "focusedPropertyInput",
                                          false
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.scenes[_vm.selectedScene.key],
                                          "subtitle",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.activatedType === "inner"
                            ? _c("li", [
                                _c("span", { staticClass: "list-name" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "message.propety_guide_content_lbl"
                                      )
                                    )
                                  ),
                                ]),
                                _c("label", [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.scenes[_vm.selectedScene.key]
                                            .description,
                                        expression:
                                          "scenes[selectedScene.key].description",
                                      },
                                    ],
                                    staticClass:
                                      "basic-property-element basic-scroll",
                                    attrs: {
                                      name: "Guide Description",
                                      maxlength: "140",
                                      placeholder: _vm.$t(
                                        "message.propety_guide_content_phd"
                                      ),
                                    },
                                    domProps: {
                                      value:
                                        _vm.scenes[_vm.selectedScene.key]
                                          .description,
                                    },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.$emit(
                                          "focusedPropertyInput",
                                          true
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$emit(
                                          "focusedPropertyInput",
                                          false
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.scenes[_vm.selectedScene.key],
                                          "description",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]
        ),
        _vm.isShowReferenceSceneSection
          ? _c("div", { staticClass: "add-check-sheet-scene" }, [
              _c("div", { staticClass: "section-title" }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("message.list_check_sheet_scene_title"))
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "create-scene" },
                [
                  !_vm.isReferenceScene && _vm.referenceSceneUpdateDT()
                    ? [
                        _c("p", { staticClass: "date" }, [
                          _vm._v(
                            "마지막 수정 : " +
                              _vm._s(_vm.referenceSceneUpdateDT())
                          ),
                        ]),
                      ]
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "buttons" },
                    [
                      _vm.isReferenceScene
                        ? [
                            _c(
                              "button-basic",
                              {
                                attrs: {
                                  color: "gray-border-1",
                                  size: "ss",
                                  width: 80,
                                  height: 24,
                                },
                                on: { click: _vm.onCancelReferenceScene },
                              },
                              [_vm._v(" 취소 ")]
                            ),
                            _c(
                              "button-basic",
                              {
                                attrs: {
                                  color: "blue",
                                  size: "ss",
                                  width: 80,
                                  height: 24,
                                  disabled: !_vm.isChangeReferenceElements,
                                },
                                on: { click: _vm.onSaveReferenceScene },
                              },
                              [_vm._v(" 저장 ")]
                            ),
                          ]
                        : _vm.referenceSceneUpdateDT()
                        ? [
                            _c(
                              "button-basic",
                              {
                                attrs: {
                                  color: "red",
                                  size: "ss",
                                  width: 80,
                                  height: 24,
                                },
                                on: { click: _vm.onDeleteReferenceScene },
                              },
                              [_vm._v(" 삭제 ")]
                            ),
                            _c(
                              "button-basic",
                              {
                                attrs: {
                                  color: "dark",
                                  size: "ss",
                                  width: 80,
                                  height: 24,
                                },
                                on: { click: _vm.openReferenceScene },
                              },
                              [_vm._v(" 수정 ")]
                            ),
                          ]
                        : _c(
                            "button-basic",
                            {
                              attrs: {
                                color: "dark",
                                size: "ss",
                                width: 120,
                                height: 24,
                              },
                              on: { click: _vm.openReferenceScene },
                            },
                            [_vm._v(" 참고 씬 만들기 ")]
                          ),
                    ],
                    2
                  ),
                  _c(
                    "p",
                    { staticClass: "description" },
                    [
                      _c("vue-material-icon", {
                        attrs: { name: "info", size: 12 },
                      }),
                      _vm.isReferenceScene
                        ? _c("span", [
                            _c("b", [_vm._v("참고 씬")]),
                            _vm._v(" 저작이 완료되면 저장 버튼을 클릭하세요. "),
                          ])
                        : _c("span", [
                            _vm._v(
                              " 설비 점검 앱에서 증강된 체크시트를 탭했을 때 보이는 증강 컨텐츠(Scene)를 저작할 수 있습니다. "
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                2
              ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "property-x-disabled" } }, [
      _c("input", {
        staticClass: "basic-property-element",
        attrs: {
          type: "number",
          id: "property-x-disabled",
          name: "property-x",
          readonly: "",
        },
      }),
      _c("span", { staticClass: "unit" }, [_vm._v("X")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "property-y-disabled" } }, [
      _c("input", {
        staticClass: "basic-property-element",
        attrs: {
          type: "number",
          id: "property-y-disabled",
          name: "property-y",
          readonly: "",
        },
      }),
      _c("span", { staticClass: "unit" }, [_vm._v("Y")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "property-width-disabled" } }, [
      _c("input", {
        staticClass: "basic-property-element",
        attrs: {
          type: "number",
          id: "property-width-disabled",
          name: "property-width",
          readonly: "",
        },
      }),
      _c("span", { staticClass: "unit" }, [_vm._v("W")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "property-height-disabled" } }, [
      _c("img", {
        staticStyle: { margin: "6px 1px 0px 1px", opacity: "0.2" },
        attrs: { src: "/images/lock-gray.svg", alt: "Lock Icon" },
      }),
      _c("input", {
        staticClass: "basic-property-element",
        attrs: {
          type: "number",
          id: "property-height-disabled",
          name: "property-height",
          readonly: "",
        },
      }),
      _c("span", { staticClass: "unit" }, [_vm._v("H")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }