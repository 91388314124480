var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "scene-left-bar" }, [
    _c(
      "div",
      {
        staticClass: "scene-check-sheet-wrapper",
        class: { "disabled-asset-section": _vm.isReferenceScene },
      },
      [
        _c(
          "div",
          { ref: "sceneSection", staticClass: "scene-section" },
          [
            _c("scene-list", {
              attrs: {
                isGetTempoSavedCustom: _vm.isGetTempoSavedCustom,
                checkSheetAssetInReference: _vm.checkSheetAssetInReference,
              },
              on: {
                isChangeScene: function ($event) {
                  return _vm.$emit("isChangeScene")
                },
                copiedScene: _vm.onCopiedScene,
                addedReferenceScene: _vm.onAddedReferenceScene,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { ref: "checkSheetSection", staticClass: "check-sheet-section" },
          [
            _c("div", { staticClass: "section-title" }, [
              _c("h4", [
                _vm._v(_vm._s(_vm.$t("message.list_check_sheet_title"))),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "section-contents basic-scroll",
                class: { "disabled-area": !_vm.isExistScene },
              },
              [
                _vm.checkSheetAssets.length > 0
                  ? _c(
                      "ul",
                      _vm._l(
                        _vm.checkSheetAssets,
                        function (checkSheetAsset, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              attrs: {
                                disabled: _vm.usedCheckSheetItemIds.includes(
                                  checkSheetAsset.checkSheetItem.id
                                ),
                              },
                            },
                            [
                              _c(
                                "drag",
                                {
                                  staticClass: "drag",
                                  attrs: {
                                    title: checkSheetAsset.id,
                                    "transfer-data": checkSheetAsset,
                                    draggable:
                                      !_vm.usedCheckSheetItemIds.includes(
                                        checkSheetAsset.checkSheetItem.id
                                      ),
                                  },
                                },
                                [
                                  _c("img", {
                                    style: { height: "35px" },
                                    attrs: {
                                      slot: "image",
                                      src: "/images/asset-checksheet.png",
                                      alt: "CheckSheet Asset",
                                    },
                                    slot: "image",
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        checkSheetAsset.checkSheetItem.name
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      0
                    )
                  : _c("p", { staticClass: "section-description" }, [
                      _vm._v(" 등록된 체크시트가 없습니다. "),
                      _c("br"),
                      _vm._v(" ‘체크시트 관리’ 에서 등록 후, 사용하세요. "),
                    ]),
              ]
            ),
          ]
        ),
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isReferenceScene,
                expression: "isReferenceScene",
              },
            ],
            staticClass: "section-description",
          },
          [_vm._v(" 참고씬 저작중에는 씬 or 체크시트를 수정할 수 없습니다. ")]
        ),
      ]
    ),
    _c("div", { ref: "assetSection", staticClass: "asset-section" }, [
      _c("div", { staticClass: "section-title" }, [
        _c("h4", [_vm._v(_vm._s(_vm.$t("message.list_asset_title")))]),
      ]),
      _c(
        "div",
        {
          staticClass: "section-contents basic-scroll",
          class: { "disabled-area": !_vm.isExistScene },
        },
        [
          _c("div", { staticClass: "text-asset-section" }, [
            _c(
              "h5",
              {
                on: {
                  click: function ($event) {
                    return _vm.onSlideList("text-asset-section")
                  },
                },
              },
              [
                _c("span", [
                  _c("img", {
                    attrs: {
                      src: "/images/asset-text.png",
                      alt: "Text Asset Icon",
                    },
                  }),
                  _vm._v(" " + _vm._s(_vm.$t("message.list_text_title")) + " "),
                ]),
                _vm._m(0),
                _vm._m(1),
              ]
            ),
            _c(
              "ul",
              { staticClass: "text-asset-list" },
              _vm._l(_vm.texts, function (text, key) {
                return _c(
                  "li",
                  { key: key },
                  [
                    _c(
                      "drag",
                      {
                        staticClass: "drag",
                        attrs: { title: key, "transfer-data": text },
                      },
                      [
                        _c("img", {
                          style: {
                            minWidth: text.width + "px",
                            height: text.height + "px",
                          },
                          attrs: {
                            slot: "image",
                            src: text.listData,
                            alt: text.name,
                          },
                          slot: "image",
                        }),
                        _c("img", {
                          attrs: { src: text.listData, alt: text.name },
                        }),
                      ]
                    ),
                    _c("span", [_vm._v(_vm._s(key) + _vm._s(text.width))]),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "label-asset-section",
              class: { "disabled-asset-section": _vm.isReferenceScene },
            },
            [
              _c(
                "h5",
                {
                  on: {
                    click: function ($event) {
                      return _vm.onSlideList("label-asset-section")
                    },
                  },
                },
                [
                  _c("span", [
                    _c("img", {
                      attrs: {
                        src: "/images/asset-label.png",
                        alt: "Label Asset Icon",
                      },
                    }),
                    _vm._v(
                      " " + _vm._s(_vm.$t("message.list_label_title")) + " "
                    ),
                  ]),
                  _vm._m(2),
                  _vm._m(3),
                ]
              ),
              _c(
                "ul",
                { staticClass: "label-asset-list" },
                _vm._l(_vm.labels, function (label, key) {
                  return _c(
                    "li",
                    { key: key },
                    [
                      _c(
                        "drag",
                        {
                          staticClass: "drag",
                          attrs: { "transfer-data": label, title: key },
                        },
                        [
                          _c("img", {
                            style: {
                              minWidth: label.width + "px",
                              width: label.width + "px",
                              height: label.height + "px",
                            },
                            attrs: {
                              slot: "image",
                              src: label.listData,
                              alt: label.name,
                            },
                            slot: "image",
                          }),
                          _c("img", {
                            attrs: { src: label.listData, alt: label.name },
                          }),
                        ]
                      ),
                      _c("span", [_vm._v(_vm._s(key))]),
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isReferenceScene,
                      expression: "isReferenceScene",
                    },
                  ],
                  staticClass: "section-description",
                },
                [_vm._v("참고씬 저작중에는 버튼 에셋을 등록할 수 없습니다.")]
              ),
            ]
          ),
          _c("div", { staticClass: "figure-asset-section" }, [
            _c(
              "h5",
              {
                on: {
                  click: function ($event) {
                    return _vm.onSlideList("figure-asset-section")
                  },
                },
              },
              [
                _c("span", [
                  _c("img", {
                    attrs: {
                      src: "/images/asset-figure.png",
                      alt: "Figure Asset Icon",
                    },
                  }),
                  _vm._v(
                    " " + _vm._s(_vm.$t("message.list_shape_title")) + " "
                  ),
                ]),
                _vm._m(4),
                _vm._m(5),
              ]
            ),
            _c(
              "ul",
              { staticClass: "symbol-asset-list" },
              _vm._l(_vm.figures, function (figure, key) {
                return _c(
                  "li",
                  { key: key, class: figure.key },
                  [
                    _c(
                      "drag",
                      {
                        staticClass: "drag",
                        attrs: { "transfer-data": figure, title: key },
                      },
                      [
                        _c("img", {
                          style: {
                            minWidth: figure.width + "px",
                            height: figure.height + "px",
                          },
                          attrs: {
                            slot: "image",
                            src: figure.listData,
                            alt: figure.name,
                          },
                          slot: "image",
                        }),
                        _c("img", {
                          attrs: { src: figure.listData, alt: figure.name },
                        }),
                      ]
                    ),
                    _c("span", [_vm._v(_vm._s(key))]),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "symbol-asset-section" }, [
            _c(
              "h5",
              {
                on: {
                  click: function ($event) {
                    return _vm.onSlideList("symbol-asset-section")
                  },
                },
              },
              [
                _c("span", [
                  _c("img", {
                    attrs: {
                      src: "/images/asset-symbol.png",
                      alt: "Symbol Asset Icon",
                    },
                  }),
                  _vm._v(
                    " " + _vm._s(_vm.$t("message.list_symbol_title")) + " "
                  ),
                ]),
                _vm._m(6),
                _vm._m(7),
              ]
            ),
            _c(
              "ul",
              { staticClass: "symbol-asset-list" },
              _vm._l(_vm.symbols, function (symbol, key) {
                return _c(
                  "li",
                  { key: key, class: symbol.key },
                  [
                    _c(
                      "drag",
                      {
                        staticClass: "drag",
                        attrs: { "transfer-data": symbol, title: key },
                        domProps: { innerHTML: _vm._s(symbol.data) },
                      },
                      [
                        _c("img", {
                          style: {
                            minWidth: symbol.width + "px",
                            width: symbol.width + "px",
                            minHeight: symbol.height + "px",
                          },
                          attrs: {
                            slot: "image",
                            src: symbol.listData,
                            alt: symbol.name,
                          },
                          slot: "image",
                        }),
                      ]
                    ),
                    _c("span", [_vm._v(_vm._s(key))]),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "media-asset-section" }, [
            _c(
              "h5",
              {
                on: {
                  click: function ($event) {
                    return _vm.onSlideList("media-asset-section")
                  },
                },
              },
              [
                _c("span", [
                  _c("img", {
                    attrs: {
                      src: "/images/asset-media.png",
                      alt: "Media Asset Icon",
                    },
                  }),
                  _vm._v(
                    " " + _vm._s(_vm.$t("message.list_media_title")) + " "
                  ),
                ]),
                _vm._m(8),
                _vm._m(9),
              ]
            ),
            _c("ul", [
              _c("li", [
                _c(
                  "form",
                  {
                    staticClass: "disabled_button",
                    attrs: {
                      method: "post",
                      enctype: "multipart/form-data",
                      action: "",
                      title: "Upload Image",
                    },
                  },
                  [
                    _c("input", {
                      staticClass: "upload",
                      staticStyle: { display: "none" },
                      attrs: {
                        type: "file",
                        name: "upFileAssetImage",
                        accept: ".jpg, .png",
                        id: "upFileAssetImage",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.fileSelect($event, "image")
                        },
                      },
                    }),
                    _c(
                      "label",
                      { attrs: { for: "upFileAssetImage" } },
                      [
                        _c("vue-material-icon", {
                          attrs: { name: "photo_size_select_actual", size: 24 },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c("span", [_vm._v("Image")]),
              ]),
              _c("li", [
                _c(
                  "form",
                  {
                    staticClass: "disabled_button",
                    attrs: {
                      method: "post",
                      enctype: "multipart/form-data",
                      action: "",
                      title: "Upload Video",
                    },
                  },
                  [
                    _c("input", {
                      staticClass: "upload",
                      staticStyle: { display: "none" },
                      attrs: {
                        type: "file",
                        name: "upFileAssetVideo",
                        accept: ".mp4",
                        id: "upFileAssetVideo",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.fileSelect($event, "video")
                        },
                      },
                    }),
                    _c(
                      "label",
                      { attrs: { for: "upFileAssetVideo" } },
                      [
                        _c("vue-material-icon", {
                          attrs: { name: "movie", size: 24 },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c("span", [_vm._v("Video")]),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "arrow_down" }, [
      _c("img", {
        attrs: {
          src: "/images/arrow_drop_down-24px.svg",
          alt: "Arrow Down Icon",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "arrow_right disabled" }, [
      _c("img", {
        attrs: { src: "/images/arrow_right-24px.svg", alt: "Arrow Down Icon" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "arrow_down" }, [
      _c("img", {
        attrs: {
          src: "/images/arrow_drop_down-24px.svg",
          alt: "Arrow Down Icon",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "arrow_right disabled" }, [
      _c("img", {
        attrs: { src: "/images/arrow_right-24px.svg", alt: "Arrow Down Icon" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "arrow_down" }, [
      _c("img", {
        attrs: {
          src: "/images/arrow_drop_down-24px.svg",
          alt: "Arrow Down Icon",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "arrow_right disabled" }, [
      _c("img", {
        attrs: { src: "/images/arrow_right-24px.svg", alt: "Arrow Down Icon" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "arrow_down" }, [
      _c("img", {
        attrs: {
          src: "/images/arrow_drop_down-24px.svg",
          alt: "Arrow Down Icon",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "arrow_right disabled" }, [
      _c("img", {
        attrs: { src: "/images/arrow_right-24px.svg", alt: "Arrow Down Icon" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "arrow_down" }, [
      _c("img", {
        attrs: {
          src: "/images/arrow_drop_down-24px.svg",
          alt: "Arrow Down Icon",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "arrow_right disabled" }, [
      _c("img", {
        attrs: { src: "/images/arrow_right-24px.svg", alt: "Arrow Down Icon" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }