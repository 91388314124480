var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "zoom-button-wrapper" }, [
    _c(
      "button",
      {
        on: {
          click: function ($event) {
            return _vm.onZoom("out")
          },
        },
      },
      [
        _c(
          "span",
          { staticClass: "zoom-icon" },
          [_c("vue-material-icon", { attrs: { name: "remove", size: 12 } })],
          1
        ),
      ]
    ),
    _c(
      "span",
      {
        class: {
          warning: _vm.zoom === _vm.minZoom || _vm.zoom === _vm.maxZoom,
        },
      },
      [_vm._v(_vm._s(_vm.zoom) + "%")]
    ),
    _c(
      "button",
      {
        on: {
          click: function ($event) {
            return _vm.onZoom("in")
          },
        },
      },
      [
        _c(
          "span",
          { staticClass: "zoom-icon" },
          [_c("vue-material-icon", { attrs: { name: "add", size: 12 } })],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }