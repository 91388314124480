var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.content
    ? _c(
        "section",
        {
          staticClass: "main-section lnb-small-section",
          class: { "loading-cursor": _vm.isLoadingFile },
        },
        [
          _vm.content
            ? [
                _c("scene-header", {
                  attrs: {
                    isChangeElements: _vm.isChangeElements,
                    isExistScene: _vm.isExistScene,
                    isReferenceScene: _vm.isReferenceScene,
                  },
                  on: {
                    clickedCustomBack: function ($event) {
                      _vm.isClickCustomBack = true
                    },
                    save: function ($event) {
                      return _vm.putTaskCustom(false)
                    },
                    publish: function ($event) {
                      return _vm.putTaskCustom(true)
                    },
                  },
                }),
                _vm.isInitRendered
                  ? _c("scene-left-bar", {
                      attrs: {
                        isExistScene: _vm.isExistScene,
                        isGetTempoSavedCustom: _vm.isGetTempoSavedCustom,
                        taskId: _vm.selectedTask.id,
                        checkSheetItems: _vm.checkSheetItems,
                        checkSheetAssetInReference:
                          _vm.checkSheetAssetInReference,
                        isReferenceScene: _vm.isReferenceScene,
                        usedCheckSheetItemIds:
                          _vm.checkSheetItemIdsInCurrentScene,
                      },
                      on: {
                        isLoadingFile: _vm.setIsLoadingFile,
                        isChangeScene: _vm.onChangeScene,
                        copiedScene: _vm.onCopiedScene,
                        uploadMedia: _vm.addMediaElement,
                        addedReferenceScene: _vm.onAddedReferenceScene,
                      },
                    })
                  : _vm._e(),
                _vm.isInitRendered
                  ? _c("scene-right-bar", {
                      attrs: {
                        isExistScene: _vm.isExistScene,
                        selectedItem: _vm.selectedItem,
                        isReferenceScene: _vm.isReferenceScene,
                        isChangeReferenceElements:
                          _vm.isChangeReferenceElements,
                      },
                      on: {
                        changeTextSize: function ($event) {
                          return _vm.resizeText(true, "size")
                        },
                        changeFontWeight: function ($event) {
                          return _vm.resizeText(true, "weight")
                        },
                        focusedPropertyInput: _vm.focusedPropertyInput,
                        setAnchor: _vm.onChangeAnchor,
                        openReferenceScene: _vm.openReferenceScene,
                        saveReferenceScene: _vm.onSaveReferenceScene,
                        cancelReferenceScene: _vm.onCancelReferenceScene,
                        deleteReferenceScene: _vm.onDeleteReferenceScene,
                        changePropertyCheckSheetElement:
                          _vm.onChangePropertyCheckSheetElement,
                      },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
          _c(
            "div",
            { staticClass: "authoring-section" },
            [
              _c(
                "button",
                {
                  staticClass: "delete-asset-button",
                  class: { "disabled-area": !_vm.isExistScene },
                  on: { click: _vm.deleteElementAll },
                },
                [
                  _c("vue-material-icon", {
                    attrs: { name: "delete", size: "17" },
                  }),
                ],
                1
              ),
              _c(
                "drop",
                {
                  staticClass: "drop basic-scroll",
                  attrs: {
                    oncontextmenu: "return false",
                    ondragstart: "return false",
                    onselectstart: "return false",
                  },
                  on: {
                    drop: function ($event) {
                      return _vm.handleDrop.apply(void 0, arguments)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      ref: "workspace_wrapper",
                      staticClass: "workspace_wrapper",
                      class: { "disabled-area": !_vm.isExistScene },
                      attrs: { id: "workspace_wrapper" },
                    },
                    [
                      _c(
                        "div",
                        {
                          ref: "workspace",
                          staticClass: "workspace",
                          attrs: { id: "workspace" },
                          on: { mouseup: _vm.onClickCanvas },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "transWrapper" },
                            [
                              _vm._l(_vm.elements, function (element) {
                                return [
                                  _c(
                                    "free-transform",
                                    {
                                      key: element.id,
                                      class: [
                                        "tr-transform__" + element.type,
                                        {
                                          "pointer-events-none":
                                            element.type === "checkSheet" &&
                                            _vm.isReferenceScene,
                                        },
                                      ],
                                      attrs: {
                                        id: element.id,
                                        x: element.x,
                                        y: element.y,
                                        "scale-x": element.scaleX,
                                        "scale-y": element.scaleY,
                                        width: element.width,
                                        height: element.height,
                                        "offset-x": _vm.offsetX,
                                        "offset-y": _vm.offsetY,
                                        angle: element.angle,
                                        "disable-scale": element.disableScale,
                                        "scale-from-center":
                                          element.scaleForCenter,
                                        aspectRatio: element.aspectRatio,
                                        selected:
                                          _vm.selectedElement &&
                                          element.id === _vm.selectedElement.id,
                                        selectOn: _vm.selectWay(element),
                                        title: element.name,
                                      },
                                      on: {
                                        onSelect: function ($event) {
                                          return _vm.onSelectElement(element)
                                        },
                                        update: function ($event) {
                                          return _vm.update(element, $event)
                                        },
                                        mouseup: function ($event) {
                                          return _vm.onMouseup(element)
                                        },
                                        dblclick: function ($event) {
                                          return _vm.onDblclick(element, $event)
                                        },
                                      },
                                    },
                                    [
                                      element.type === "targetQR"
                                        ? _c(
                                            "div",
                                            { staticClass: "element" },
                                            [
                                              _c("vue-qr-code", {
                                                staticClass: "qr-code",
                                                attrs: {
                                                  value: element.data,
                                                  margin: 0,
                                                  errorCorrectionLevel: "H",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.imageTypes.includes(element.type)
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "element",
                                              class: "element__" + element.type,
                                              style: {
                                                opacity: element.opacity,
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: element.data,
                                                  alt: "Image",
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      element.type === "video"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "element",
                                              style: {
                                                opacity: element.opacity,
                                              },
                                            },
                                            [
                                              element.thumbnail
                                                ? _c("img", {
                                                    attrs: {
                                                      src: element.thumbnail,
                                                      alt: "Video Thumbnail",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    "data-icon":
                                                      "play_circle_filled",
                                                  },
                                                },
                                                [
                                                  _c("vue-material-icon", {
                                                    attrs: {
                                                      name: "play_circle_filled",
                                                      size: 30,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      element.type === "text" &&
                                      element.name === "Text"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "element text-element",
                                            },
                                            [
                                              _c("label", [
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: element.data,
                                                      expression:
                                                        "element.data",
                                                    },
                                                  ],
                                                  style: {
                                                    color: element.textColor,
                                                    backgroundColor:
                                                      element.backgroundColor,
                                                    textAlign:
                                                      element.textAlign,
                                                    fontWeight:
                                                      element.fontWeight,
                                                    fontSize:
                                                      element.fontSize + "pt",
                                                    opacity: element.opacity,
                                                    padding: "7.5px 10px",
                                                  },
                                                  attrs: {
                                                    id: "asset" + element.id,
                                                    readonly: "readonly",
                                                    autocomplete: "off",
                                                    placeholder: _vm.$t(
                                                      "message.propety_guide_content_phd"
                                                    ),
                                                  },
                                                  domProps: {
                                                    value: element.data,
                                                  },
                                                  on: {
                                                    focus: function ($event) {
                                                      return _vm.resizeText(
                                                        false
                                                      )
                                                    },
                                                    blur: function ($event) {
                                                      return _vm.onTextBlur(
                                                        element
                                                      )
                                                    },
                                                    keydown: function ($event) {
                                                      return _vm.resizeText(
                                                        false
                                                      )
                                                    },
                                                    keyup: function ($event) {
                                                      return _vm.resizeText(
                                                        false
                                                      )
                                                    },
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        element,
                                                        "data",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      element.type === "text" &&
                                      element.name &&
                                      element.name.includes("Number")
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "element text-element numbering",
                                              attrs: {
                                                "data-type": element.name,
                                              },
                                            },
                                            [
                                              _c("label", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: element.data,
                                                      expression:
                                                        "element.data",
                                                    },
                                                  ],
                                                  style: {
                                                    color: element.textColor,
                                                    backgroundColor: "#ffffff",
                                                    boxShadow:
                                                      "0 0 0 3px " +
                                                      element.backgroundColor +
                                                      " inset",
                                                    textAlign:
                                                      element.textAlign,
                                                    fontWeight:
                                                      element.fontWeight,
                                                    fontSize:
                                                      element.fontSize + "pt",
                                                    opacity: element.opacity,
                                                  },
                                                  attrs: {
                                                    type: "number",
                                                    id: "asset" + element.id,
                                                    max: "999",
                                                    min: "-99",
                                                    maxlength: "3",
                                                    readonly: "readonly",
                                                    autocomplete: "off",
                                                  },
                                                  domProps: {
                                                    value: element.data,
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.onTextBlur(
                                                        element,
                                                        true
                                                      )
                                                    },
                                                    keydown: function ($event) {
                                                      return _vm.onKeydownLabel(
                                                        $event,
                                                        element
                                                      )
                                                    },
                                                    input: [
                                                      function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          element,
                                                          "data",
                                                          $event.target.value
                                                        )
                                                      },
                                                      function ($event) {
                                                        return _vm.maxLengthCheck(
                                                          $event,
                                                          element
                                                        )
                                                      },
                                                    ],
                                                  },
                                                }),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      element.type === "label" &&
                                      element.name &&
                                      element.name.includes("Anchor Type 1")
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "element label-element text-type-1",
                                              class: _vm.getAnchorType(
                                                element.name
                                              ),
                                            },
                                            [
                                              _c("label", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: element.data,
                                                      expression:
                                                        "element.data",
                                                    },
                                                  ],
                                                  style: {
                                                    color: element.textColor,
                                                    backgroundColor:
                                                      element.backgroundColor,
                                                    textAlign:
                                                      element.textAlign,
                                                    fontWeight:
                                                      element.fontWeight,
                                                    fontSize:
                                                      element.fontSize + "pt",
                                                    opacity: element.opacity,
                                                  },
                                                  attrs: {
                                                    type: "text",
                                                    id: "asset" + element.id,
                                                    readonly: "readonly",
                                                    autocomplete: "off",
                                                    placeholder: _vm.$t(
                                                      "message.propety_guide_content_phd"
                                                    ),
                                                    maxlength: "30",
                                                  },
                                                  domProps: {
                                                    value: element.data,
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      return _vm.onKeydownLabel(
                                                        $event,
                                                        element
                                                      )
                                                    },
                                                    keyup: function ($event) {
                                                      return _vm.resizeText(
                                                        false
                                                      )
                                                    },
                                                    blur: function ($event) {
                                                      return _vm.onTextBlur(
                                                        element
                                                      )
                                                    },
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        element,
                                                        "data",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]),
                                              _c("span", {
                                                staticClass: "anchor-triangle",
                                                style: {
                                                  borderColor:
                                                    "" +
                                                    element.backgroundColor,
                                                  opacity: element.opacity,
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      element.type === "label" &&
                                      element.name &&
                                      element.name.includes("Anchor Type 2")
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "element label-element text-type-2",
                                              class: _vm.getAnchorType(
                                                element.name
                                              ),
                                            },
                                            [
                                              _c("label", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: element.data,
                                                      expression:
                                                        "element.data",
                                                    },
                                                  ],
                                                  style: {
                                                    color: element.textColor,
                                                    backgroundColor: "#ffffff",
                                                    boxShadow:
                                                      "0 0 0 3px " +
                                                      element.backgroundColor +
                                                      " inset",
                                                    textAlign:
                                                      element.textAlign,
                                                    fontWeight:
                                                      element.fontWeight,
                                                    fontSize:
                                                      element.fontSize + "pt",
                                                    opacity: element.opacity,
                                                  },
                                                  attrs: {
                                                    type: "text",
                                                    id: "asset" + element.id,
                                                    readonly: "readonly",
                                                    autocomplete: "off",
                                                    placeholder: _vm.$t(
                                                      "message.propety_guide_content_phd"
                                                    ),
                                                    maxlength: "30",
                                                  },
                                                  domProps: {
                                                    value: element.data,
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      return _vm.onKeydownLabel(
                                                        $event,
                                                        element
                                                      )
                                                    },
                                                    keyup: function ($event) {
                                                      return _vm.resizeText(
                                                        false
                                                      )
                                                    },
                                                    blur: function ($event) {
                                                      return _vm.onTextBlur(
                                                        element
                                                      )
                                                    },
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        element,
                                                        "data",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]),
                                              _c("span", {
                                                staticClass: "anchor-line",
                                                style: {
                                                  backgroundColor:
                                                    element.backgroundColor,
                                                  opacity: element.opacity,
                                                },
                                              }),
                                              _c("span", {
                                                staticClass: "anchor-circle",
                                                style: {
                                                  boxShadow:
                                                    "0 0 0 3px " +
                                                    element.backgroundColor +
                                                    " inset",
                                                  opacity: element.opacity,
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      element.type === "label" &&
                                      element.name === "Numbering"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "element label-element numbering",
                                            },
                                            [
                                              _c("label", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: element.data,
                                                      expression:
                                                        "element.data",
                                                    },
                                                  ],
                                                  style: {
                                                    color: element.textColor,
                                                    backgroundColor: "#ffffff",
                                                    boxShadow:
                                                      "0 0 0 3px " +
                                                      element.backgroundColor +
                                                      " inset",
                                                    textAlign:
                                                      element.textAlign,
                                                    fontWeight:
                                                      element.fontWeight,
                                                    fontSize:
                                                      element.fontSize + "pt",
                                                    opacity: element.opacity,
                                                  },
                                                  attrs: {
                                                    type: "number",
                                                    id: "asset" + element.id,
                                                    max: "999",
                                                    min: "-99",
                                                    maxlength: "3",
                                                    readonly: "readonly",
                                                    autocomplete: "off",
                                                  },
                                                  domProps: {
                                                    value: element.data,
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.onTextBlur(
                                                        element,
                                                        true
                                                      )
                                                    },
                                                    keydown: function ($event) {
                                                      return _vm.onKeydownLabel(
                                                        $event,
                                                        element
                                                      )
                                                    },
                                                    input: [
                                                      function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          element,
                                                          "data",
                                                          $event.target.value
                                                        )
                                                      },
                                                      function ($event) {
                                                        return _vm.maxLengthCheck(
                                                          $event,
                                                          element
                                                        )
                                                      },
                                                    ],
                                                  },
                                                }),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      element.type === "label" &&
                                      element.name &&
                                      element.name.includes("Button Type 1")
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "element label-element button-type",
                                              class: _vm.getAnchorType(
                                                element.name
                                              ),
                                              attrs: {
                                                "data-type": element.name,
                                              },
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  style: {
                                                    opacity: element.opacity,
                                                  },
                                                },
                                                [
                                                  element.name.includes("2")
                                                    ? _c("span", {
                                                        staticClass:
                                                          "asset-button-number",
                                                        style: {
                                                          backgroundColor:
                                                            element.backgroundColor,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: element.data,
                                                        expression:
                                                          "element.data",
                                                      },
                                                    ],
                                                    style: {
                                                      color: element.textColor,
                                                      backgroundColor:
                                                        element.backgroundColor,
                                                      borderRadius:
                                                        element.height -
                                                        15 +
                                                        "px",
                                                      textAlign:
                                                        element.textAlign,
                                                      fontWeight:
                                                        element.fontWeight,
                                                      fontSize:
                                                        element.fontSize + "pt",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      id: "asset" + element.id,
                                                      readonly: "readonly",
                                                      autocomplete: "off",
                                                      placeholder: _vm.$t(
                                                        "message.propety_guide_content_phd"
                                                      ),
                                                      maxlength: "30",
                                                    },
                                                    domProps: {
                                                      value: element.data,
                                                    },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        return _vm.onKeydownLabel(
                                                          $event,
                                                          element
                                                        )
                                                      },
                                                      keyup: function ($event) {
                                                        return _vm.resizeText(
                                                          false
                                                        )
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.onTextBlur(
                                                          element
                                                        )
                                                      },
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          element,
                                                          "data",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  element.name.includes("1")
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "arrow-right icon-vertical-top",
                                                          style: {
                                                            color:
                                                              element.textColor,
                                                            top:
                                                              element.height /
                                                                2 -
                                                              (Number(
                                                                element.fontSize
                                                              ) +
                                                                8) /
                                                                2 +
                                                              "px",
                                                          },
                                                          attrs: {
                                                            "data-icon":
                                                              "asset-button-arrow",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "vue-material-icon",
                                                            {
                                                              attrs: {
                                                                name: "arrow_forward",
                                                                size:
                                                                  Number(
                                                                    element.fontSize
                                                                  ) + 8,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      element.type === "label" &&
                                      element.name &&
                                      element.name.includes("Button Type 2")
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "element label-element button-type",
                                              class: _vm.getAnchorType(
                                                element.name
                                              ),
                                              attrs: {
                                                "data-type": element.name,
                                              },
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  style: {
                                                    opacity: element.opacity,
                                                  },
                                                },
                                                [
                                                  element.name.includes("2")
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "asset-button-number",
                                                          style: {
                                                            backgroundColor:
                                                              element.backgroundColor,
                                                            width:
                                                              element.height +
                                                              "px",
                                                          },
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  element.subData,
                                                                expression:
                                                                  "element.subData",
                                                              },
                                                            ],
                                                            style: {
                                                              color:
                                                                _vm.setButtonType2Color(
                                                                  "color",
                                                                  element.backgroundColor
                                                                ),
                                                              fontWeight:
                                                                element.fontWeight,
                                                              fontSize:
                                                                element.subDataFontSize +
                                                                "pt",
                                                            },
                                                            attrs: {
                                                              type: "number",
                                                              id:
                                                                "number-asset" +
                                                                element.id,
                                                              max: "999",
                                                              min: "-99",
                                                              maxlength: "3",
                                                              readonly:
                                                                "readonly",
                                                              autocomplete:
                                                                "off",
                                                            },
                                                            domProps: {
                                                              value:
                                                                element.subData,
                                                            },
                                                            on: {
                                                              blur: function (
                                                                $event
                                                              ) {
                                                                return _vm.onTextBlur(
                                                                  element,
                                                                  true
                                                                )
                                                              },
                                                              keydown:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.onKeydownLabel(
                                                                    $event,
                                                                    element
                                                                  )
                                                                },
                                                              input: [
                                                                function (
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    $event
                                                                      .target
                                                                      .composing
                                                                  ) {
                                                                    return
                                                                  }
                                                                  _vm.$set(
                                                                    element,
                                                                    "subData",
                                                                    $event
                                                                      .target
                                                                      .value
                                                                  )
                                                                },
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.maxLengthCheck(
                                                                    $event,
                                                                    element
                                                                  )
                                                                },
                                                              ],
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: element.data,
                                                        expression:
                                                          "element.data",
                                                      },
                                                    ],
                                                    style: {
                                                      color: element.textColor,
                                                      backgroundColor:
                                                        _vm.setButtonType2Color(
                                                          "background",
                                                          element.backgroundColor
                                                        ),
                                                      boxShadow:
                                                        "0 0 0 3px " +
                                                        element.backgroundColor +
                                                        " inset",
                                                      textAlign:
                                                        element.textAlign,
                                                      fontWeight:
                                                        element.fontWeight,
                                                      fontSize:
                                                        element.fontSize + "pt",
                                                      borderRadius:
                                                        element.height -
                                                        15 +
                                                        "px",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      id: "asset" + element.id,
                                                      readonly: "readonly",
                                                      autocomplete: "off",
                                                      placeholder: _vm.$t(
                                                        "message.propety_guide_content_phd"
                                                      ),
                                                      maxlength: "30",
                                                    },
                                                    domProps: {
                                                      value: element.data,
                                                    },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        return _vm.onKeydownLabel(
                                                          $event,
                                                          element
                                                        )
                                                      },
                                                      keyup: function ($event) {
                                                        return _vm.resizeText(
                                                          false
                                                        )
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.onTextBlur(
                                                          element
                                                        )
                                                      },
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          element,
                                                          "data",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  element.name.includes("1")
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "icon-vertical-top",
                                                          style: {
                                                            color:
                                                              element.textColor,
                                                            top:
                                                              element.height /
                                                                2 -
                                                              (Number(
                                                                element.fontSize
                                                              ) +
                                                                8) /
                                                                2 +
                                                              "px",
                                                          },
                                                          attrs: {
                                                            "data-icon":
                                                              "asset-button-arrow",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "vue-material-icon",
                                                            {
                                                              attrs: {
                                                                name: "arrow_forward",
                                                                size:
                                                                  Number(
                                                                    element.fontSize
                                                                  ) + 8,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      element.type === "label" &&
                                      element.name &&
                                      element.name.includes("Button Type 3")
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "element label-element button-type",
                                              class: _vm.getAnchorType(
                                                element.name
                                              ),
                                              attrs: {
                                                "data-type": element.name,
                                              },
                                            },
                                            [
                                              _c("label", [
                                                element.name.includes("2")
                                                  ? _c("span", {
                                                      staticClass:
                                                        "asset-button-number",
                                                      style: {
                                                        backgroundColor:
                                                          element.backgroundColor,
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: element.data,
                                                      expression:
                                                        "element.data",
                                                    },
                                                  ],
                                                  style: {
                                                    color: element.textColor,
                                                    backgroundColor:
                                                      element.backgroundColor,
                                                    textAlign:
                                                      element.textAlign,
                                                    fontWeight:
                                                      element.fontWeight,
                                                    fontSize:
                                                      element.fontSize + "pt",
                                                    opacity: element.opacity,
                                                  },
                                                  attrs: {
                                                    type: "text",
                                                    id: "asset" + element.id,
                                                    readonly: "readonly",
                                                    autocomplete: "off",
                                                    placeholder: _vm.$t(
                                                      "message.propety_guide_content_phd"
                                                    ),
                                                    maxlength: "30",
                                                  },
                                                  domProps: {
                                                    value: element.data,
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      return _vm.onKeydownLabel(
                                                        $event,
                                                        element
                                                      )
                                                    },
                                                    keyup: function ($event) {
                                                      return _vm.resizeText(
                                                        false
                                                      )
                                                    },
                                                    blur: function ($event) {
                                                      return _vm.onTextBlur(
                                                        element
                                                      )
                                                    },
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        element,
                                                        "data",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                element.name.includes("1")
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "icon-vertical-top",
                                                        style: {
                                                          color:
                                                            element.textColor,
                                                          top:
                                                            element.height / 2 -
                                                            (Number(
                                                              element.fontSize
                                                            ) +
                                                              8) /
                                                              2 +
                                                            "px",
                                                        },
                                                        attrs: {
                                                          "data-icon":
                                                            "asset-button-arrow",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "vue-material-icon",
                                                          {
                                                            attrs: {
                                                              name: "arrow_forward",
                                                              size:
                                                                Number(
                                                                  element.fontSize
                                                                ) + 8,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      element.type === "figure" &&
                                      !element.data.startsWith("http")
                                        ? _c("div", {
                                            staticClass: "element",
                                            class: {
                                              horizontal:
                                                element.isHorizontalFlip,
                                              vertical: element.isVerticalFlip,
                                            },
                                            style: { opacity: element.opacity },
                                            domProps: {
                                              innerHTML: _vm._s(element.data),
                                            },
                                          })
                                        : _vm._e(),
                                      element.type === "figure" &&
                                      element.data.startsWith("http")
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "element",
                                              class: {
                                                horizontal:
                                                  element.isHorizontalFlip,
                                                vertical:
                                                  element.isVerticalFlip,
                                              },
                                              style: {
                                                opacity: element.opacity,
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: element.data,
                                                  alt: element.name,
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      element.type === "pointing"
                                        ? _c("div", {
                                            staticClass: "element",
                                            style: { opacity: element.opacity },
                                            domProps: {
                                              innerHTML: _vm._s(element.data),
                                            },
                                          })
                                        : _vm._e(),
                                      element.type === "symbol"
                                        ? _c("div", {
                                            staticClass: "element",
                                            class: {
                                              horizontal:
                                                element.isHorizontalFlip,
                                              vertical: element.isVerticalFlip,
                                            },
                                            style: { opacity: element.opacity },
                                            domProps: {
                                              innerHTML: _vm._s(element.data),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.isUsedArCheckSheet(element)
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "element label-element text-type-2",
                                              class: _vm.getAnchorType(
                                                element.name
                                              ),
                                              style: {
                                                opacity: element.opacity,
                                              },
                                            },
                                            [
                                              _c("label", [
                                                _c("input", {
                                                  staticClass:
                                                    "check-sheet-input",
                                                  style: {
                                                    color: element.textColor,
                                                    backgroundColor: "#ffffff",
                                                    boxShadow:
                                                      "0 0 0 2px " +
                                                      element.backgroundColor +
                                                      " inset",
                                                    fontWeight:
                                                      element.fontWeight,
                                                    fontSize:
                                                      element.fontSize + "pt",
                                                    paddingLeft:
                                                      Number(element.fontSize) *
                                                        1.2 +
                                                      "px",
                                                    paddingRight:
                                                      Number(element.fontSize) +
                                                      8 +
                                                      "px",
                                                    width:
                                                      _vm.getCheckSheetAssetWidth(
                                                        element
                                                      ),
                                                  },
                                                  attrs: {
                                                    type: "text",
                                                    id: "asset" + element.id,
                                                    readonly: "readonly",
                                                    autocomplete: "off",
                                                  },
                                                  domProps: {
                                                    value:
                                                      element.checkSheetItem
                                                        .name,
                                                  },
                                                }),
                                                _c("span", {
                                                  staticClass:
                                                    "check-sheet-status",
                                                  style: {
                                                    borderColor:
                                                      "" +
                                                      element.backgroundColor,
                                                    width:
                                                      Number(element.fontSize) +
                                                      "px",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "arrow-right",
                                                    style: {
                                                      color: element.textColor,
                                                      top: _vm.getCheckSheetAssetTop(
                                                        element
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("vue-material-icon", {
                                                      attrs: {
                                                        name: "chevron_right",
                                                        size:
                                                          Number(
                                                            element.fontSize
                                                          ) + 8,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                              _c("span", {
                                                staticClass:
                                                  "anchor-line check-sheet-anchor-line",
                                                style: {
                                                  backgroundColor:
                                                    element.backgroundColor,
                                                  opacity: element.opacity,
                                                },
                                              }),
                                              _c("span", {
                                                staticClass:
                                                  "anchor-circle check-sheet-anchor-circle",
                                                style: {
                                                  background:
                                                    "" +
                                                    element.backgroundColor,
                                                  opacity: element.opacity,
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  ref:
                                                    "check-sheet-detail-box-" +
                                                    element.id,
                                                  refInFor: true,
                                                  staticClass:
                                                    "check-sheet-detail-box",
                                                  class: {
                                                    "visibility-hidden":
                                                      !_vm.isReferenceScene,
                                                  },
                                                  style: {
                                                    width:
                                                      (245 / 12) *
                                                        element.fontSize +
                                                      "px",
                                                    boxShadow:
                                                      "0 0 0 2px " +
                                                      element.backgroundColor +
                                                      " inset",
                                                    padding:
                                                      (10 / 12) *
                                                        element.fontSize +
                                                      "px",
                                                    opacity: element.opacity,
                                                    top:
                                                      _vm.checkBoxDetailTop(
                                                        element
                                                      ) + "px",
                                                  },
                                                },
                                                [
                                                  element.checkSheetItem
                                                    .rscFiles.length > 0
                                                    ? _c("div", {
                                                        staticClass:
                                                          "background-image",
                                                        style: {
                                                          backgroundImage:
                                                            "url(" +
                                                            element
                                                              .checkSheetItem
                                                              .rscFiles[0]
                                                              .rscUrl +
                                                            ")",
                                                          height:
                                                            (130 / 12) *
                                                              element.fontSize +
                                                            "px",
                                                          marginBottom:
                                                            (5 / 12) *
                                                              element.fontSize +
                                                            "px",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "check-sheet-cycle",
                                                      style: {
                                                        color:
                                                          element.textColor,
                                                        fontSize:
                                                          element.fontSize +
                                                          "pt",
                                                        margin:
                                                          "0 " +
                                                          (5 / 12) *
                                                            element.fontSize +
                                                          "px " +
                                                          (4 / 12) *
                                                            element.fontSize +
                                                          "px",
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(" 점검 주기 : "),
                                                        _c("b", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm
                                                                .checkSheetCycleOptionNames[
                                                                element
                                                                  .checkSheetItem
                                                                  .chkCycle
                                                              ]
                                                            )
                                                          ),
                                                        ]),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "white-space-pre-line",
                                                      style: {
                                                        color:
                                                          element.textColor,
                                                        fontSize:
                                                          element.fontSize +
                                                          "pt",
                                                        margin:
                                                          "0 " +
                                                          (5 / 12) *
                                                            element.fontSize +
                                                          "px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            element
                                                              .checkSheetItem
                                                              .chkMethodDesc
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              }),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c("zoom", { class: { "disabled-area": !_vm.isExistScene } }),
              _vm.selectedElement
                ? _c("div", {
                    staticClass: "tempo-text-area",
                    style: { fontSize: _vm.selectedElement.fontSize + "pt" },
                    attrs: { id: "tempo-text-area" },
                  })
                : _vm._e(),
              _c("div", {
                staticClass: "tempo-text-area",
                attrs: { id: "tempo-check-sheet-area" },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "toast-popup toast-popup-middle" }, [
            _vm._v(" " + _vm._s(_vm.toastPopupTitle) + " "),
          ]),
          _c("confirm-popup", {
            attrs: {
              isShow: _vm.showedPopupName === "ConfirmPopup",
              contentsKey: _vm.popupContentsKey,
            },
            on: {
              close: _vm.onCancelConfirmInPopup,
              confirm: _vm.onConfirmInPopup,
            },
          }),
          _c("div", { staticClass: "toast-popup toast-popup-bottom" }, [
            _vm._v(" " + _vm._s(_vm.toastTitle) + " "),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }