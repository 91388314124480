export default (name) => {
  if (!name) return 'bottom';
  if (name.includes('top')) {
    return 'top';
  } else if (name.includes('right')) {
    return 'right';
  } else if (name.includes('left')) {
    return 'left';
  } else {
    return 'bottom';
  }
};
