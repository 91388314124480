var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scene-list-wrapper", on: { mouseleave: _vm.onMouseLeave } },
    [
      _c(
        "div",
        { staticClass: "section-title", on: { mouseover: _vm.onMouseLeave } },
        [
          _c("h4", [_vm._v(_vm._s(_vm.$t("message.list_scene_title")))]),
          _c(
            "button",
            {
              attrs: {
                "data-icon": "add",
                title: _vm.$t("message.list_scene_add_tooltip"),
              },
              on: {
                click: function ($event) {
                  return _vm.addScene("single")
                },
              },
            },
            [
              _c(
                "span",
                { attrs: { "data-icon": "scene-group-add" } },
                [_c("vue-material-icon", { attrs: { name: "add", size: 16 } })],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "section-contents",
          attrs: { id: "scene-list" },
          on: { mouseleave: _vm.onCloseMoreList },
        },
        [
          _c(
            "ul",
            {
              staticClass: "scene-list",
              attrs: { oncontextmenu: "return false" },
            },
            _vm._l(_vm.sceneArray, function (outerScene, index) {
              return _c(
                "li",
                {
                  key: outerScene.key,
                  staticClass: "scene outerScene",
                  class: {
                    "single-scene": outerScene.type === "single",
                    "scene-group": outerScene.type === "group",
                    selected:
                      (!_vm.activatedType.includes("asset") &&
                        _vm.activatedType !== "group" &&
                        outerScene.key === _vm.selectedScene.key) ||
                      (_vm.activatedType === "group" &&
                        outerScene.key === _vm.selectedSceneGroup.key),
                    focused: _vm.isFocusedOuterScene(outerScene),
                    "hover-inner": _vm.hoveredScene !== "outerScene",
                  },
                  attrs: {
                    name: "outerScene",
                    draggable: !_vm.isListMoveMode,
                    title: outerScene.name,
                  },
                  on: {
                    mouseover: function ($event) {
                      return _vm.onMouseover($event, outerScene, false)
                    },
                    dblclick: function ($event) {
                      return _vm.onInputMode($event, "input" + outerScene.key)
                    },
                    mousedown: function ($event) {
                      return _vm.onSelectScene($event, outerScene)
                    },
                    mouseup: function ($event) {
                      return _vm.onMouseup($event)
                    },
                    dragstart: function ($event) {
                      return _vm.handleDragStart($event, outerScene, index)
                    },
                    dragenter: function ($event) {
                      return _vm.handleDragEnter($event, outerScene, index)
                    },
                    dragover: function ($event) {
                      return _vm.handleDragOver($event, index)
                    },
                    dragleave: function ($event) {
                      return _vm.handleDragLeave($event, outerScene, index)
                    },
                    dragend: function ($event) {
                      return _vm.handleDragEnd($event, outerScene, null, index)
                    },
                  },
                },
                [
                  outerScene.type !== "inner"
                    ? [
                        _c(
                          "span",
                          {
                            on: {
                              mouseover: function ($event) {
                                return _vm.onMouseover($event, outerScene, true)
                              },
                            },
                          },
                          [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.custom.scenes[outerScene.key].name,
                                    expression:
                                      "custom.scenes[outerScene.key].name",
                                  },
                                ],
                                attrs: {
                                  type: "text",
                                  id: "input" + outerScene.key,
                                  readonly: "readonly",
                                  maxlength: "20",
                                  placeholder: "Scene",
                                },
                                domProps: {
                                  value: _vm.custom.scenes[outerScene.key].name,
                                },
                                on: {
                                  blur: _vm.onBlurInput,
                                  keypress: _vm.onKeypress,
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.custom.scenes[outerScene.key],
                                      "name",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "opacity-0",
                                class: {
                                  "opacity-100":
                                    _vm.hoveredScene === "outerScene",
                                },
                                attrs: {
                                  "data-icon": "drag_indicator",
                                  draggable: "false",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "/images/drag_indicator.png",
                                    alt: "Drag Indicator",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c("ul", { staticClass: "scene-more-list" }, [
                          _c(
                            "li",
                            { on: { click: _vm.onClickChangeSceneName } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("message.list_scene_name_label"))
                              ),
                            ]
                          ),
                          _c("li", { on: { click: _vm.onClickCopyScene } }, [
                            _vm._v(
                              _vm._s(_vm.$t("message.list_scene_copy_label"))
                            ),
                          ]),
                          _c(
                            "li",
                            {
                              class: { disabled: _vm.sceneArray.length === 1 },
                              on: {
                                click: function ($event) {
                                  return _vm.onClickDeleteScene(true)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("message.delete_label")) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                        outerScene.type === "group"
                          ? _c(
                              "ul",
                              { staticClass: "inner-scene-list" },
                              [
                                _vm._l(
                                  outerScene.innerScenes,
                                  function (innerScene, index) {
                                    return _c(
                                      "li",
                                      {
                                        key: innerScene.key,
                                        staticClass: "scene innerScene",
                                        class: {
                                          selected:
                                            !_vm.activatedType.includes(
                                              "asset"
                                            ) &&
                                            _vm.activatedType !== "group" &&
                                            innerScene.key ===
                                              _vm.selectedScene.key,
                                          focused:
                                            (_vm.activatedType === "group" &&
                                              innerScene.key ===
                                                _vm.selectedScene.key) ||
                                            (_vm.activatedType.includes(
                                              "asset"
                                            ) &&
                                              innerScene.key ===
                                                _vm.selectedScene.key),
                                        },
                                        attrs: {
                                          name: "innerScene_" + outerScene.key,
                                          draggable: !_vm.isListMoveMode,
                                          title: innerScene.name,
                                        },
                                        on: {
                                          mouseover: function ($event) {
                                            return _vm.onMouseover(
                                              $event,
                                              innerScene,
                                              false
                                            )
                                          },
                                          dblclick: function ($event) {
                                            return _vm.onInputMode(
                                              $event,
                                              "input" + innerScene.key
                                            )
                                          },
                                          mousedown: function ($event) {
                                            return _vm.onSelectScene(
                                              $event,
                                              outerScene,
                                              innerScene
                                            )
                                          },
                                          mouseup: function ($event) {
                                            return _vm.onMouseup($event)
                                          },
                                          dragstart: function ($event) {
                                            return _vm.handleDragStart(
                                              $event,
                                              innerScene,
                                              index,
                                              outerScene.innerScenes
                                            )
                                          },
                                          dragenter: function ($event) {
                                            return _vm.handleDragEnter(
                                              $event,
                                              innerScene,
                                              index,
                                              outerScene.innerScenes
                                            )
                                          },
                                          dragover: function ($event) {
                                            return _vm.handleDragOver(
                                              $event,
                                              index
                                            )
                                          },
                                          dragleave: function ($event) {
                                            return _vm.handleDragLeave(
                                              $event,
                                              innerScene,
                                              index,
                                              outerScene.innerScenes
                                            )
                                          },
                                          dragend: function ($event) {
                                            return _vm.handleDragEnd(
                                              $event,
                                              outerScene,
                                              innerScene,
                                              index,
                                              outerScene.innerScenes
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("label", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.custom.scenes[
                                                    innerScene.key
                                                  ].name,
                                                expression:
                                                  "custom.scenes[innerScene.key].name",
                                              },
                                            ],
                                            attrs: {
                                              type: "text",
                                              id: "input" + innerScene.key,
                                              readonly: "readonly",
                                              maxlength: "20",
                                              placeholder: "Scene",
                                            },
                                            domProps: {
                                              value:
                                                _vm.custom.scenes[
                                                  innerScene.key
                                                ].name,
                                            },
                                            on: {
                                              blur: _vm.onBlurInput,
                                              keypress: _vm.onKeypress,
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.custom.scenes[
                                                    innerScene.key
                                                  ],
                                                  "name",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "opacity-0",
                                            class: {
                                              "opacity-100":
                                                _vm.hoveredScene ===
                                                innerScene.parentKey,
                                            },
                                            attrs: {
                                              "data-icon": "drag_indicator",
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: "/images/drag_indicator.png",
                                                alt: "Drag Indicator",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "ul",
                                          { staticClass: "scene-more-list" },
                                          [
                                            _c(
                                              "li",
                                              {
                                                on: {
                                                  click:
                                                    _vm.onClickChangeSceneName,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "message.list_scene_name_label"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                on: {
                                                  click: _vm.onClickCopyScene,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "message.list_scene_copy_label"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                class: {
                                                  disabled:
                                                    _vm.sceneArray.length ===
                                                      1 &&
                                                    outerScene.innerScenes
                                                      .length === 1,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onClickDeleteScene(
                                                      false
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "message.delete_label"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                _c(
                                  "li",
                                  {
                                    staticClass: "add-list scene",
                                    on: { mouseover: _vm.onMouseLeave },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        ref: "add",
                                        refInFor: true,
                                        attrs: { "data-icon": "add" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addInnerScene(
                                              outerScene.key
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("vue-material-icon", {
                                          attrs: { name: "add", size: 16 },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            0
          ),
        ]
      ),
      _c("confirm-popup", {
        attrs: {
          isShow: _vm.showedPopupName === "ConfirmPopup",
          contentsKey: _vm.popupContentsKey,
        },
        on: { close: _vm.togglePopup, confirm: _vm.onConfirmInPopup },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }