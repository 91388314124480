<template>
  <div class="zoom-button-wrapper">
    <button @click="onZoom('out')">
      <span class="zoom-icon">
        <vue-material-icon name="remove" :size="12" />
      </span>
    </button>
    <span :class="{ warning: zoom === minZoom || zoom === maxZoom }">{{ zoom }}%</span>
    <button @click="onZoom('in')">
      <span class="zoom-icon">
        <vue-material-icon name="add" :size="12" />
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Zoom',
  data() {
    return {
      zoom: 100,
      minZoom: 80,
      maxZoom: 120,
      unit: 0.05,
    };
  },
  computed: {},
  mounted() {},
  watch: {},
  methods: {
    onZoom(type) {
      let $zoomArea = document.getElementById('workspace');
      if (!$zoomArea.style.zoom) {
        $zoomArea.style.zoom = '1';
      }
      if (type === 'in' && this.zoom < this.maxZoom) {
        this.zoom += this.unit * 100;
        $zoomArea.style.zoom = Number($zoomArea.style.zoom) + this.unit;
      } else if (type === 'out' && this.zoom > this.minZoom) {
        this.zoom -= this.unit * 100;
        $zoomArea.style.zoom = Number($zoomArea.style.zoom) - this.unit;
      }
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import 'Zoom';
</style>
